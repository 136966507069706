@import '../../../styles/mixin';


.container_subscribe {
    @media screen and (max-width: 767px) {
        background: url("../../../backgroundmobile.png") center  no-repeat;
        background-size: cover;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'Open Sans';
        padding-bottom: 5rem;
        color: #fff;
    }

    @include media-min ('tablet') {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-family: 'Open Sans';
        background: linear-gradient(#090a0a,#212626);
    }

    @include media-min ('desktop-s') {
        padding-bottom: 10rem;
        padding-top: 6rem;
    }

    @media (min-width: 1224px) {
        padding-bottom: 0;
        padding-top: 0;
        justify-content: center;
        align-items: center;
    }

    .close_btn {
        position: absolute;
        top: 5px;
        right: 7px;
        color: lightgray;
        padding: 10px;
        background-color: transparent;
        border: none;
        font-size: 25px;
        font-weight: bold;
        @media (orientation: landscape) {
            top: 5%;
            right: 5%;
            font-size: 30px;
            padding: 0;
        }
    }

    .logoI {
        @media screen and (max-width: 767px) {
            display: none;
        }
        @include media-min ('tablet') {
            width: 160px;
            height: 95px;
            margin: 2rem auto;
        }
    }
    .logomobileI {
        @media screen and (max-width: 767px) {
            margin-top: 2rem;
            margin-left: auto;
            margin-right: auto;
        }
        @include media-min ('tablet') {
            display: none;
        }
    }

    .title_subscribe {
        @media screen and (max-width: 767px) {
            text-align: center;
            color: #fff;
            font-size: 1rem;
            font-family: 'Open Sans';
            font-weight: 900;
            margin-top: 3rem;
            margin-bottom: 2rem;
            width: 80%;
        }
        @include media-min ('tablet') {
            text-align: center;
            color: #fff;
            font-size: 24px;
            font-family: 'Open Sans';
            font-weight: 900;
            margin-top: 2rem;
            margin-bottom: 2rem;
            width: 80%
        }
        @include media-min ('desktop-s') {
            margin-top: 6rem;
            margin-bottom: 1.5rem;
            font-size: 26px;
        }
        @media (min-width: 1224px) {
            margin-top: 5rem;
            margin-bottom: 1.5rem;
            font-size: 26px;
        }
    }
    .container__link {
        @media screen and (max-width: 767px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 2rem;
            margin-bottom: 2rem;
            border: none;
            width: 70%;
            max-width: 250px;
        }
        @include media-min ('tablet') {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 2rem;
            min-height: 64px;
            border: none;
            width: 50%;
            max-width: 500px;
        }
            .link__subscribe {
                @media screen and (max-width: 767px) {
                    color: #fff;
                    text-transform: uppercase;
                    font-size: .7rem;
                    font-weight: 700;
                    text-align: center;
                    background: linear-gradient(to right, #3CAD00, #C8D900 );
                    border-radius: 50px;
                    height: 40px;
                    min-height: 40px;
                    margin: 5% auto;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    
                }
                @include media-min ('tablet') {
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 1rem;
                    font-weight: 700;
                    text-align: center;
                    background: linear-gradient(to right, #3CAD00, #C8D900 );
                    border-radius: 50px;
                    height: 40px;
                    min-height: 40px;
                    margin: 5% auto;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }
            }
            .link_comeBack {
                @media screen and (max-width: 767px) {
                    color: #fff;
                    text-transform: uppercase;
                    font-size: .7rem;
                    font-weight: 700;
                    text-align: center;
                    background: linear-gradient(270deg, #272C2B, #020202);
                    border-radius: 50px;
                    height: 40px;
                    min-height: 40px;
                    margin: 5% auto;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border: none;
                    
                }
                @include media-min ('tablet') {
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 1rem;
                    font-weight: 700;
                    text-align: center;
                    background: linear-gradient(270deg, #272C2B, #020202);
                    border-radius: 50px;
                    height: 40px;
                    min-height: 40px;
                    margin: 5% auto;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border: none;
                }
            }
    }

}



