@import '../../../styles/mixin';

.containerSpinner {
    background: linear-gradient(to right, rgba(200, 157, 46, 0.5), rgba(200, 157, 46, 1)), url('../../../../public/assets/images/bg-maintenance.png') center  no-repeat, #000000;
    background-size: cover;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__message {
        margin: 2rem;
        font-size: 1.3rem;
        color: #fff;
        @media (min-width: 668px) {
            font-size: 2rem;
        }
    }
}