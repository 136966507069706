@import '../../../styles/mixin';

  .container-voucher {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    font-family: 'Open Sans';
    color: #fff;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: .8rem;
    padding: 1rem;
    font-weight: lighter;
    background: linear-gradient(#090a0a,#212626);
    overflow: auto;
    @include media-min ('tablet'){
      font-size: 18px;
    }
    .link_return {
      position: absolute;
      top: 0;
      left: 0;
      margin: 1.2rem;
      img {
          width: 12px;
      }
      }
    .imagelogo {
      margin-left: auto;
      margin-right: auto;
      width: 90px;
      height: auto;
      @include media-min ('tablet'){
        width: 160px;
      height: 95px;
      }
    }
    h1 {
      color: white;
      font-size: 1.3rem;
      margin: 5rem 0 2rem;
      font-weight: bold;
      @include media-min ('tablet'){
        font-size: 22px;
        margin: 5rem 0 3rem;
      }
      @include media-min ('desktop-s') {
        margin: 5rem 0 3rem;
      }
    }
    p {
      color: white;
      width: 65%;
      margin-bottom: 2rem;
      @include media-min ('tablet'){
        width: 55%;
        margin-bottom: 3rem;
      }
      @include media-min ('desktop-s') {
        width: 40%;
      }
      @include media-min ('desktop-m') {
        width: 30%;
      }
      @include media-min ('desktop-l') {
        width: 25%;
      }
    }
    .container__input {
      display: flex;
      justify-content: center;
      width: 90%;
      flex-direction: column;
      margin-bottom: 5vh;
      @include media-min ('desktop-s') {
        width: 60%;
      }
      @include media-min ('desktop-m') {
        width: 40%;
      }
      @include media-min ('desktop-l') {
        width: 35%;
      }
      .first-row{
        display: flex;
        margin-bottom: 4vh;

      .prefix {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        font-size: .6rem;
        display: block;
        width: 50px;
        height: 100%;
        background: linear-gradient(to bottom, #3CAD00, #C8D900 );
        text-align: center;
        padding: .7rem .2rem;
        font-weight: bold;
        @include media-min ('tablet'){
          font-size: 1rem;
          width: 96px;
          padding: 1.2rem .2rem;
          height: 64px;
        }
     
    }
  }
  .input {
    border-radius: 0 10px 10px 0;
    border: none;
    width: 100%;
    padding-left: 20px;
    outline: none;
    @include media-min ('tablet'){
      width: 100%;
      height: 64px;
    }
  }
}
  .container__button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15%;
    .button-voucher {
        height: 35px;
        width: 156px;
        color: #fff;
        border-radius: 50px;
        border: none;
        background: linear-gradient(to right, #3CAD00, #C8D900 );
        text-transform: uppercase;
        font-size: .6rem;
        font-weight: 900;
      margin-bottom: 5vh;
        @include media-min ('tablet'){
          height: 64px;
          width: 320px;
          font-size: 1rem;
        }
    }
  }
  .code {
    display: flex;
    flex-direction: row;
    margin-bottom: 5vh;
    align-items: center;
    .territory {
      background: none;
      border: none;
      width: 6vw;
      color: #67c100;
      flex: 10%;
      text-align: right;
      font-weight: bold;
      @include media-min ('desktop-s') {
        font-size: 20px;
      }
      @include media-min ('desktop-m') {
        font-size: 25px;
      }
      @include media-min ('desktop-l') {
        font-size: 28px;
      }
    }
    .campaign {
      height: 35px;
      border-radius: 10px;
      border: none;
      width: 6vw;
      flex: 10%;
      @include media-min ('tablet'){
        height: 64px;
      }
    }
    .voucher {
      height: 35px;
      border-radius: 10px;
      border: none;
      flex: 60%;
      @include media-min ('tablet'){
        height: 64px;
      }
    }
    span {
      color: #67c100;
      margin-right: 10px;
      margin-left: 10px;
    }
  }
}
 