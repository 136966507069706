@import '../../../styles/mixin';

.container_onboarding_gender {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Open Sans";
  color: #fff;
  padding: 3vh;
  overflow: hidden;
  background: linear-gradient(to top, #212626, #090a0a);
  .bg-onboarding-gender {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .onboarding-gender-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 90vh;
      h1 {
        font-size: 1.5rem;
        font-weight: bold;
        padding-bottom: 2vh;
        @include media-min ('tablet') {
          font-size: 2.5rem;
          padding-bottom: 8vh;
        }
        @include media-min ('desktop-s') {
          font-size: 2.7rem;
          padding-bottom: 10vh;
          padding-top: 12vh;
        }
      }
      .gender-desktop {
        @include media-min ('tablet') {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          width: 100%;
        }
        @include media-min ('desktop-s') {
          justify-content: center;
        }
        .btn-gender {
          position: relative;
          background-color: transparent;
          display: flex;
          justify-content: center;
          border: none;

          p{
            position: absolute;
            bottom: 7vh;
            color: white;
            text-transform: uppercase;
            font-weight: 900;
            @include media-min ('tablet') {
              bottom: 4vh;
              font-size: 1.5rem;
            }
            @include media-min ('desktop-m') {
              font-size: 2rem;
            }
            @include media-min ('desktop-l') {
              font-size: 2.5rem;
            }
          }
         
          .gender {
            width: 45vw;
            border: solid 8px;
            @media (min-width: 400px) {
              width: 30vw;
            }
            @media (min-width: 700px) {
              width: 22vw;
            }
            @media (max-width: 767px) {
              margin-bottom: 5vh;
            }
            @include media-min ('tablet') {
              width: 30vw;
            }
            @include media-min ('desktop-s') {
              width: 20vw;
              margin-right: 2vw;
              margin-left: 2vw;
            }
          }
        }
      }
      .noanswer {
        background-color: transparent;
        border: none;
        color: #fff;
        padding: 20px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.8rem;
        margin-bottom: 2vh;
        @include media-min ('tablet') {
          padding-top: 8vh;
          font-size: 1rem;
        }
        @include media-min ('desktop-s') {
          padding-top: 12vh;
          font-size: 1.2rem;
        }
      }
    }
  }
}
