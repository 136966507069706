@import '../../../styles/mixin';

.playlist {
  .flex-column {
    width: 100%;
    .sticky-top-sticky {
      background: linear-gradient(to bottom, #272c2b, #000000);
      height: 17vh;
      position: sticky;
      top: 0;
      justify-content: center;
      width: 100%;

      .sticky-description {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        h1 {
          font-family: "Open Sans";
          font-weight: 900;
        }
        .d-column-flex {
          p:first-child {
            font-family: "Open Sans";
            font-weight: 300;
            font-size: 16px;
          }
          p:nth-child(2) {
            font-family: "Open Sans";
            font-weight: 600;
            font-size: 18px;
          }
        }
      }
      #back {
        border: none;
        background-color: inherit;
        height: 0;

        img {
          width: 15px;
        }
      }
      #play {
        justify-content: center;
        align-items: center;
        width: 13vh;
        height: 13vh;
        border-radius: 100%;
        img {
          width: 39px;
          margin-right: 0;
        }
      }
    }
  }
  .mt-3 {
    margin-top: 0;
  }

  /* .bg-black {
    background-color: #000000;
    img {
      width: 50px;
      margin-left: 15px;
      height: 100%;
      flex: 10% 1;
    }
  } */

  .bg-secondary {
    background-color: #6c757d;

    // img {
    //   width: 50px;
    //   margin-left: 15px;
    //   height: 100%;
    //   flex: 10% 1;
    // }
  }

  .list-group {
    //background-color: #000000;
    //margin-bottom: 4rem;
    /* p {
      margin-bottom: 0;
      font-size: 13px;
      font-family: "Open Sans";
      font-weight: 300;
    }

    p:first-child {
      font-weight: bold;
      font-size: 15px;
      margin-bottom: 0;
      font-family: "Open Sans";
      font-weight: 800;
    } */
  }
  /* .buttonHeart {
    background-color: inherit;
    border: none;
    flex: 5%;

    img {
      width: 20px;
      height: 20px;
      margin-right: 15px;
    }
  } */
  /* .d-inline-flex.mb-2 {
    align-items: flex-start;
  } */
}

/* @media (max-width: 399px) {
  .playlist {
    padding-bottom: 110px;
    .flex-column {
      .sticky-top-sticky {
        background: linear-gradient(to bottom, #272c2b, #000000);
        height: 16vh;
        position: sticky;
        top: 0;
        justify-content: center;

        button#back {
          display: none;

          img {
            display: none;
          }
        }

        .sticky-description {
          margin-top: 0;
          justify-content: center;
          display: initial;
          width: initial;
          h1 {
            display: none;
          }
          .d-column-flex {
            width: 200px;
            p:first-child {
              display: none;
            }
            p:nth-child(2) {
              display: none;
            }
          }
        }

        #playSticky {
          width: 70vw;
          height: 50px;
          background: linear-gradient(to right, #3cad00, #c8d900);
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          border-radius: 3px;
          color: white;
          font-weight: bold;
          font-size: 17px;

          img {
            width: 17px;
            margin-right: 20px;
          }
          p {
            margin-bottom: 0;
            font-weight: 700;
            font-family: "Open Sans";
            text-transform: uppercase;
          }
        }
      }
    }
    #point {
      display: none;
    }
    .list-group {
      .d-inline-flex.mb-2 {
        margin-top: 10px;
        .justify-content-between {
          flex: inherit;
        }
      }
    }
    .p-2 {
      display: none;
    }
    .playButton {
      display: none;
    }
    .mobilePlayOnClick {
      pointer-events: all;
    }
  }
}
@media (min-width: 500px) {
  .playlist {
    padding-bottom: 9%;
    .navbar-nav {
      margin-right: 0;
    }
    .list-group {
      //background-color: #000000;

      .lastInfoPlaylist {
        .p-2 {
          font-size: 13px;
          padding: 0;
          margin-bottom: 0;
        }
      }

      .buttonHeart img {
        width: 30px;
        height: auto;
      }

      .playButton {
        background-color: inherit;
        border: none;

        img {
          width: 30px;
          margin-right: 0;
        }
      }

      .d-inline-flex.mb-2 {
        background-color: #0f1112;
        align-items: center;
        height: 100px;

        .lastInfoPlaylist {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        .track-description-md {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          width: 35vw;

          #point {
            margin-right: 50px;
          }

          p {
            font-size: 14px;
            color: #d8d8d8;
            width: 700px;
          }

          p:first-child {
            font-size: 14px;
            color: #ffffff;
            width: 700px;
          }
        }
      }
    }
  }
  .bg-black,
  .bg-secondary {
    img {
      height: auto;
    }

    img.d-block {
      flex: inherit;
      height: auto;
      padding: 15px;
      width: 105px;
      z-index: 1;
    }
  }
  .sticky-description {
    .backgroundImageSticky {
      display: none;
    }
    #playSticky {
      width: 10vw;
      height: 10vw;
    }
    .centralButtonSticky {
      display: flex;
      align-items: center;
      p {
        display: none;
      }
    }
  }
}
@include media-min ('tablet') {
  .playlist {
    .flex-column {
      //margin-left: 21.1%;
    }

    .navbar-container {
      top: 0;
    }
  }
}
@include media-min ('desktop-s') {
  .playlist {
    padding-bottom: 50px;
    .list-group {
      .d-inline-flex.mb-2 {
        height: 75px;
        .justify-content-between {
          .track-description-md {
            flex: 75%;
            p {
              font-size: 16px;
            }

            p:first-child {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
@include media-min ('desktop-m') {
  .playlist {
    padding-bottom: 50px;
    .list-group {
      .d-inline-flex.mb-2 {
        .justify-content-between {
          flex: inherit;
          display: block;
          .track-description-md {
            flex: 75%;
            width: 55vw;
            p {
              font-size: 18px;
            }

            p:first-child {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
}
.mobilePlayOnClick {
  pointer-events: none;
}
.d-inline-flex.mb-2 {
  height: 70px;
} */
