@import '../../../styles/mixin';

.container_onboarding_birth {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  font-family: "Open Sans";
  color: #fff;
  padding: 3rem;
  background: linear-gradient(to top, #212626, #090a0a);
  .bg-onboarding-birth {
     display: flex;
     flex-direction: column;
     justify-content: center;
    .onboarding-birth-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 90vh;
      h1 {
        text-align: center;
        font-size: 1.4rem;
        font-weight: bold;
        width: 80%;
        padding-bottom: 6vh;
        @include media-min ('tablet') {
          font-size: 1.6rem;
        }
        @include media-min ('desktop-s') {
          font-size: 1.8rem;
        }
        @include media-min ('desktop-m') {
          font-size: 2rem;
        }
        @include media-min ('desktop-l') {
          font-size: 2.2rem;
        }
      }
      .btn-birth {
        height: 50px;
        width: 60vw;
        color: #fff;
        border-radius: 50px;
        border: none;
        background: linear-gradient(to right, #3cad00, #c8d900);
        text-transform: uppercase;
        font-size: 0.9rem;
        font-weight: 700;
        margin-top: 12vh;
        @include media-min ('tablet') {
          width: 40vw;
          height: 60px;
        }
        @include media-min ('desktop-s') {
          width: 30vw;
          height: 60px;
          font-size: 1rem;
        }
        @include media-min ('desktop-m') {
          height: 70px;
          font-size: 1.2rem;
        }
        @include media-min ('desktop-l') {
          height: 80px;
          font-size: 1.3rem;
        }
      }
      .input-birth {
        width: 40vw;
        height: 9vh;
        margin-top: 6vh;
        @include media-min ('tablet') {
          width: 30vw;
        }
        @include media-min ('desktop-s') {
          width: 20vw;
        }
      }
    }
  }
}
