@import '../../../styles/mixin';

.container-mymusic {
  //min-height: 100vh;
  font-size: 1rem;
  padding-bottom: 150px;
  overflow: hidden;
  @include media-min('desktop-m') {
    padding-bottom: 100px;
  }
  .title-page {
    @media (max-width: 767px) {
    }
    @include media-min('desktop-s') {
      margin-bottom: 3.5rem;
    }
    @include media-min('desktop-m') {
      margin-bottom: 4rem;
    }
  }
  .title-section {
    font-size: size(10);
    font-weight: 800;
    margin-bottom: 10px;
    @include media-min('tablet') {
      font-size: size(18);
      margin-bottom: 22px;
      //padding-bottom: 1.5rem;
    }
    @include media-min('desktop-s') {
      font-size: size(22);
      margin-bottom: 24px;
    }
    @include media-min('desktop-m') {
    }
    @include media-min('desktop-l') {
    }
  }
  .fav-tracks {
    margin-bottom: 20px;
    @include media-min('tablet') {
      margin-bottom: 42px;
    }

    .buttontracksfavorites {
      background: linear-gradient(#C8D900,#3CAD00 );
      border: none;
      border-radius: 5px;
      display: flex;
      height: 100px;
      padding: 0;
      margin-bottom: 0.5rem;
      width: 100%;
      justify-content: space-around;
      align-items: center;
      flex-direction: row;
      //margin-left: 15px;
      //margin-top: 15px;
      @include media-min('tablet') {
        height: 160px;
        margin-bottom: 1rem;
      }
      @include media-min('desktop-s') {
        height: 192px;
      }
      @include media-min('desktop-m') {
      }
      @include media-min('desktop-l') {
        height: 224px;
      }

     .title-fav-track {
      width: 100px;
      color: #fff;
      font-size: size(15);
      font-weight: 800;
      @include media-min('tablet') {
        font-size: size(18);
        width: 110px;
      }
      @include media-min('desktop-s') {
        font-size: size(22);
        width: 120px;
      }
      @include media-min('desktop-m') {
        font-size: size(24);
        width: 130px;
      }
      @include media-min('desktop-l') {
        font-size: size(26);
        width: 140px;
      }

    }
    }

    .myplaylist-tablet {
      display: flex;
      align-items: center;
    }
    .fav-desc-container-tablet {
      /* #myplaylist {
        display: none;
        @include media-min('desktop-s') {
          color: #fff;
          display: block;
          font-size: 18px;
          font-weight: 800;
          margin: 0 0 .5rem;
        }
      } */
      .fav-desc-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include media-min('desktop-s') {
          //margin-top: 2vh;
        }
      }
      .fav-desc-content-tablet {
        @include media-min('tablet') {
          display: flex;
          flex-direction: row;
          //margin-left: 40px;
        }
      }
      .fav-desc {
        display: flex;
        p {
          font-size: 10px;
          margin: 0 5px 0 0;
          @include media-min('tablet') {
            font-size: 16px;
            margin: 0;
          }
        }
        p:nth-child(even) {
          @include media-min('tablet') {
            margin-left: 5px;
          }
        }
        p:nth-child(even):after {
          @include media-min('tablet') {
            content: '.';
            margin-left: 5px;
          }
        }
        .duration-tablet {
          @include media-min('tablet') {
            margin-left: 5px;
          }
        }
      }
    }
    .btn-play {
      display: none;
    }
    /* .btn-play {
      background: linear-gradient(#3cad00, #c8d900);
      border-radius: 50%;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 1.8rem;
      outline: none;
      padding: 0;
      width: 1.8rem;
      @include media-min('tablet') {
        height: 2.5rem;
        margin-left: 1.25rem;
        width: 2.5rem;
      }
      &:focus {
        outline: none;
      }
    } */
  }
  .buttonlistfavorites {
    background: linear-gradient(#3CAD00,#C8D900 );
    border: none;
    border-radius: 5px;
    display: flex;
    height: 122px;
    width: 122px;
    padding: 0;
    margin-bottom: 0.5rem;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    @include media-min('tablet') {
      height: 160px;
      width: 160px;
      margin-bottom: 1rem;
    }
    @include media-min('desktop-s') {
      height: 192px;
      width: 192px;
    }
    @include media-min('desktop-m') {
    }
    @include media-min('desktop-l') {
      height: 224px;
      width:  224px;
    }
  }
  .linear-gradient {
    display: flex;
    background: linear-gradient(#3CAD00,#C8D900 );
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border-radius: 5px;
    position: relative;
    @include media-min('tablet') {
      width: 90px;
    height: 90px;
    }
    @include media-min('desktop-s') {
      width: 100px;
      height: 100px;
    }
    @include media-min('desktop-m') {
      width: 110px;
      height: 110px;
    }
    @include media-min('desktop-l') {
      width: 120px;
      height: 120px;
    }
    .heart {
      position: absolute;
      top: 5px;
      right: 5px;
    }

   }
  .empty-fav-container {
    margin: 2vh 0;
    &:empty {
      display: none;
    }
    @include media-min('desktop-s') {
      margin: 4vh 0;
    }
    .empty-fav {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include media-min('tablet') {
        align-items: flex-start;
        padding-left: 40px;
      }
      .title-section {
        margin-left: 0;
      }
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(to right, #3cad00, #c8d900);
        border-radius: 5px;
        color: #fff;
        border: none;
        margin: 15px;
        text-transform: uppercase;
        font-weight: bold;
        width: 50vw;
        height: 6vh;
        @include media-min('tablet') {
          width: 30vw;
          margin: 15px 0;
        }
      }
    }
  }

  .body-myPlaylist {
    margin-bottom: 0;
    @include media-min('tablet') {
      margin-bottom: 25px;
    }
    @include media-min('desktop-s') {
      margin-bottom: 24px;
    }
    @include media-min('desktop-m') {
      margin-bottom: 34px;
    }
    @include media-min('desktop-m') {
      margin-bottom: 40px;
    }
  }
  .myplaylist-desc {
    display: flex;
    margin-bottom: 10px;
    @include media-min('tablet') {
      align-items: center;
      margin-bottom: 16px;
    }
    @include media-min('desktop-s') {
      margin-bottom: 24px;
    }
    .title-section {
      margin-right: 5px;
      @include media-min('tablet') {
        margin-bottom: 0;
      }
    }
    img {
      margin-left: 0.5rem;
      width: 15px;
      @include media-min('tablet') {
        width: 30px;
        margin-left: 1rem;
      }
    }
  }

  .body-suggestions {
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
    position: relative;
    .slider_content {
      margin-right: -14px;
      position: relative;
      @include media-min('tablet') {
        margin-right: 0;
      }
      .react-multi-carousel-list {
        @include media-min('tablet') {
          // padding-left: 20px;
          // margin-bottom: 2vh;
        }
      }
      .react-multi-carousel-item {
        padding-right: 14px;
        @include media-min('tablet') {
          padding-right: 2rem;
        }
      }
      .react-multiple-carousel__arrow {
        display: none;
      }
      .carousel-button-group {
        @media (max-width: 767px) {
          display: none;
        }
        @include media-min('tablet') {
          top: -54px;
          right: -0.75rem;
        }
        img {
          height: 16px;
          width: 16px;
        }
      }
    }
  }
  .link-item {
    border-radius: 2px;
    display: block;
    position: relative;
    text-decoration: none;
    @include media-min('tablet') {
      border-radius: 5px;
    }
    &:hover {
      text-decoration: none;
    }
  }

  .show-desktop {
    button {
      font-family: 'Permanent Marker', cursive;
      font-size: 16px;
      background: linear-gradient(to right, #3cad00, #c8d900);
      border-radius: 4px 4px 0 0;
      border: 0px;
      height: 60%;
      width: 100px;
      margin-right: 20px;
      font-size: 15px;
      @include media-min('tablet') {
        font-size: 22px;
        height: 52%;
        width: 170px;
      }
      @include media-min('tablet') {
        height: 57%;
      }
      @include media-min('desktop-m') {
        font-size: 25px;
        height: 60%;
        width: 190px;
      }
      @include media-min('desktop-l') {
        font-size: 28px;
        height: 64%;
        width: 228px;
      }
    }
  }
  .navbar-container {
    z-index: 1;
  }
}

.container-playlist-suggestion {
  width: 100%;
  @include media-min('tablet') {
    height: auto;
    width: 160px;
  }
  @include media-min('desktop-s'){
    width: 192px;
  }
  @include media-min('desktop-l'){
    width: 224px;
  }
  .container-play,
  .btn-play,
  .playlistDescription {
    display: none;
  }
  .picture_content {
    border-radius: 2px;
    margin: 0 0 0.5rem;
    @include media-min('tablet') {
      border-radius: 5px;
      margin-bottom: 1rem;
    }
    img {
      border-radius: inherit;
      display: block;
      max-width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center top;
      width: 100%;
    }
  }
  .container-description {
    position: relative;
    @include media-min('tablet') {
    }
    @include media-min('desktop-m') {
    }
    @include media-min('desktop-l') {
    }
  }
  .playlistTitle {
    color: #fff;
    font-size: 0.5rem;
    font-weight: 800;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    @include media-min('tablet') {
      font-size: 0.875rem;
    }
    @include media-min('desktop-s') {
      font-size: 1rem;
    }
  }

}

