@import "../../../styles/mixin";
/*
.container-main-carousel {
    .carousel-button-group {
        display: none;
        @include media-min ('tablet') {
            display: inline;
            top:0.5rem;
            right: 0;
            margin-bottom: 2rem;
        }
        @include media-min ('desktop-m') {
            top:0.6rem;
        }
    } 
}
*/
.container__only {
  margin-bottom: size(28);
  @include media-min("desktop-l") {
    margin-bottom: size(50);
  }
}

.container-titles-only {
  /* display: flex;
    padding: 0;
    align-items: baseline;
    justify-content: space-between; */
  /* button {
        background: transparent;
        border: none;
        @include media-min ('tablet') {
            display: none !important;
        }
        img {
            width: 10px;
            height: 15px;
        }
    } */
  .link__title {
    @include media-min("tablet") {
      display: flex;
      align-items: baseline;
    }
    img {
      //display: none;
      @include media-min("tablet") {
        display: block;
        width: 10px;
        height: 15px;
      }
    }
    /* .title-only {
            color: #fff;
            font-weight: 800;
            font-size: 14px;
            margin-right: 1rem;
            margin-top: 1rem;
            @media (min-width:768px) {
                font-size: 18px;
            }
            @include media-min ('desktop-m') {
                font-size: 22px;
            }
        } */
  }
  /* .button__seemore {
        font-size: 14px;
        color: white;
        @include media-min ('desktop-s') {
            display: none;
        }
    } */

  .title-discover {
    font-size: 0.7rem;
    font-weight: 800;
    @media (min-width: 768px) {
      font-size: 15px;
    }
  }
}

.container__playlist {
  height: auto;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  //width: 87%;
  //margin-bottom: 2rem;
  /* .container__description {
        height: 2rem;
        color: #fff;
        margin-top: 0.25rem;
        :hover {
            text-decoration: none;
        }
        @include media-min ('tablet') {
            height: auto;
            margin-top: 1rem;
            font-size: 16px;
        }
        @include media-min ('desktop-m') {
            margin-top: 0.5rem;
        }
    } */

  .picture_content {
    border-radius: 5px;
    margin: 0;
    position: relative;
  }
  .picture {
    border-radius: inherit;
    //min-height: 122px;
    max-width: 100%;
    width: 100%;
    @include media-min("tablet") {
      //min-height: 192px;
    }
    @include media-min("desktop-l") {
      //min-height: 224px;
    }
  }
  .playlistTitle {
    color: #fff;
    font-size: size(10);
    font-weight: 800;
    padding-top: 0.5rem;
    margin-bottom: 0;
    @include media-min("tablet") {
      font-size: 1rem;
      padding-top: 1rem;
    }
    @include media-min("desktop-m") {
      //font-size: 14px;
    }
    @include media-min("desktop-l") {
      //font-size: 15px;
    }
  }
  .image-button {
    width: 50%;
    margin-left: 32%;
    margin-right: auto;
    @include media-min("tablet") {
      width: 50%;
    }
  }
}

/* @media (max-width: 480px) {
    .see-all{
        width: 100px !important;
        padding: 0 !important;
        text-align: right !important;
    }
    .title-only {
        margin-right: 0 !important;
    }
} */
