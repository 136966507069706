@import '../../../styles/mixin';

.container-page {
  &.container-explorer {
    height: 100vh;
    @media (max-width: 767px) {
      background: linear-gradient(180deg, #000000, #232529);
    }  
  }  
}
.container-explorer {
  .title-page {
    @media (max-width: 767px) {
      display: block;
      margin: 0 0 1rem;
      text-align: center;
    }
    @include media-min ('tablet') {
      margin-bottom: 2rem;
    }
    @include media-min ('desktop-s') {
      
    }
    @include media-min ('desktop-m') {
      font-size: size(34);
      margin-bottom: 1rem;
    }
    @include media-min ('desktop-l') {
      font-size: size(34);
    }
  }
  .main_content {
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
    position: relative;
    @include media-min ('tablet') {
    }
  }
  .body-width {
    display: flex;
    flex-direction: column;
    height: 100%;
    > div:nth-child(2) {
      flex: 1 1 100%;
      height: calc(100% - 48px);
      overflow: hidden auto;
      @include media-min ('tablet') {
        height: calc(100% - 256px);
      }

      
    }
    .loader {
      position: fixed;
      bottom: 0;
      width: 100%;
      justify-content: center;
      display: flex;
      background-color:#212626 ;
      padding: 10px;
    }
  }
  .hide-mobil {
    @media (max-width: 767px) {
      display: none;
    }
  }
  .filter-container {
    //background: linear-gradient(180deg, #272C2B, #FE000000);
    display: flex;
    flex-direction: column;
    flex: 1 0 48px;
    //position: fixed;
    // left: 0;
    // top: 0;
    overflow: hidden;
    width: 100%;
    z-index: 2;
    @media (max-width: 767px){
      overflow: visible;
      //height: 120px;
      margin-bottom: 30px;
      flex: 1 0 100px;
    }
    @include media-min ('tablet') {
      flex-basis: 256px;
      // left: 160px;
      // width: calc(100% - 160px);
    }
    // @include media-min ('desktop-s') {
    //   left: 192px;
    //   width: calc(100% - 192px);
    // }
    // @include media-min ('desktop-m') {
    //   left: 224px;
    //   width: calc(100% - 224px);
    // }
  }
  .background-linear {
    //background: #000000;
    flex: 1 0 0;
    //height: 256px;
    padding: .875rem .875rem 0;
    @include media-min ('tablet') {
      background: linear-gradient(to bottom, #272c2b, #000000);
      flex: 1 0 256px;
      height: 256px;
      padding: 2rem 2rem 4rem;
    }
    @include media-min ('desktop-s') {
      padding: 3rem 2rem 4rem;
    }
    @include media-min ('desktop-m') {
      padding: 3rem 4rem 4rem;
    }
    @include media-min ('desktop-l') {
      padding: 3rem 6rem 4rem;
    }
  }
  .mobil-title {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;
    min-height: 1.25rem;
    //margin: 0 0 1rem;
    margin: 0;
    position: relative;
    //margin-top: 30px;
    &:empty {
      display: none;
    }
    // @include media-min ('tablet') {
    //   align-items: center;
    //   display: flex;
    //   padding-left: 15px;
    // }
    /* @media (max-width: 767px) and (orientation: landscape) {
      height: 50px;
      align-items: flex-start;
    } */
    @include media-min ('tablet') {
      display: none;
    }
    .title-page {
      margin: 0;
    }
    .backArrow {
      display: block;
      line-height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      width: 13px;
      height: 20px;
      img {
        @media (max-width: 767px) {
          width: 13px;
        }
      }
    }
  }
  .filters {
    width: 100%;
    @include media-min ('tablet') {
      //height: 100%;
      width: 100%;
      overflow: hidden;
      //overflow-x: scroll;
    }
    .filters-header {
      width: 100%;
      @include media-min ('desktop-s') {
        display: flex;
        align-items: center;
      }
    }
    .labeled {
      color: #fff;
      font-size: size(10);
      font-weight: 800;
      margin-bottom: size(10);
      white-space: nowrap;
      @media (min-width: 600px) {
        font-size: size(14);
      }
      @include media-min ('tablet') {
        font-size: 1rem;
        margin-right: .5rem;
      }
      @include media-min ('desktop-s') {
        margin-right: size(50);
        margin-bottom: 0;
      }
      @include media-min ('desktop-m') {
        margin-right: size(80);
      }
    }
    .filter-md {
      @include media-min ('desktop-s') {
        display: flex;
        align-items: center;
      }
      @include media-min ('tablet') {
        position: relative;
        z-index: 3;
      }
    }
    .close-btn {
      background: none;
      border: none;
      color: rgba($color: #fff, $alpha: .5);
      font-size: 1rem;
      font-weight: 800;
      flex-basis: auto;
      height: auto;
      min-width: unset;
      padding: 0;
      position: absolute;
      right: 0; top: 0;
      text-transform: uppercase;
      width: auto;
      @media (max-width: 767px) {
        display: none;
      }
      @include media-min ('desktop-s') {
        margin-left: auto;
        position: relative;
      }
      @include media-min ('desktop-m') {
        font-size: 1rem;
        //margin-left: 42%;
        
      }
      @include media-min ('desktop-l') {
        //margin-left: 82%;
      }
    }
  }
  .flex-filter {
    display: flex;
    align-items: center;
    height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    // padding-right: 50vw;
    // margin-top: 0.5rem;
    &::-webkit-scrollbar {
      display: none;
    }
    
    button {
      background-color: transparent;
      border-style: solid;
      border-color: #fff;
      border-radius: 5px;
      border-width: 2px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 0 1 122px;
      font-size: 10px;
      font-weight: 800;
      height: 44px;
      margin-right: 14px;
      min-width: 122px;
      padding: .625rem;
      text-transform: uppercase;
      width: 122px;
      @media (min-width: 600px) {
        font-size: size(14);
      }
      @include media-min ('tablet') {
        flex-basis: 160px;
        font-size: size(16);
        justify-content: space-between;
        height: 64px;
        min-width: 160px;
        padding: .625rem 1rem;
        width: 160px;
      }
      @include media-min ('desktop-m') {
        flex-basis: 192px;
        min-width: 192px;
        width: 192px;
      }
      @include media-min ('desktop-l') {
        flex-basis: 224px;
        min-width: 224px;
        width: 224px;
      }
      &:last-child {
        margin-right: 0;
      }
      img {
        display: block;
        width: 16px;
        @media (max-width: 767px) {
          display: none;
        }
      }
      span {
        color: inherit;
      }
      &.show-desktop-active {
        border-color: #fff;
        color: #fff;
        img {
          width: 12px;
        }
      }
      //margin-right: .875rem;
      //flex: 1 0 145px;
      &:not(.show-desktop-active) {
        &.show-desktop {
          border-color: gray;
          color: gray;
        }
      }
      &.show-desktop-active {
        border-color: #fff;
        color: #fff;
      }
    }
  }
  .active-filters {
    display: flex;
    justify-content: space-between;
    padding-right: 24px;
    position: relative;
    padding-left: 1rem;
    @media (max-width: 767px) {
      margin-top: 50px;
    }
    @include media-min ('tablet') {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    @include media-min ('desktop-m') {
      padding-left: 4rem;
      padding-right: 4rem;
    }
    @include media-min ('desktop-l') {
      padding-left: 6rem;
      padding-right: 6rem;
    }
    .filter-choice {
      display: flex;
      flex-wrap: wrap;
      margin: -5px;
      overflow: hidden;
      padding-bottom: 1rem;
      @include media-min ('tablet') {
        width: 100%;
      }
      @include media-min ('desktop-s') {
        padding-bottom: 2rem;
      }
      &:empty {
        display: none;
      }
      button {
        background-color: grey;
        border: none;
        border-radius: 5px;
        color: white;
        font-size: size(12);
        font-weight: 800;
        display: flex;
        justify-content: space-between;
        height: 32px;
        //margin-bottom: 4%;
        margin: 5px;
        padding: .5rem .625rem;
        text-transform: uppercase;
        width: fit-content;
        span:first-child {
          margin-right: .5rem;
        }
        .closeBtn {
          color: inherit;
          font-weight: 700;
        }
      }
    }
    .delete-btn-mobil {
      background-color: transparent;
      //margin-right: 37px;
      border: none;
      height: 24px;
      line-height: 100%;
      padding: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;
      @include media-min ('tablet') {
        display: none;
      }
      img {
        width: 22px;
      }
    }
  }
  .filter-choice-mobile {
    overflow: hidden;
    .filter-choice {
      display: flex;
      flex-wrap: wrap;
      z-index: 2;
      width: 100%;
      margin-left: 2%;
      @media (max-width: 767px){
        margin-top: 20px;
      }
      @include media-min('tablet'){
        margin-left: 30px;
      }
      @include media-min('desktop-s'){
        margin-left: 65px;
      }
      @include media-min('desktop-m'){
        margin-left: 95px;
      }

      .delete-btn-mobil {
        background-color: transparent;
        margin-right: 37px;
        @include media-min ('tablet') {
          display: none;
        }
        img {
          width: 22px;
        }
      }
      .flex.mobile {
        @media (max-width: 767px) {
          width: 93%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      button {
        text-transform: uppercase;
        color: white;
        background-color: grey;
        padding: 10px 5px 10px 5px;
        border: none;
        border-radius: 3px;
        width: max-content;
        margin-bottom: 4%;
        margin-right: 15px;
        display: flex;
        justify-content: space-between;
        font-size: 9px;
        @include media-min('tablet'){
          height: 40px;
          font-size: 13px;
        }
        p:first-child {
          margin-right: 15px;
        }
        span {
          margin: 0 10px;
        }
      }
    }
  }
  .filter-category-content {
    background: #000000;
    flex: 1 1 100%;
    max-height: 100vh;
    width: 100%;
    @include media-min ('tablet') {
      position: absolute;
      left: 0;
      top: 205px;
      background: linear-gradient(0deg, #212626, #090A0A);
      min-height: 60px;
    }

    &:empty {
      display: none;
    }
    .filter-innercontent {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      //padding: 1.25rem .875rem;
      &:empty {
        display: none;
      }
    }
  }
  .apply {
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 128px;
    height: 128px;
    position: absolute;
    left: 0;
    width: 100%;
    z-index: 2;
    @media (max-width: 767px) {
      position: absolute;
      //display: none;
      bottom: 50px;
    }
    @include media-min ('tablet') {
      //width: calc(100% - 160px);
      bottom: 200px;
      //padding-left: 160px;
    }
    .apply_content {
      position: relative;
    }
    .apply-btn {
      background: linear-gradient(270deg, #B1D00E, #5FB101);
      border: none;
      border-radius: 2px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: size(11);
      font-weight: 800;
      height: 40px;
      text-transform: uppercase;
      width: 156px;
      @include media-min ('tablet') {
        border-radius: size(48);
        font-size: size(18);
        height: 64px;
        width: 320px;
      }
    }
    .delete-btn {
      background: none;
      border: none;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: size(18);
      font-weight: 800;
      height: 28px;
      padding: 0;
      position: absolute;
      right: -100px; top: 20px;
      text-transform: uppercase;
      @media (max-width: 767px) {
        display: none;
      }
      @include media-min ('desktop-s') {
        right: -100px;
      }
      @include media-min ('desktop-m') {
        right: -260px;
      }
      img {
        width: 24px;
        @include media-min ('desktop-m') {
          display: none;
        }
      }
      .text {
        @media (max-width: 1439px) {
          display: none;
        }
      }
    }
  }
  .apply-with-player {
    bottom: calc(50px);
    @include media-min('tablet'){
      bottom: calc(200px + 64px);
    }
    @include media-min('desktop-s'){
      bottom: calc(200px + 70px);
    }
  }
  .absolute {
    //padding-top: 2rem;
    height: 100vh;
    overflow: hidden auto;
    width: 100%;
    .category-pannel {
      position: relative;
      width: 100%;
      z-index: 1;
      padding-left: 1rem;
      @include media-min ('tablet') {
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 2rem;
        overflow: auto;
        max-height: calc(100vh - 200px);
      }
      @include media-min ('desktop-m') {
        padding-left: 4rem;
        padding-right: 4rem;
      }
      @include media-min ('desktop-l') {
        padding-left: 6rem;
        padding-right: 6rem;
      }
      .flex {
        display: flex;
        flex-wrap: wrap;
        overflow: auto;
      }
      button {
        background-color: grey;
        border: none;
        border-radius: 3px;
        color: white;
        display: flex;
        font-size: 12px;
        font-weight: 800;
        justify-content: center;
        align-items: center;
        margin-bottom: 11px;
        margin-right: 11px;
        padding: 15px 5px 15px 5px;
        text-transform: uppercase;
        width: 29%;
        height: 60px;
        @media (max-width: 767px){
         max-width: 128px;
      }
        @include media-min ('tablet') {
          height: 95px;
          font-size: 18px;
          font-weight: 700;
          margin-right: 18px;
          margin-bottom: 18px;
        }
        @include media-min ('desktop-s') {
          margin-right: 25px;
          margin-bottom: 25px;
          width: 220px;
        }
        // @media (max-width: 850px) and (orientation: landscape) {
        //   height: 60px;
        //   font-size: 15px;
        //   width: 22%;
        // }
        @include media-min ('desktop-m') {
          margin-right: 30px;
          margin-bottom: 30px;
          width: 155px;
        }
        @include media-min ('desktop-l') {
          width: 187px;
        }
      }
      .mood-btn {
        background-color: grey;
        color: white;
        border: none;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-transform: uppercase;
        
        padding: 15px 5px 15px 5px;
        
        border-radius: 3px;
        width: 88px;
        height: 83%;
        margin-bottom: 12px;
        font-size: 13px;
        @include media-min ('tablet') {
          height: 155px;
          width: 155px;
          font-size: 18px;
          font-weight: 700;
          margin-right: 16px;
          margin-bottom: 16px;
        }
        @media (max-width: 850px) and (orientation: landscape) {
          height: 110px;
          width: 22%;
        }
        @include media-min ('desktop-m') {
          width: 180px;
          height: 180px;
          margin-left: 0;
          font-size: 26px;
        }
        @include media-min ('desktop-l') {
          width: 200px;
          height: 200px;
          font-size: 28px;
        }
        img {
          width: 24px;
          margin-bottom: 10px;
          @include media-min ('tablet') {
            width: 75px;
          }
          @include media-min ('desktop-m') {
            width: 60px;
          }
          @include media-min ('desktop-l') {
            width: 70px;
          }
        }
      }
    }
    .category-pannel-with-player-only{
      overflow: auto;
      max-height: calc(100vh - 275px);
    }
    .category-pannel-with-apply-btn{
      overflow: auto;
      max-height: calc(100vh - 350px);
      @include media-min ('tablet') {
        max-height: calc(100vh - 400px);
      }
    }
    .category-pannel-with-player-and-btn{
      @include media-min ('tablet') {
        overflow: auto;
        max-height: calc(100vh - 500px);
      }
    }
  }

  .container__playlistGrid {
    padding: 1.25rem .875rem 5rem;
    width: 100%;
    max-width: 100%;
    height: calc(100vh - 150px);
    @media (max-width: 767px) {
      overflow: scroll;
      height: calc(100vh - 200px);
    }
    @include media-min ('tablet') {
      height: auto;
      padding: 3rem 2rem 5rem;
    }
    @include media-min ('desktop-s') {
      padding-left: 4rem;
      padding-right: 4rem;
      padding-bottom: 7rem;
    }
    @include media-min ('desktop-m') {
      padding-left: 6rem;
      padding-right: 6rem;
      padding-bottom: 8rem;
    }
    .content__list {
      display: flex;
      flex-wrap: wrap;
      
      height: auto;
      margin: size(-14);
      @include media-min ('tablet') {
        height: auto;
      }
      
      .text-white {
        flex: 0 1 33.33333%;
        padding: size(14);
        @include media-min ('desktop-m') {
          flex-basis: 20%;
        }
        > a {
          display: block;
          text-decoration: none;
          &:hover {
            text-decoration: none;
          }
        }
        .container__img {
          border-radius: 5px;
          margin: 0 0 .5rem;
          @include media-min ('tablet') {
            margin-bottom: 1rem;
          }
          img {
            border-radius: 5px;
            display: block;
            object-fit: cover;
            object-position: center top;
            height: 100%;
            max-width: 100%;
            width: 100%;
          }
        }
        .container-description {
        }
        .title {
          color: #fff;
          font-size: .5rem;
          font-weight: 600;
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-word;
          @media (min-width: 600px) {
            font-size: .875rem;
            font-weight: 800;
          }
          @include media-min ('tablet') {
            font-size: 1rem;
          }
        }
      }
    }
  }
  .container-playlistGrid-with-player{
    height: calc(100vh - 270px);
  }
  .container-description {
    .container-play {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.2rem;
      .btn-play {
        display: none;
        @include media-min ('tablet') {
          display: flex;
          justify-content: center;
          align-items: center;
          background: linear-gradient(#3cad00, #c8d900);
          border-radius: 50%;
          width: 2.3rem;
          height: 2.3rem;
          border: none;
          outline: none;
        }
        img.image-button{
          padding-right: 0 !important;
        }

        .image-button {
          width: 50%;
          margin-left: 32%;
          margin-right: auto;
        }
      }
    }
  }
}


.explorer {
  /* .navbar-container {
    z-index: 4;
  } */
  .background-linear {
    /* @include media-min ('tablet') {
      background: linear-gradient(to bottom, #272c2b, #000000);
      overflow: hidden;
    } */
    /* .mobil-title {
      display: flex;
      flex-direction: row;
      //margin-top: 30px;
      &:empty {
        display: none;
      }
      @include media-min ('tablet') {
        //display: flex;
        align-items: center;
        //padding-left: 15px;
      }
      @media (max-width: 767px) and (orientation: landscape) {
        //height: 50px;
        align-items: flex-start;
      }
      @include media-min ('tablet') {
        display: none;
      }
    } */
    /* .hide-mobil {
      display: flex;
      align-items: center;
      @media (max-width: 767px) {
        display: none;
      }
      p {
        margin-right: 0;
      }
    } */
    /* .backArrow img {
      @media (max-width: 767px) {
        width: 13px;
      }
    } */
    /* 
    h1.pad-left {
      //margin-left: 40%;
       @media (max-width: 767px) and (orientation: landscape) {
        margin: 0;
      } 
    }
    */
    .active-filters {
      //margin-left: 3.5%;
      @include media-min ('desktop-m') {
        //margin-left: 9%;
      }
      .filter-choice {
        // top:2px;
        // margin-top: 35px;
        //margin-left: 4%;
        //width: 85%;
        //margin-left: 7px;
        //display: flex;
        //flex-wrap: wrap;
        /* &:empty {
          display: none;
        }
        @include media-min ('tablet') {
          width: inherit;
          width: 100%;
        } */
        // @include media-min ('desktop-s') {
        //   margin-left: 0;
        // }
        // @include media-min ('desktop-m') {
        //   margin-left: 0;
        // }
        /* button {
          text-transform: uppercase;
          color: white;
          background-color: grey;
          padding: 10px 5px 10px 5px;
          border: none;
          border-radius: 5px;
          width: max-content;
          margin-bottom: 4%;
          font-size: 13px;
          margin-right: 15px;
          display: flex;
          justify-content: space-between;
          height: 40px;
          p:first-child {
            margin-right: 15px;
          }
        } */
      }
      /* .delete-btn-mobil {
        background-color: transparent;
        margin-right: 37px;
        border: none;
        position: absolute;
        top: 100px;
        left: 87vw;
        z-index: 2;
        @include media-min ('tablet') {
          display: none;
        }
        img {
          width: 22px;
        }
      } */
    }
  }

  .d-flex-column {
    /* margin-left: 258px;
    overflow: hidden;
    width: 100%; */
    /* @media (max-width: 767px) {
      margin-left: 0;
      @media (orientation: landscape) {
        overflow: auto;
      }
    } */
    /* @include media-min ('tablet') {
      margin-left: 160px;
      @media (orientation: landscape) {
        margin-left: 258px;
      }
    } */
    /* @include media-min ('desktop-s') {
      margin-left: 258px;
    } */
    /* h1 {
      //color: white;
      //font-family: "Open Sans";
      //margin-left: 4%;
      //font-weight: 700;
      @media (max-width: 767px) {
        //font-size: 20px;
        //font-weight: 600;
        //text-align: center;
      }
      @include media-min ('tablet') {
        // margin-top: 2rem;
        // margin-bottom: 2rem;
      }
      @include media-min ('desktop-s') {
        //margin-top: 4rem;
        // margin-left: 4%;
      }
      @include media-min ('desktop-m') {
        // margin-left: 9%;
      }
      @media (max-width: 850px) and (orientation: landscape) {
        font-size: 1.5rem;
      }
      @media (max-width: 767px) and (orientation: landscape) {
        margin: 0;
      }
    } */
    .filters {
      //margin-left: 4%;
      //margin-bottom: 2rem;
      /* @include media-min ('tablet') {
        height: 100%;
        width: 100%;
        overflow: hidden;
        overflow-x: scroll;
      } */
      /* @include media-min ('desktop-s') {
        overflow: hidden;
      }
      @include media-min ('desktop-m') {
        margin-left: 9%;
      } */
      /* .hide-mobil {
         @media (max-width: 767px) {
          display: none;
        } 
        @include media-min ('tablet') {
          position: relative;
          z-index: 3;
          top: 0px;
          align-items: center;
        }
      } */
      /* p {
        color: #fff;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 10px;
        white-space: nowrap;
        @include media-min ('tablet') {
          font-size: 1rem;
          margin-right: 15px;
          margin-top: 0;
          margin-bottom: 0;
        }
        @media (max-width: 850px) and (orientation: landscape) {
          font-size: 16px;
        }
      } */
      /* .apply {
        display: flex;
        position: fixed;
        z-index: 2;
        height: 128px;
        width: 100vw;
        bottom: 65px;
        justify-content: center;
        background-color: black;
        align-items: flex-start;
        left: 0;
        @include media-min ('tablet') {
          width: 80vw;
          bottom: 0;
          padding-left: 10%;
          margin-left: 160px;
          @media (orientation: landscape) {
            align-items: center;
            padding: 0;
            width: 85vw;
            margin-left: 258px;
            padding-left: 10%;
          }
        }
        @include media-min ('desktop-m') {
          width: 100%;
          padding: 0;
          padding-top: 25px;
          padding-left: 25%;
          margin-left: 258px;
        }
        @include media-min ('desktop-l') {
          bottom: 0px;
        }
        .apply-btn {
          max-width: 25rem;
          height: 64px;
          background: linear-gradient(to right, #3cad00, #c8d900);
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          border-radius: 3px;
          color: white;
          font-weight: bold;
          font-size: 17px;
          margin: auto;

          @include media-min ('tablet') {
            border-radius: 45px;
            
          }
          @media (max-width: 767px) and (orientation: landscape) {
            flex: 0.5;
          }

          @media (max-width: 850px) and (orientation: landscape) {
            height: 28px;
            font-size: 15px;
          }

          @include media-min ('desktop-s') {
            height: 60px;
            font-size: 20px;
          }
          @include media-min ('desktop-m') {
            height: 80px;
            font-size: 22px;
          }
        }
        .delete-btn {
          border: none;
          justify-content: center;
          display: flex;
          width: 265px;
          align-items: center;
          height: 50px;
          @media (max-width: 767px) {
            display: none;
          }
          @media (max-width: 850px) and (orientation: landscape) {
            height: initial;
          }
          @include media-min ('tablet') {
            margin: auto;
          }
          @include media-min ('desktop-s') {
            height: 60px;
            font-size: 20px;
          }
          @include media-min ('desktop-m') {
            height: 80px;
            font-size: 22px;
          }
        }
      } */
      /* button {
        background-color: transparent;
        color: #fff;
        text-transform: uppercase;
        margin-right: 15px;
        flex: 1 0 145px;
        display: flex;
        justify-content: center;
        &:last-child {
          margin-right: 0;
        }
        @include media-min ('tablet') {
          flex: 1 0 155px;
          padding: 10px;
          border-radius: 5px;
          justify-content: space-between;
          align-items: center;
        }
        @media (max-width: 850px) and (orientation: landscape) {
          padding: 5px;
        }
        @include media-min ('desktop-m') {
          flex: 1 0 235px;
        }
        @include media-min ('desktop-l') {
          flex: 1 0 265px;
        }
      } */
      /* .flex-filter {
        width: 100%;
        height: 100%;
        overflow: auto;
        display: flex;
        align-items: center;
        // padding-right: 50vw;
        // margin-top: 0.5rem;
        .close-btn {
          border: none;
          font-size: 15px;
          color: white;
          font-weight: 600;
          @include media-min ('desktop-m') {
            margin-left: 42%;
            display: hidden;
          }
          @include media-min ('desktop-l') {
            margin-left: 82%;
          }
        }
        .show-desktop-inactive {
          border: solid 2px white;
          img {
            display: block;
            width: 20px;
            @media (max-width: 767px) {
              display: none;
            }
          }
        }
        .show-desktop {
          border: solid 2px gray;
          span {
            color: gray;
          }
          img {
            display: block;
            width: 20px;
            @media (max-width: 767px) {
              display: none;
            }
          }
        }
        .show-desktop-active {
          border: solid 2px white;
          img {
            display: block;
            width: 12px;
            @media (max-width: 767px) {
              display: none;
            }
          }
        }
      }
      .filters-header {
        @include media-min ('tablet') {
          margin-bottom: 2rem;
        }
      } */
    } 
    /*.filter-choice-mobile {
      .filter-choice {
        position: relative;
        top: 2px;
        z-index: 2;
        width: 100%;
        margin-left: 4%;
        display: flex;
        flex-wrap: wrap;
        @include media-min ('tablet') {
          width: inherit;
          top: 5px;
        }
        @include media-min ('desktop-m') {
          margin-left: 9%;
        }
        .delete-btn-mobil {
          background-color: transparent;
          margin-right: 37px;
          @include media-min ('tablet') {
            display: none;
          }
          img {
            width: 22px;
          }
        }
        .flex.mobile {
          @media (max-width: 767px) {
            width: 93%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
        button {
          text-transform: uppercase;
          color: white;
          background-color: grey;
          padding: 10px 5px 10px 5px;
          border: none;
          border-radius: 3px;
          width: max-content;
          margin-bottom: 4%;
          font-size: 13px;
          margin-right: 15px;
          display: flex;
          justify-content: space-between;
          height: 40px;
          p:first-child {
            margin-right: 15px;
          }
        }
      }
    }
    .absolute {
      width: 100%;
      .category-pannel {
        padding-left: 7px;
        position: relative;
        background-color:#000000;
        padding-top: 50px;
        z-index: 1;
        padding-bottom: 500px;
        width: 100%;
        @include media-min ('tablet') {
          background: linear-gradient(to bottom, #232928, #000000);
          top: 23px;
          padding-bottom: 1000px;
          padding-right: 0;
          padding-left: 4%;
          padding-top: 4%;
        }
        @include media-min ('desktop-m') {
          padding-right: 0;
          padding-left: 8%;
          padding-top: 4%;
          justify-content: flex-start;
        }
        .flex {
          display: flex;
          flex-wrap: wrap;
        }
        button {
          text-transform: uppercase;
          color: white;
          background-color: grey;
          padding: 15px 5px 15px 5px;
          border: none;
          border-radius: 3px;
          width: 30%;
          margin-bottom: 4%;
          font-size: 13px;
          margin-right: 11px;
          display: flex;
          justify-content: center;
          align-items: center;
          @include media-min ('tablet') {
            height: 95px;
            font-size: 18px;
            font-weight: 700;
            margin-right: 16px;
          }
          @media (max-width: 850px) and (orientation: landscape) {
            height: 60px;
            font-size: 15px;
            width: 22%;
          }
          @include media-min ('desktop-m') {
            width: 155px;
            margin-left: 15px;
          }
          @include media-min ('desktop-l') {
            width: 187px;
          }
        }
        .mood-btn {
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          text-transform: uppercase;
          color: white;
          background-color: grey;
          padding: 15px 5px 15px 5px;
          border: none;
          border-radius: 3px;
          width: 30%;
          height: 83%;
          margin-bottom: 4%;
          font-size: 13px;
          @include media-min ('tablet') {
            height: 155px;
            width: 155px;
            font-size: 18px;
            font-weight: 700;
            margin-right: 16px;
          }
          @media (max-width: 850px) and (orientation: landscape) {
            height: 110px;
            width: 22%;
          }
          @include media-min ('desktop-m') {
            width: 180px;
            height: 180px;
            margin-left: 15px;
            font-size: 26px;
          }
          @include media-min ('desktop-l') {
            width: 200px;
            height: 200px;
            font-size: 28px;
          }
          img {
            width: 24px;
            margin-bottom: 10px;
            @include media-min ('tablet') {
              width: 75px;
            }
            @include media-min ('desktop-m') {
              width: 60px;
            }
            @include media-min ('desktop-l') {
              width: 70px;
            }
          }
        }
      }
    } */
  }
  
  .container__playlistGrid {
    //min-height: 70vh;
    //background-color: #272c2b;
    .content__list {
      // margin-top: 2.5rem !important;
      // padding: 1rem 0 5rem 1.5rem !important;
      // @include media-min ('tablet') {
      //   margin-top: 1.5rem !important;
      //   height: auto;
      // }
      // @include media-min ('desktop-m') {
      //   margin-left: 9%;
      // }
  
      .text-white {
        /* width: 30vw;
        @include media-min ('tablet') {
          width: 23.7vw;
        }
        @include media-min ('desktop-s') {
          width: 18vw;
        }
        @include media-min ('desktop-m') {
          width: 14vw;
        } */
        /* img {
          border-radius: 5px 5px 0 0;
          @media (max-width: 767px) {
            width: 30vw;
            padding-right: 15px;
          }
          @include media-min ('tablet') {
            width: 22vw;
            padding-right: 5px;
          }
          @include media-min ('desktop-s') {
            width: 17vw;
          }
          @include media-min ('desktop-m') {
            width: 14vw;
            padding-right: 15px;
          }
        } */
        /* .container__img {
          @media (max-width: 767px) {
            height: 25vw;
          }
          @include media-min ('tablet') {
            height: 21vw;
          }
          @include media-min ('desktop-s') {
            height: 17vw;
          }
          @include media-min ('desktop-m') {
            height: 13.5vw;
          }
        } */
        /* .description {
          display: none;
          @include media-min ('tablet') {
            display: inline-block;
            font-weight: 200;
          }
        } */
        /* p {
          font-size: 10px;
          text-overflow: ellipsis;
          width: 100px;
          word-break: break-word;
          overflow: hidden;
          @media (min-width: 500px) {
            width: 135px;
          }
          @media (min-width: 600px) {
            width: 21vw;
          }
          @include media-min ('tablet') {
            width: inherit;
            font-size: 12px;
            font-weight: 400;
            margin-top: 6px;
          }
          @include media-min ('desktop-s') {
            margin-top: 0;
          }
        } */
      }
    }
  }

  .container-description {
   /*  display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    @include media-min ('tablet') {
      height: auto;
      font-size: 16px;
      margin-bottom: 1rem;
      width: 168px;
    }
    @include media-min ('desktop-s') {
    width: 220px;
    }
    @include media-min ('desktop-m') {
      width: 192px;
      }
      @include media-min ('desktop-l') {
        width: 223px;
        } */
    .content-description {
      /* @include media-min ('tablet') {
        width: 22vw;
      }
      @include media-min ('desktop-s') {
        width: 17vw;
      }
      @include media-min ('desktop-m') {
        width: 168px;
      }
      @include media-min ('desktop-l') {
        width: 172px;
      } */

      /* .title {
        padding-top: 0.5rem;
        margin-bottom: 14px;
        @media (min-width: 500px) {
          padding-top: 1rem;
        }
        @media (min-width: 600px) {
          padding-top: 1.25rem;
        }
        @include media-min ('tablet') {
          padding-top: 0rem;
          margin-bottom: 0;
        }
        @include media-min ('desktop-m') {
          padding-top: 0;
        }
      } */
      /* .description {
        margin: 0;
      } */
    }
    /* .container-play {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.2rem;
      .btn-play {
        display: none;
        @include media-min ('tablet') {
          display: flex;
          justify-content: center;
          align-items: center;
          background: linear-gradient(#3cad00, #c8d900);
          border-radius: 50%;
          width: 2.3rem;
          height: 2.3rem;
          border: none;
          outline: none;
        }
        img.image-button{
          padding-right: 0 !important;
        }

        .image-button {
          width: 50%;
          margin-left: 32%;
          margin-right: auto;
        }
      }
    } */
  }
}
/* footer */
.container__miniplayers {
  z-index: 5;
}