@import "../../../styles/mixin";

.container__big-titles {
  margin-bottom: size(28);
  @include media-min("tablet") {
    margin-bottom: size(70);
  }
  .imgCover {
    border-radius: 5px;
    margin: 0;
    width: 100%;
    > a {
      border-radius: inherit;
      display: block;
      width: 100%;
      height: 100%;
    }
    img {
      border-radius: inherit;
      width: 100%;
      max-width: 100%;
    }
  }
  .no-title-on-carousel {
    visibility: hidden;
  }
  /* margin-top: 2rem;
    margin-bottom: 2rem;
    position: relative; */
  /* .carousel-button-group {
        display: none;
        @include media-min ('tablet') {
            display: inline;
            top: -3rem;
            right: 0;
            margin-bottom: 4rem;
        }
    } */
  /* .container-titles-only {
        .container__titles__carrousel {
            display: none;
            @include media-min ('tablet') {
                display:flex;
                align-items: baseline;
                justify-content: space-between;
                padding-left: 20px;
                padding-right: 20px;
            }
            .title__articles {
                font-size: 18px;
                @include media-min ('tablet') {
                    font-weight: 900;
                }
                @include media-min ('desktop-m') {
                    font-size: 22px;
                }
                @include media-min ('desktop-l') {
                    font-size: 22px;
                }
            }
        }
    } */
  /* .mt-mobile {
        margin-top: 1.5em;
        @include media-min ('tablet') {
            margin-top: 0;
        }
    } */

  /* @include media-min ('desktop-m') {
        .carousel{
            margin-right: 1rem;
            padding: 0 !important;
        }
    } */
  /* @media (max-width: 480px) {
        .title-carousel{
            display: none; 
        }
    } */
  /* .react-multi-carousel-list {
        padding: 0 25px !important;
    } */
}
