@import '../../../styles/mixin';

.container__artistgrid {
    padding: 0 .8rem;
    @include media-min ('tablet') {
        padding: 0 2rem;
    }
    @include media-min ('desktop-s') {
        padding: 1.8rem 2rem 0;
    }
    @include media-min ('desktop-m') {
        padding: 2rem 2rem 0;
    }
    .title__page {
        font-family: 'Sans open', sans-serif;
        font-size: 12px;
        font-weight: 900;
        @media (min-width: 400px) {
            display: none;
        }
        @include media-min ('desktop-s') {
            display: inline;
            font-size: 22px;
            font-weight: 900;
            padding-left: 2rem;
        }
        @include media-min ('desktop-m') {
            font-size: 24px;
        }
    }
    .container__playlists {
        font-family: 'Sans open', sans-serif;
        @include media-min ('tablet') {
            margin-top: 0rem;
        }
        @include media-min ('desktop-s') {
            padding-left: 2rem;
            padding-top: 1.8rem;
        }
        @include media-min ('desktop-m') {
            padding-top: 2rem;
        }
        .container__grid {
        box-sizing: border-box;
        width: 30%;
        z-index: 5;
        position: absolute;
        img {
            width: 100%;
            border-radius: 5px;
        }
        @include media-min ('tablet') {
            width: 175px;
        }
        @include media-min ('desktop-s') {
            width: 192px;
            z-index: 0;
        }
        @include media-min ('desktop-m') {
            width: 17%;
        }
        .container__desc {
            display: flex;
            justify-content: space-between;
            font-size: 10px;
            margin-top: .5rem;
            @include media-min ('tablet') {
                padding-top: 1rem;
            }
            .title {
                padding: 0;
                margin: 0;
                padding-bottom: .2rem;
                @include media-min ('tablet') {
                    font-size: 16px;
                }
                @include media-min ('desktop-m') {
                    font-size: 18px;
                }
            }
            .desc {
                display: none;
                font-size: 8px;
                padding: 0;
                margin: 0;
                font-weight: lighter;
                @include media-min ('tablet') {
                    display: block;
                    font-size: 12px;
                }
                @include media-min ('desktop-m') {
                    font-size: 16px;
                }
            }
            .container-play {
                display: none;
                justify-content: center;
                margin-left: 1rem;
                @include media-min ('tablet') {
                    display: flex;
                }
            }
            .btn-play {
                display: flex;
                justify-content: center;
                align-items: center;
                background: linear-gradient(#3CAD00, #C8D900);
                border-radius: 50%;
                width: 2.3rem;
                height: 2.3rem;
                border: none;
                outline:none;
                @include media-min ('tablet') {
                    width: 2.6rem;
                    height: 2.6rem;
                }
                .image-button {
                    width: 50%;
                    margin-left: 32%;
                    margin-right: auto;
                }
            }
        }
    }
}
}








