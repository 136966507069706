@import '../../../styles/mixin';

  .container-vouchervalidation {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    font-family: 'Open Sans';
    color: #fff;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: .8rem;
    padding: 1rem;
    font-weight: lighter;
    height: auto;
    @include media-min ('tablet'){
      font-size: 18px;
      padding: 5rem;
    }
    .link_return {
      position: absolute;
      top: 0;
      left: 0;
      margin: 1.2rem;
      img {
          width: 12px;
      }
      }
    .imagelogo {
      margin-left: auto;
      margin-right: auto;
      width: 90px;
      height: auto;
      @include media-min ('tablet'){
        width: 160px;
      height: 95px;
      }
    }
    h1 {
      color: #3CAD00;
      font-size: 1.3rem;
      margin: 3rem 0 2rem;
      font-weight: bold;
      @include media-min ('tablet'){
        font-size: 22px;
        margin: 5rem 0 3rem;
      }
      @include media-min ('desktop-s') {
        margin: 6rem 0 3rem;
      }
    }
    p {
      color: white;
      width: 65%;
      margin-bottom: 2rem;
      @include media-min ('tablet'){
        width: 55%;
        margin-bottom: 3rem;
      }
      @include media-min ('desktop-s') {
        width: 40%;
      }
      @include media-min ('desktop-m') {
        width: 30%;
      }
      @include media-min ('desktop-l') {
        width: 25%;
      }
    }
    .popin {
      position: absolute;
      top: 0;
      background-color: rgba(0, 0, 0, 0.5);
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      .popin-content {
        width: 40%;
        height: 40vh;
        background-color: rgb(243, 243, 243);
        box-shadow: 0 15px 20px rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 200px;
          margin-bottom: 0;
        }
        .close-btn {
          position: relative;
          left: 8vw;
          top: -16vh;
          border: none;
          font-size: 30px;
          background-color: none;
          background: none;
        }
      }
    }
  .codeinput {
    display: flex;
    flex-direction: row;
    align-items: center;
    .free {
      border: none;
      width: 6vw;
      color: #67c100;
      flex: 5%;
      text-align: right;
      font-weight: bold;
      background: none;
      text-align: right;
    }
    .styles_react-code-input-container__tpiKG {
      width: inherit !important;
    }
    .styles_react-code-input__CRulA > input {
      margin-right: 2vw;
      border-radius: 6px;
      @include media-min ('desktop-s') {
        width: 60px !important;
        height: 56px !important;
      }
       @include media-min ('desktop-m') {
        width: 65px !important;
        height: 61px !important;
      }
      @include media-min ('desktop-l') {
        width: 80px !important;
        height: 76px !important;
      }
    }
  }
  span {
    color: #67c100;
    margin-right: 10px;
    margin-left: 10px;
  }
  p.error {
    margin-top: 2vw;
    color: red;
  }
  }