@import '../../../styles/mixin';

.navbar-container {
  background-color: #000;
  
  max-width: 100%;
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // right: 0;
  // font-size: 10px;
  // font-weight: 400;
  z-index: 2;
  @media screen and (max-width: 767px) {
    background: linear-gradient(180deg, #121212, #0C0C0C);
    height: 57px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
  @include media-min ('tablet'){
    width: 160px;
    min-height: 100%;
    font-size: 14px;
    background-color: #000000;
  }
  @include media-min ('desktop-s') {
    font-size: 16px;
    width: 258px;
  }
  @include media-min ('desktop-m') {
    font-size: 18px;
  }
  @include media-min ('desktop-l') {
    font-size: 18px;
  }
  .navbar-nav {
    //display: flex;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    //padding-top: 0.3rem;
    
    @media screen and (max-width: 767px) {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0 14px;
    }
    @media screen and (max-width: 479px) {
      justify-content: space-between;
    }
    @include media-min ('tablet'){
      flex-direction: column;
      padding: size(32) size(16) size(20);
      //margin-left: 1rem;
      //padding: size(64) size(32);
      //width: 90%;
    }
    @include media-min ('desktop-s') {
      overflow: hidden;
      padding: size(48) 0 size(32) size(32);
      //margin-left: 3rem;
    }
    @include media-min ('desktop-m') {
      padding: size(64) size(32) size(32);
      //margin-left: 1rem;
    }
    @include media-min ('desktop-m') {
      //margin-left: 1rem;
    }
  }
  .navbar-logo {
    display: none;
    @include media-min ('tablet'){
      display: block;
      align-self: center;
      //margin-left: 25%;
      //margin-top: 2rem;
      margin-bottom: size(74);
      width: 48px;
      @media screen and (max-height: 599px) {
        margin-bottom: size(48);
      }
    }
    @include media-min ('desktop-s') {
      margin-bottom: size(112);
      width: 64px;
      @media screen and (max-height: 599px) {
        margin-bottom: size(48);
      }
    }
    @include media-min ('desktop-m') {
      //margin: 3rem auto 4rem;
    }
    img {
      width: 48px;
    }
  }
  .nav-link {
    color: #fff;
    //padding: .5rem;
    //margin: auto;
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      font-size: size(8);
      align-items: center;
      margin: 0 2rem;
      min-width: 48px;
      padding: .6rem 0;
      text-align: center;
      width: 48px;
    }
    @media screen and (max-width: 479px) {
      margin: 0
    }
    @include media-min ('tablet'){
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: size(14);
      font-weight: 600;
      padding: 1rem 0;
      margin-bottom: 1rem;
      // @media (orientation: landscape) {
      //   margin: 0;
      // }
      &:last-child {
        margin-bottom: 0;
      }
    }
    @include media-min ('desktop-s') {
      font-size: size(16);
      //padding-left: 1.2rem;
      // @media (orientation: landscape) {
      //   margin: 1rem 0;
      // }
    }
    @include media-min ('desktop-m') {
      //margin: 1rem 1rem;
    }
    @include media-min ('desktop-l') {
      //padding-left: 2rem;
    }
    // img {
    //   padding-bottom: .3rem;
    //   @include media-min ('tablet'){
    //   }
    // }
    // .logo {
    //   @include media-min ('tablet'){
    //     margin-right: size(22);
    //   }
    // }
    .icon {
      height: 20px;
      width: 20px;
      @include media-min ('tablet'){
        margin-right: size(22);
      }
    }
    > span {
      @media screen and (max-width: 767px) {
        padding-top: 5px;
      }
    }
  }
  .active-link {
    @include media-min ('tablet'){
      color: #3CAD00;
    }
  }
  .nav-qrbtn {
    background-color: #151717;
    border: none;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    width: 100%;
    padding: 20px;
    .nav-qrcode {
      height: auto;
      margin: auto;
      max-width: 100px;
      width: 100%;
      background-color: #fff;
      padding: 7px;
      border-radius: 10px;
    }
  }
}

