@import "../../../styles/mixin";

.genre-filter-home {
  width: calc(100% - 10px);
  display: flex;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none;

  &:hover {
    color: #fff;
    text-decoration: none;
  }

  @include media-min("tablet") {
    height: 50px;
    font-size: 14px;
  }
  @include media-min("desktop-m") {
    font-size: 18px;
    height: 60px;
  }
}

.container__genre .react-multi-carousel-list {
  margin: 0 !important;
}
