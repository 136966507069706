@import '../../../styles/mixin';

.container_changeLanguage_page {
    @media screen and (max-width: 767px) {
        background: url("../../../backgroundmobile.png") center  no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
    }
    @include media-min ('tablet') {
        overflow: hidden;
        //background: linear-gradient(#090a0a,#212626);
        // @media (orientation: landscape) {
        //     overflow: auto;
        // }
    }

    .container_changeLanguage {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        position: absolute;
        left: 0; top: 0;
        width: 100%;
        .link_change_language {
            color: #fff;
            text-align: center;
            display: inline-flex;
            font-size: 8px;
            font-weight: 600;
            height: 100%;
            margin: 0;
            text-align: center;
            @media screen and (max-width: 767px) {
                color: #cacaca;
                padding: 15px 12px 12px;
            }
            @include media-min ('tablet') {
                font-weight: normal;
                font-size: 15px;
                height: 100%;
                padding: 20px;
            }
        }
    }
    .imagelogo.country {
        @media screen and (max-width: 767px) {
            display: none;
            // @media (orientation: landscape) {
            //     margin-top: 1rem;
            // }
        }
        @include media-min ('tablet') {
            // width: 160px;
            // height: 95px;
            // margin: 2rem auto;
        }
    }
    .container__forgotLogin {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        @media screen and (max-width: 767px) {
            background: url("../../../backgroundmobile.png") center  no-repeat;
            background-size: cover;
            height: 100vh;
            font-size: size(32);
        }
        @include media-min ('tablet') {
            //height: 80%;
            color: #fff;
            //padding-bottom: 7rem;
            margin: auto;
            text-align: center;
        }
        @media (min-width: 1224px) {
            justify-content: center;
            align-items: center;
            padding-bottom: 0;
            padding-top: 0;
        }
        .main_content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            @include media-min('tablet'){
                overflow: hidden;
            }
            .text_content {
                margin-top: 50px;
                p {
                    color: #fff;
                    text-align: center;
                    display: inline-flex;
                    font-size: 8px;
                    font-weight: 600;
                    margin: 0;
                    @media screen and (max-width: 767px) {
                        color: #cacaca;
                        padding: 15px 12px 12px;
                    }
                    @include media-min ('tablet') {
                        font-weight: normal;
                        font-size: 15px;
                    }
                }
                a {
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    font-size: 8px;
                    font-weight: 600;
                    margin: 0;
                    @include media-min ('tablet') {
                        font-weight: normal;
                        font-size: 15px;
                    }
                }
            }
        }
        .imagelogomobile {
            height: auto;
            width: 52px;
            margin: 0 auto;
            @media screen and (max-width: 767px) {
                @media (orientation: landscape) {
                    margin-top: .5rem;
                    margin-bottom: 0;
                }
            }
            @include media-min ('tablet') {
                display: none; 
            }
        }
    }
}

