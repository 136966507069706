@import '../../../styles/mixin';


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.containerAccess {
    display: flex;
    flex-direction: column;
    background: linear-gradient(#090a0a,#212626);
    height: 100vh;
    overflow: auto;
    @media screen and (max-width: 767px) {
      background: url("../../../backgroundmobile.png") center  no-repeat;
      background-size: cover;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    &__imagelogomobile {
        height: auto;
        margin: 0 auto 2rem;
        width: 52px;
        @media screen and (max-width: 767px) {
            @media (orientation: landscape) {
                margin-top: .5rem;
                margin-bottom: 0;
            }
        }
        @include media-min ('tablet') {
            display: none; 
        }
    }
    &__imagelogo {
        margin: 0 auto 5rem;
        @media screen and (max-width: 767px) {
            display: none;
        }
    }
    &__info {
        color: white;
        width: 600px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: size(12);
        font-weight: 600;
        margin: 0 auto;
        text-align: center;
        max-width: 80%;
        @include media-min ('tablet') {
          font-size: size(18);
          max-width: 75%;
        }
        p {
            width: 90%;
            @include media-min ('tablet') {
                width: 80%;
            }
        }
        &--user {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-bottom: size(20);
            p {
                width: 100%;
                margin: 0;
            }
        }
        &--title {
            background: -webkit-linear-gradient(360deg,#3CAD00, #C8D900);
            background: linear-gradient(to bottom, #3CAD00, #C8D900 );
            background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: size(20);
            font-weight: 800;
            text-align: center;
            padding-bottom: size(20);
            max-width: 90%;
            @include media-min ('tablet') {
                font-size: size(30);
                line-height: 1.6rem;
            }
            @include media-min ('desktop-s') {
            margin-bottom: size(28);
            }
        }
        &--exp {
            font-weight: 800;
            font-size: 16px;
            margin-bottom: 10px;
            @media screen and (max-width: 767px) {
                font-size: 14px;
             }
        }
        &--login {
            font-weight: 800;
            font-size: 16px;
            margin-bottom: 30px;
            margin-top: 30px;
            @media screen and (max-width: 767px) {
                font-size: 14px;
             }
        }
    }
    &__storeBtn {
        height: 70px;
        width: 100%;
        display: flex;
        justify-content: space-around;
        @include media-min ('tablet') {
            margin-bottom: 20px;
            width: 70%;
        }
        a {
            width: 45%;
            @include media-min ('tablet') {
                padding: 10px 0;
            }
            img {
                width: 100%;
                overflow: hidden;
            }
        }
    }
    &__linkPwa { 
        background: linear-gradient(270deg, #5FB101, #B1D00E);
        box-shadow: inset 1px 2px 3px rgba(0,0,0,0.5);
        border-radius: 50px;
        border: none;
        color: #fff;
        height: 43px;
        font-size: size(11);
        font-weight: 900;
        text-transform: capitalize;
        width: 210px;
        margin-top: 0px;
        margin-bottom: 2rem;
        @include media-min ('tablet') {
          border-radius: 48px;
          height: 64px;
          font-size: size(18);
          width: 320px;
        }
    }
}