@import '../../../styles/mixin';

.container__voucher-pop-in {
    z-index: 100;
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .container__pop-in { 
        width: 250px;
        height: 400px;
        background-color: #000;
        border: 2px solid #3CAD00;
        @include media-min ('tablet'){
            width: 400px;
            height: 400px;
        }
        @include media-min ('desktop-s'){
            width: 500px;
            height: 500px;
        }
        .close-popin {
            width: 100%;
            background-color: transparent;
            border: none;
            
            @include media-min ('tablet'){
            
            }
            span{
              float: right;
              margin-right: 1%;
              font-size: 20px;
              color: white;
              
            @include media-min ('tablet'){
              font-size: 24px;
            }
            @include media-min ('desktop-s'){
              font-size: 26px;
            }
          }
        }
        .title {
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            margin-top: 10%;
            width: 90%;
            margin-left: 5%;
            @include media-min ('desktop-s'){
                font-size: 20px;
            }
        }
        .text {
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            text-align: center;
            margin-top: 10%;
            width: 90%;
            margin-left: 5%;
            @include media-min ('desktop-s'){
                font-size: 20px;
            }
        }
        .container__link {
            margin-top: 10%;
            display: flex;
            justify-content: center;
            height: 50px;
            .continue-popin {
                border: none;
                width: 100%;
                background-color: transparent;
                .link__informations {
                    background: linear-gradient(270deg, #5FB101, #B1D00E);
                    box-shadow: inset 1px 2px 3px rgba(0,0,0,0.5);
                    border-radius: 2px;
                    border: none;
                    color: #fff;
                    font-weight: 800;
                    text-transform: uppercase;
                    align-items: center;
                    padding: 2% 10%;
                    @include media-min ('tablet') {
                    border-radius: 48px;
                    font-size: 16px;
                    }
                    @include media-min ('desktop-l'){
                        font-size: 20px;
                    }
                }
            }

        }
    }
}