@import '../../../styles/mixin';

.container_onboarding_welcome {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Open Sans";
  color: #fff;
  padding: 3vh;
  background: linear-gradient(to top, #212626, #090a0a);
  .bg-onboarding-welcome {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .onboarding-welcome-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 90vh;

      h1 {
        font-size: 2.5rem;
        padding-bottom: 10vh;
        font-weight: bold;
        @include media-min ('tablet') {
          font-size: 3rem;
        }
        @include media-min ('desktop-s') {
          padding-bottom: 12vh;
          padding-top: 15vh;
        }
      }
      p {
        text-align: center;
        font-size: 1.2rem;
        padding-bottom: 10vh;
        width: 70%;
        font-weight: bold;
        @include media-min ('tablet') {
          font-size: 2rem;
          font-weight: normal;
        }
        @include media-min ('desktop-s') {
          width: 50%;
          padding-bottom: 12vh;
        }
        @include media-min ('desktop-m') {
          width: 40%;
        }
      }
      .btn-welcome {
        height: 8vh;
        width: 60%;
        color: #fff;
        border-radius: 3px;
        border: none;
        background: linear-gradient(to right, #3cad00, #c8d900);
        text-transform: uppercase;
        font-size: 1rem;
        font-weight: bold;
        margin-bottom: 2rem;
        @include media-min ('tablet') {
          border-radius: 50px;
          font-size: 1.5rem;
          width: 50%;
          height: 10vw;
        }
        @include media-min ('desktop-s') {
          height: 85px;
          width: 40%;
        }
        @include media-min ('desktop-m') {
          width: 30%;
          height: 75px;
        }
      }
    }
  }
}
