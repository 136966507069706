@import '../../../styles/mixin';

.identification__page {
    background: linear-gradient(#090a0a,#212626);
    height: 100vh;
    overflow: auto;
    .container__identification {
        //font-size: .8rem ;
        //height: 70vh;
        // @include media-min ('desktop-s') {
        //     justify-content: flex-start;
        //     margin-top: 5em;
        // }
        // h1 {
        //     margin: 3rem 0 2rem;
        //     font-weight: bold;
        //     @include media-min ('tablet') {
        //         margin: 5rem 0 3rem;
        //     }
        //     @include media-min ('desktop-s') {
        //         margin: 6rem 0 3rem;
        //     }
        // }
        // p {
        //     width: 60%;
        //     margin-bottom: 2rem;
        //     @include media-min ('mobil-sm') {
        //         width: 35%;
        //     }
        //     @include media-min ('tablet') {
        //         width: 35%;
        //         margin-bottom: 3rem;
        //     }
        //     @include media-min ('desktop-s') {
        //         width: 35%;
        //     }
        // }
        .container__input {
            display: flex;
            align-items: center;
            @include media-min ('tablet') {
                width: 420px !important;
            }
            span {
                color: #71b804;
                display: inline-flex;
                font-size: size(12);
                font-weight: 800;
                margin-right: 6px;
                padding-top: 0px;
                white-space: nowrap;
                width: 35px;
                @include media-min ('tablet') {
                    font-size: size(20);
                    width: 60px;
                    margin-right: 16px;
                }
                @include media-min ('desktop-s') {
                    font-size: 18px;
                    margin-right: 1rem;
                }
            }
            input {
                background-color: #fff;
                box-shadow: inset 0px 1px 3px rgba(0,0,0,0.5);
                border: none;
                border-radius: 2px;
                color: #989898;
                font-family: "open sans" !important;
                font-size: 1rem;
                font-weight: 800;
                margin-right: 14px;
                height: 40px !important;
                width: 54px !important;
                &:last-child {
                    margin-right: 0;
                }
                @include media-min ('tablet') {
                    border-radius: 11px;
                    margin-right: 32px;
                    height: 64px !important;
                    width: 64px !important;
                }
                
            }
            .styles_react-code-input-container__tpiKG {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                width: 100% !important;
                @include media-min ('tablet') {
                }
            }
        }
        .wrap_link {
            line-height: normal;
            margin: 0;
            text-align: right;
        }
        .forgotLink {
            color: #cacaca;
            display: inline-block;
            font-size: 8px;
            line-height: inherit;
            margin-top: 10px;
            @include media-min ('tablet') {
                font-size: 10px;
            }
            &:hover{
                text-decoration: none;
            }
        }
        .container__button {
            .button__validation {
            }
        }
        
    }
}
