@import '../../../styles/mixin';

.container-playlist {
  margin-bottom: 0;
  max-width: 100%;
  overflow: hidden;
  width: 100%;
  .list-playlist {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -7px;
    @media (min-width: 600px) {
      margin: 0 -1rem;
    }
  }
  .grid {
    padding: 0 7px;
    margin-bottom: 1rem;
    width: 33.33333%;
    @media (min-width: 600px) {
      padding: 0 1rem;
    }
    @include media-min('tablet') {
      margin-bottom: 2rem;
      width: auto;
    }
    .link-item {
      //width: 88px;
      @media (min-width: 600px) {}
      @include media-min('tablet') {
        width: 160px;
      }
      @include media-min('desktop-s') {
        width: 192px;
      }
      @include media-min('desktop-l') {
        width: 224px;
      }
    }
  }
  .picture_content {
    border-radius: 2px;
    margin: 0 0 0.5rem;
    @include media-min('tablet') {
      border-radius: 5px;
      margin-bottom: 1rem;
    }
    img {
      border-radius: inherit;
      display: block;
      max-width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center top;
      width: 100%;
    }
  }
  .playlistTitle {
    color: #fff;
    font-size: 0.5rem;
    font-weight: 800;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: none;
    white-space: nowrap;
    width: 100%;
    @include media-min('tablet') {
      font-size: 0.875rem;
    }
    @include media-min('desktop-s') {
      font-size: 1rem;
    }
  }
  .container-play,
  .btn-play,
  .playlistDescription {
    display: none;
  }
  .container-description {
    position: relative;
  }
}
