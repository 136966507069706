@import '../../../styles/mixin';

.playlist {
  .flex-column {
    width: 100%;
    overflow: hidden;
    .sticky-top-sticky {
      background: linear-gradient(to bottom, #272c2b, #000000);
      height: 17vh;
      position: sticky;
      top: 0;
      justify-content: center;
      width: 100%;

      .sticky-description {
        display: flex;
        flex-direction: row;
        align-items: inherit;
        justify-content: space-between;
        width: 100%;

        h1 {
          font-family: "Open Sans";
          font-weight: 900;
          font-size: 18px;
          padding-left: 5px;
          margin-bottom: 0;
        }
        .d-column-flex {
          p:first-child {
            font-family: "Open Sans";
            font-weight: 300;
            font-size: 12px;
            margin-bottom: 0;
          }
          p:nth-child(2) {
            font-family: "Open Sans";
            font-weight: 600;
            font-size: 18px;
          }
        }
      }
      #back {
        border: none;
        background-color: inherit;
        height: 0;

        img {
          width: 10px;
          vertical-align: baseline;
        }
      }
      #play {
        justify-content: center;
        align-items: center;
        width: 13vh;
        height: 13vh;
        border-radius: 100%;
        img {
          width: 39px;
          margin-right: 0;
        }
      }
    }
    .track-description-md{
      display: flex;
      flex-direction: row;
      width: 35vw;
      @include media-min ('tablet') {
        flex-direction: row;
        align-items: center;
      }
    }

  }
  .mt-3 {
    margin-top: 0;
  }

  .bg-black {
    //background-color: #000000;
    // img {
    //   width: 50px;
    //   margin-left: 15px;
    //   height: 100%;
    //   flex: 10% 1;
    // }
  }

  .bg-secondary {
    background-color: #6c757d;
    /* img {
      width: 50px;
      margin-left: 15px;
      height: 100%;
      flex: 10% 1;
    } */
  }
  .empty-fav{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    padding-top: 3rem;
    .title-section {
      font-family: "Open Sans";
      font-weight: 300;
      font-size: 12px;
      margin-bottom: 0;
    }
    .backArrow {
      background: none;
      border: none;
      //height: 13px;
      padding: 0;
      position: absolute;
      left: 1rem; top: 1.25rem;
      //left: -2rem; top: -4px;
      z-index: 1;
      width: 30px;
      @include media-min ('tablet') {
        left: 2rem; 
        top: 1.5rem;
        //width: 16px;
      }
      @include media-min ('desktop-m') {
        left: 4rem;
      }
      @include media-min ('desktop-m') {
        left: 2rem;
      }
      @include media-min ('desktop-l') {
        left: 2.5rem; 
      }
      img {
        height: 13px;
        @include media-min ('tablet') {
          height: 16px;
        }
      }
    }
    }

  .list-group {
    //background-color: #000000;
    //margin-bottom: 4rem;
    /* overflow: hidden;
    p {
      margin-bottom: 0;
      font-size: 13px;
      font-family: "Open Sans";
      font-weight: 300;
    }
    p:first-child {
      font-weight: bold;
      font-size: 15px;
      margin-bottom: 0;
      font-family: "Open Sans";
      font-weight: 800;
    } */
  }
  // .buttonHeart {
  //   background-color: inherit;
  //   border: none;
  //   flex: 5%;

  //   img {
  //     width: 20px;
  //     height: 20px;
  //     margin-right: 15px;
  //   }
  // }
  // .d-inline-flex.mb-2 {
  //   align-items: flex-start;
  // }
}

