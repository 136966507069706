@import '../../../styles/mixin';

.maintenance-content {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding-top: 15vh;


  img {
    width: 120px;
    margin-bottom: 18vh;
    @include media-min ('desktop-s') {
      width: 130px;
    }
  }
  p {
    color: white;
    font-size: 21px;
    font-weight: 500;
    width: 245px;
    text-align: center;
    @include media-min ('desktop-s') {
      font-size: 22px;
      width: 250px;
    }
    @include media-min ('desktop-m') {
      font-size: 26px;
      width: 300px;
    }
    @include media-min ('desktop-l') {
      font-size: 30px;
      width: 330px;
    }
  }
}
.bg-container {
  width: 100%;
  height: auto;
}
.bg-maintenance {
  background-image: url('../../../../public/assets/images/bg-maintenance.png');
  background-size: cover;
  height: 100vh;
  background-position-x: center;
  background-position-y: center;
}
