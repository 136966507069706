@import '../../../styles/mixin';

.modalQRCode-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.70);
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 767px) {
    display: none;
  }
  .modalQRCode{
    position: relative;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    background-color: black;
    text-align: center;
    padding: 50px;
    .logo {
      width: 100px;
      margin-bottom: 20px;
    }
    h1 {
        font-weight: bold;
        font-size: 18px;
        width: 300px;
        @include media-min ('tablet') {
            font-size: 18px;
            }
    }
    p {
        margin-top: 10px;
        font-size: 14px;
        width: 300px;
        font-weight: lighter;
        @include media-min ('tablet') {
            font-size: 16px;
            }
    }
    .modal-qrCode {
      height: auto;
      margin: auto;
      max-width: 250px;
      width: 100%;
      background-color: #fff;
      padding: 14px;
      border-radius: 10px;
      margin: 20px 0;
    }
    .closebtn {
      background-color: transparent;
      border: none;
      position: absolute;
      top: 0;
      right: -50px;
      img {
        width: 25px;
      }
    }
}
}