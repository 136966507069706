.d-flex.flex-wrap.mt-2.p-3.onlyForYou {
    height: 85VH;
    margin-top: 2.5rem !important;
    padding: 1rem 0 1rem 1rem !important;
    align-content: flex-start;
    @media (min-width: 768px) {
      margin-top: 5.5rem !important;
      height: auto;
      margin-left: 2vw;
    }

    .text-white {
      width: 30vw;
      @media (min-width: 768px) {
        width: 23.7vw;
      margin-bottom: 10px;
      }
      @media (min-width: 1440px) {
        width: 14vw;
        margin-left: 26px;
      }
      img {
        border-radius: 5px 5px 0 0;
        @media (max-width: 767px) {
          width: 30vw;
          padding-right: 15px;
        }
        @media (min-width: 768px) {
          width: 22vw;
          padding-right: 5px;
        }
        @media (min-width: 1440px) {
          width: 14vw;
        }
      }
      .description {
        display: none;
        @media (min-width: 768px) {
          display: inline-block;
          font-weight: 200;
        }
      }
      p {
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 90px;
        word-break: break-word;
        width: 90px;
        overflow: hidden;

        @media (min-width: 768px) {
          width: inherit;
          font-size: 14px;
          font-weight: 800;
          margin-top: 0;
        }
      }
    }
  }
  .container-description {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    @media (min-width: 768px) {
      height: auto;
      margin-top: 1rem;
      font-size: 16px;
      margin-bottom: 1rem;
      width: 168px;
    }
    @media (min-width: 1024px) {
    width: 220px;
    }
    @media (min-width: 1440px) {
      width: 192px;
      }
      @media (min-width: 1680px) {
        width: 223px;
        }
    .content-description {
      @media (min-width: 768px) {
        width: 118px;
      }
      @media (min-width: 1024px) {
        width: 168px;
      }
      @media (min-width: 1440px) {
        width: 140px;
      }
      @media (min-width: 1680px) {
        width: 172px;
      }

      .title {
        padding-top: 0.5rem;
        margin-bottom: 14px;
        @media (min-width: 768px) {
          font-weight: 800;
          margin-bottom: 0;
        }
      }
      .description {
        margin: 0;
      }
    }
    .container-play {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.2rem;
      .btn-play {
        display: none;
        @media (min-width: 768px) {
          display: flex;
          justify-content: center;
          align-items: center;
          background: linear-gradient(#3cad00, #c8d900);
          border-radius: 50%;
          width: 2.3rem;
          height: 2.3rem;
          border: none;
          outline: none;
        }
        img.image-button{
          padding-right: 0 !important;
        }

        .image-button {
          width: 50%;
          margin-left: 32%;
          margin-right: auto;
        }
      }
    }
  }