// Override default variables before the import
$body-bg: #000000;
// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import '_mixin.scss';

body {
    font-family: 'Open Sans', sans-serif;
}
footer {
    &:empty {
        display: none;
    }
}
button:focus {
  outline: none !important;
}

.main_page {
    background: linear-gradient(#090a0a,#212626);
    height: 100vh;
    overflow:  hidden auto;
    .container_page {
        color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        @media screen and (max-width: 767px) {
            background: url("../backgroundmobile.png") center  no-repeat;
            background-size: cover;
        }
    }
    .imagelogo {
        margin-left: auto;
        margin-right: auto;
        width: 91px;
        //top: 3vh;
        @include media-min ('tablet') {
            height: 95px;
            width: 160px;
        }
    }
    .link_return {
        color: #fff;
        position: absolute;
        top: 0;
        left: 0;
        margin: .60rem 1.2rem;
        @include media-min ('tablet') {
            margin: 1rem 2rem;
        }
        img {
            width: 12px;
            @include media-min ('tablet') {
                width: 20px;
            }
        }
    }
    .titre_page {
        color: #fff;
        font-size: size(16);    
        font-weight: 800;
        line-height: 1.25rem;
        margin-bottom: size(16);
        margin-top: size(48);
        text-align: center;
        @include media-min ('tablet') {
            font-size: size(30);
            line-height: 1.6rem;
            margin-bottom: size(32);    
            margin-top: size(102);
            @media screen and (max-height: 767px) {
                margin-top: size(51);
            }
            @media screen and (max-height: 600px) {
                margin-top: size(20);
            }
        }
    }
    .chapo_text {
        color: #fff;
        font-size: size(12);
        font-weight: 600;
        line-height: 1rem;
        margin-bottom: 2rem;
        text-align: center;
        width: 70%;
        
        @include media-min ('mobil-sm') {
            width: 36%;
        }
        @include media-min ('tablet') {
            font-size: size(18);
            line-height: 1.6rem;
            margin-bottom: 4rem;
            @media screen and (max-height: 600px) {
                margin-bottom: 2rem;
            }
        }
    }
    .container__button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: size(20);
        //margin-bottom: 2rem;
        @include media-min ('tablet') {
        margin-top: size(34);
        @media screen and (max-height: 600px) {
            margin-top: size(20);
        }
        }
        .button__validation {
            color: #fff;
            height: 40px;
            border: none;
            font-size: size(11);
            font-weight: 800;
            text-transform: uppercase;
            width: 156px;
            @media screen and (max-width: 767px) {
                background: linear-gradient(270deg, #5FB101, #B1D00E);
                box-shadow: inset 1px 2px 3px rgba(0,0,0,0.5);
            }
            @include media-min ('tablet') {
                background: linear-gradient(270deg, #B1D00E, #5FB101);
                border-radius: 48px;
                font-size: size(18);
                height: 64px;
                width: 320px;
            }
            span {
                opacity: .6;
            }
            &:focus, &:hover {
                span {
                    opacity: 1;
                }
            }
        }
    }
}

.container-page  {
    background: linear-gradient(0deg, #212626, #090A0A);
    //background: linear-gradient( #090A0A, #212626) ;
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding-bottom: 0;
    @include media-min ('tablet') {
        flex-direction: row-reverse;
        max-height: 100vh;
        overflow: hidden;
        padding-bottom: 0;
    }
}
.container-page-with-player{
 @media (max-width: 767px) {
     padding-bottom: 50px;
 }
}
.title-page {
    color: #fff;
    font-size: 1rem;
    font-weight: 800;
    @media (max-width: 767px) {
        margin-bottom: 1.25rem;
        text-align: center;
        //display: none; // only for home page
    }
    @include media-min ('tablet') {
        //display: inline-block;
        font-size: size(30);
        line-height: 1.25em;
        margin-bottom: 2rem;
        padding: 0;
        // padding-bottom: 1.5rem;
        // padding-left: 20px;
        // padding-right: 20px;
    }
    @include media-min ('desktop-s') {
        line-height: 1.6em;
    }
    @include media-min ('desktop-m') {
        font-size: size(40);
    }
    @include media-min ('desktop-l') {
        font-size: size(50);
    }
}
.main_content {
    flex: 1 1 100%;
    overflow: hidden auto;
    padding: 1rem .875rem 3.5rem;
    @include media-min ('tablet') {
        margin: 0 auto;
        width: calc(100% - 160px);
        padding: 2rem 2rem 5.5rem;
        //padding: 2rem 1rem 6rem;
    }
    @include media-min ('desktop-s') {
        width: calc(100% - 192px);
        padding: 2rem 2rem 6rem;
    }
    @include media-min ('desktop-m') {
        padding: 3rem 4rem 7rem;
        width: calc(100% - 224px);
    }
    @include media-min ('desktop-l') {
        padding: 3rem 6rem 7rem;
        //padding: 3rem 6rem 10rem;
    }
}
.main_menu {
    @include media-min ('tablet') {
        flex: 1 0 160px;
        width: 160px;
    }
    @include media-min ('desktop-s') {
        flex-basis: 192px;
        width: 192px;
    }
    @include media-min ('desktop-m') {
        flex-basis: 224px;
        width: 224px;
    }
}
    


.slider_bloc {
    //margin-bottom: size(36);
    position: relative;
    .slider-title {
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        width: 100%;
        @include media-min ('tablet') {
            margin-bottom: size(24);
        }
        .title {
            color: #fff;
            font-size: size(10);
            font-weight: 800;
            line-height: 1.4em;
            margin: 0;
            @media (min-width: 600px ) {
                font-size: size(14);
            }
            @include media-min ('tablet') {
                font-size: size(18);
            }
            @include media-min ('desktop-s') {
                font-size: size(22);
            }
            @include media-min ('desktop-m') {
                font-size: size(22);
            }
        }
        .link__title {
            display: flex;
            color: #fff;
            align-items: center;
            text-decoration: none;
            &:hover {
                color: inherit;
                text-decoration: none;
            }
            img {
                height: 16px;
                margin-left: 20px;
                display: none;
                @include media-min ('tablet') {
                    display: inline-flex;
                }
            }
        }
        .button__seemore {
            color: #fff;
            display: inline-flex;
            font-size: size(8);
            font-weight: 800;
            text-decoration: none;
            text-transform: uppercase;
            &:hover {
                text-decoration: none;
            }
            @media (min-width: 600px ) {
                font-size: size(12);
            }
            @include media-min ('tablet') {
                font-size: size(12);
            }
            @include media-min ('desktop-s') {
                font-size: size(14);
                display: none;
            }
            @include media-min ('desktop-m') {
                font-size: size(16);
            }
        }
    }
    .slider-body {
        overflow: hidden;
        margin-right: -.875rem;
        @include media-min ('tablet') {
            margin-right: -1rem;
        }
        @include media-min ('desktop-s') {
            margin-right: -2rem;
        }
        @include media-min ('desktop-m') {
            margin-right: 0;
        }
    }
    .react-multi-carousel-list {
        justify-content: flex-start;
        margin: 0 -7px;
        @include media-min ('tablet'){
            margin:  0 size(-16);
        }
    }
    .slider_item {
        padding: 0 7px;
        @include media-min ('tablet'){
            padding:  0 size(16);
        }
    }

    .react-multi-carousel-track {
        justify-content: flex-start;
    }
    .carousel-button-group {
        right: -.75rem;
        top: -2px;
        @media screen and (max-width: 1023px) {
            display: none;
        }
    }
}
