@import '../../../styles/mixin';

.swiper-container {
    width: 100%;
    height: 100%;
    color: white;
    font-family: "Open Sans";

    .swiper-pagination-bullets{
        top: 10px;
        height: 0;
        @include media-min ('tablet'){
        top: 5vh;
        }
    }
    .swiper-pagination-bullet {
        background: white;
        opacity: 1;
    }
    .swiper-pagination-bullet-active {
        background-color:#5FB101;
    }
.swipper-wrapper {
    display: flex;
}
.swiper-slide {
    flex-shrink: 0;
    text-align: center;
    font-size: 18px;
    background: linear-gradient(#000000,#232529 );
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-btn {
    @media (max-width: 1023px) {
      display: none;
    }
    cursor: pointer;
    position: relative;
    z-index: 20;
    img {
      height: 30px;
    }
  }
  .swiper-button-prev {
    transform: translate(10px);
  }
  .swiper-button-next {
    transform: translate(-10px);
  }
  .tutorial-container{
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      @include media-min("desktop-s") {
        justify-content: flex-start;
      }
      .close-tuto{
      position: absolute;
      right: 1vw;
      top: 1vh;
      background-color: transparent;
      border: none;
      width: 50px;
      height: 50px;
      @include media-min ('tablet'){
      right: 2vw;
      top: 2vh;
      }
      p{
        font-size: 20px;
        color: white;
        padding: 0;
      @include media-min ('tablet'){
        font-size: 24px;
      }
      @include media-min ('desktop-l'){
        font-size: 26px;
      }
    }
      }
   

      h2 {
        font-weight: bold;
        text-transform: uppercase;
        font-size: 16px;
        margin-top: 5vh;
        margin-bottom: 2vh;
        @include media-min ('tablet'){
            margin-top: 10vh;
            font-size: 22px;
            }
      }
      p{
        font-size: 12px;  
        font-weight: 600;
        color: #A0A0A0A1;
        padding: 0 45px 0 45px;
        @include media-min ('tablet'){
            color: white;
            font-size: 18px;
            text-align: center;
            padding: 0 180px 0 180px;
            }
            @include media-min("desktop-s") {
                font-weight: 300;
                padding: 0 360px 0 360px;
            }
            @include media-min("desktop-m") {
              padding: 0 460px 0 460px;
          }
          @include media-min("desktop-l") {
            padding: 0 660px 0 660px;
        }
      }
      .small-image {
        width: 100vw;
        display: flex;
        justify-content: center;
        align-items: center;
        @include media-min ('tablet'){
            display: none;
        }
          .tuto-small{
            height: 55vh;
          }
          .picto {
              margin: 0 15px;
              width: 50px;
              height: 50px;
              position: absolute;
              right: 0;
          }
          .picto2 {
              margin: 0 15px;
              height: 50px;
              margin-bottom: 20vh;
              position: absolute;
              right: 0;
          }
          .picto3 {
            margin: 0 15px;
            height: 50px;
            position: absolute;
            right: 0;
          }
      }
      .tablet-image {
          display: none;
          @include media-min ('tablet'){
            display: flex;
            align-items: flex-end;
            position: relative;
        }
        @include media-min("desktop-s") {
            display: none;
        }
        .tuto-medium{
          height: 55vh;
        }
        .picto-tablet{
            margin: 0 15px;
            width: 65px;
            height: 65px;
        }
        .picto-tablet1{
            position: absolute;
            width: 65px;
            height: 65px;
            right: 50%;
            left: 50%;
            top: 50%;
        }
      }
      .desktop-footer {
        @include media-min("desktop-s") {
          display: flex;
          align-items: flex-end;
          margin-top: 2vh;
          margin-bottom: 10vh;
        }
      .desktop-image {
          display:none;
          @include media-min("desktop-s") {
            display:flex;
            width: 75vw;
            position: relative;
        }
        @include media-min("desktop-m") {
          width: 50vw;
        }
        @include media-min("desktop-l") {
          width: 45vw;
        }
        .picto-desktop1{
          position: absolute;
          right: 22vw;
          top: 8vh;
          width: 30px;
          @include media-min("desktop-m") {
            right: 13vw;
          }
        }
        .tuto-desktop{
            width: 65vw;
            border: 2px solid #3CAD00;
            @include media-min("desktop-m") {
              width: 45vw;
            }
            @include media-min("desktop-l") {
              width: 40vw;
            }
        }
      }
      .right-desktop {
        @include media-min("desktop-s") {
          display: flex;
          flex-direction: column;
          height: 30vh;
          justify-content: flex-end;
          align-items: center;
        }
      }
      .picto-desktop {
          display: none;
          @include media-min("desktop-s") {
            display: inline;
            width: 35px;
            height: 35px;
            margin-bottom: 4vh;
          }
      }
    }
      .linear-gradient-footer {
          background: linear-gradient(#3CAD00, #C8D900);
          margin-bottom: 0;
          padding: 20px ;
          color: white;
          width: 100vw;
          @include media-min ('tablet'){
          padding: 35px 180px;
          }
          @include media-min("desktop-s") {
            padding: 30px;
            width: 20vw;
            font-size: 12px;
            font-weight: bold;
            text-align: left;
          }
          @include media-min("desktop-m") {
          width: 15vw;
          }
          @include media-min("desktop-l") {
            width: 12vw;
            }
      }
  }
}

div.swiper-container + footer > .container__miniplayers {
  visibility: hidden;
}
