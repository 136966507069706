@import '../../../styles/mixin';

.container__artist__page {
    background: linear-gradient( #212626, #090A0A) ;
    color: #fff;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    font-size: 1rem;
    font-family: 'Sans open', sans-serif;
    padding-bottom: 150px;
    @media (min-width: 768px ) {
        padding-bottom: 90px;
        margin-left: 21%;
        padding-bottom: 30px;
    }
    @include media-min ('desktop-s') {
        padding-bottom: 95px;
    }
    @media (min-width: 1440px ) {
        margin-left: 21%;
        padding-bottom: 100px;
    }
    @include media-min ('desktop-l') {
    }

}

.titleArtistPage{
    color: white;
    position: absolute;
}