@import '../../../styles/mixin';

.overlay_container {
  display: none;
  @media screen and (max-height: 479px) and (min-width: 560px) {
    background: linear-gradient(#090a0a,#212626);
    position: fixed;
    top: 0;
    left: 0;
    min-height: 400px;
    height: 100%;
    width: 100%;
    z-index: 2000;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }
  .overlaywarninglogo {
    position: fixed;
    top: 15vh;
    left: 50%;
    transform: translate(-50%, 0);
    width: 80px;
   }
  .overlay_text_container {
    width: 60%;
    p {
      color: white;
      margin: 0;
    }
  }
}
