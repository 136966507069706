@import 'src/styles/mixin';

.bg_rgpd {
    background-image: url('../../../../public/assets/images/bg-maintenance.png');
    background-size: cover;
    background-position-x: center;
    background-position-y: center;
    height: 100vh;
    color: white;
    font-family: "Open Sans";
    position: fixed;
    z-index: 10;
    overflow: auto;

    @media (max-width: 767px) {
        width: 100vw;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
    }

    @include media-min('tablet'){
        background: black;
        left: 3vh;
        bottom: 3vh;
        width: 400px;
        height: 475px;
    }


        .rgpd_content{
            display: flex;
            flex-direction: column;
            align-items: center; 
            justify-content: space-around;
            height: 100%;
            margin: 20px 40px 20px 40px;
            padding-top: 40px;

            .logo {
                width: 100px;
                height: auto;
                @include media-min ('tablet'){
                    display: none;
                }
            }

            .btn_notAgree{
                background-color: transparent;
                border: none;
                color: white;
                font-weight: 300;
                font-size: 0.6rem;
                position: absolute;
                top: 40px;
                right: 40px;
                padding: 0;
            }
            .rgpd_info {
                text-align: justify;
                font-size: 0.8rem;
                @include media-min ('tablet'){
                    font-size: 0.7rem;
                }
            }
            .rgpd_button_container{
                text-align: center;
                align-items: center;
            }
            .btn_rgpd {
                height: 50px;
                width: 60vw;
                border-radius: 30px;
                font-size: 0.8rem;
                font-weight: bold;
                color: #fff;
                text-transform: uppercase;
                margin: 10px auto;
                @include media-min ('tablet'){
                    font-size: 0.8rem;
                    width: 250px;
                }
            }
            .btn_choice {
                border: none;
                background: linear-gradient(to right, #3cad00, #c8d900);
            }
            .btn_configure {
                border: rgb(255,255,255) 1.5px solid;
                background-color: transparent;
            }
            .rgpdprivacy_container{
                padding-bottom: 50px;
            }
            .rgpd_privacy {
                color: #fff;
                text-decoration: underline;
                @include media-min ('tablet'){
                    font-size: 0.7rem;
                }
            }
        }
}