@import '../../../styles/mixin';

.container__audioplayer {
  font-family: "Sans open", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: space-around;
  background: linear-gradient(#090a0a, #212626);
  min-height: 100vh;
  max-width: 100vh;
  color: #fff;
  .container__play {
    padding: 1rem 0 0 0;
    width: 100%;
    .container__picture {
      display: block;
      margin: 0.3rem;
      img {
        width: 100%;
      }
    }
    .container__titles {
      display: flex;
      padding: 0 1rem 1.5rem;
      flex-direction: row;
      align-items: baseline;
      justify-content: space-between;
      font-weight: 700;
      color: #fff;
      min-width: 100%;
      font-size: 10px;
      button {
        display: flex;
        background: transparent;
        border: none;
        color: #fff;
        img {
          transform: rotate(-90deg);
          margin-right: 1rem;
        }
      }

      .see__the__playlist {
        color: #fff;
        text-transform: uppercase;
        font-size: 8px;
      }
    }
    .container__audioplayer__description {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: flex-start;
      margin: 1.5rem 0.3rem 4rem;
      font-size: 20px;
      img {
        width: 22px;
      }
      .audioplayer__title {
        font-weight: 900;
      }
      p {
        padding: 0;
        margin: 0;
      }
      button {
        border: none;
        background: transparent;
      }
    }
    .container__range__audioplayer {
      width: 90%;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      .slider {
        -webkit-appearance: none;
        width: 100%;
        height: 3px;
        background: gray;
        outline: none;
        border-radius: 50px;
      }
      /*for google*/
      .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 13px;
        height: 13px;
        border-radius: 50px;
        background: #3cad00;
      }
      /*for mozilla*/
      .slider::-moz-range-thumb {
        width: 13px;
        height: 13px;
        border-radius: 50px;
        background: #3cad00;
      }

      .range__time {
        margin-top: 0.5rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        font-size: 0.7rem;
      }
    }
  }
  .container__audioplayer__button {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    .title__playlist {
      font-size: 1rem;
    }
    .button_shuffle {
      width: 17px;
      height: 16px;
    }
    .buttonM {
      width: 25px;
    }
    .button_repeat {
        width: 17px;
        height: 16px;
    }
    .btn_repeatonce {
      position: relative;
      .active_repeatonce {
        background: #c8d900;
        color: #fff;
        width: 13px;
        font-weight: bold;
        height: 13px;
        display: block;
        border: 0.5px solid #fff;
        border-radius: 50%;
        position: absolute;
        right: 1px;
        top: 2px;
        font-size: 10px;
      }
    }
    .container__buttonL {
      background-color: #fff;
      height: 5.2rem;
      width: 5.2rem;
      border-radius: 50px;
      position: relative;
      .buttonL__play {
        width: 25px;
        position: absolute;
        top: 34%;
        left: 39%;
        filter: invert(100%);
      }
      .buttonL__breack {
        width: 17px;
        position: absolute;
        top: 32%;
        left: 40%;
        filter: invert(100%);
      }
    }

    button {
      border: none;
      background: transparent;
      color: #fff;
    }
  }
}
