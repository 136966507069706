@import '../../../styles/mixin';

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.container-forgot-pwd {
  //background: linear-gradient(#090a0a,#212626);
  .link_return {
  }
  .content-forget-pwd {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }  
    h1 {
      @include media-min ('tablet') {
        // font-size: 22px;
        // margin: 3rem 0 3rem;
      }
    }
    p {
      width: 65%;
      text-align: center;
      margin-bottom: 2rem;
      @include media-min ('tablet') {
        width: 55%;
        margin-bottom: 3rem;
        font-size: 18px
      }
      @include media-min ('desktop-s') {
        width: 40%;
      }
      @include media-min ('desktop-m') {
        width: 30%;
      }
      @media (min-width: 1660px) {
        width: 25%;
      }

    }
    .wrong {
      color:  #F00;
      width: 322px;
    }
    .container__input {
      border-radius: 2px;
      height: 40px;
      max-width: 512px;
      margin-bottom: 2rem;
      width: 80vw;
      @include media-min ('tablet') {
        height: 64px;
        
        //width: 90%;
        @media (max-height: 699px) {
          margin-bottom: 1rem;
        }
      }
      
      .input {
        border: none;
        border-radius: 2px;
        box-shadow: inset 0px 1px 3px rgba(0,0,0,0.5);
        color: #989898;
        font-size: size(14);
        font-weight: 600;
        height: 100%;
        outline: none;
        padding: 8px 20px;
        width: 100%;
        margin-bottom: 3px;
        @include media-min ('tablet') {
          border-radius: 11px;
          width: 100%;
        }
        // @include media-min ('desktop-s') {
        //   width: 60%;
        // }
        // @include media-min ('desktop-m') {
        //   width: 40%;
        // }
      }
      .input::placeholder {
        color: #989898;
      }
    }
    .container__input__dv {
      display: flex;
      justify-content: center;
      height: 40px;
      @include media-min ('tablet') {
        height: 64px;
        width: 512px;
      }
      .prefix {
        background: linear-gradient(270deg, #C8D900, #78C000, #3CAD00);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        font-size: .75rem;
        font-weight: 800;
        display: block;
        height: 100%;
        text-align: center;
        padding: .7rem .2rem;
        width: 50px;
        @include media-min ('tablet') {
          font-size: 1rem;
          width: 96px;
          padding: 1.2rem .2rem;
        }
        @include media-min ('desktop-s') {
          font-size: 1rem;
          width: 96px;
          padding: 1.2rem .2rem;
        }
      }
      .input {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top-left-radius:0 ;
        border-bottom-left-radius: 0;
        border: none;
        color: #989898;
        font-size: .875rem;
        font-weight: 800;
        outline: none;
        height: 100%;
        padding-left: 1rem;
        width: 80%;
        @include media-min ('tablet') {
          width: 100%;
        }
      }
    }
    .container__button {
      margin-top: 2rem !important;
      @include media-min('tablet'){
        margin-top: 4rem !important;
      }
    }
  
}
