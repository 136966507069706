@import '../../../styles/mixin';

.playlist {
  max-height: 100%;
  padding: 0;
  @include media-min('tablet') {
    overflow: unset !important;
  }
  .main_menu {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    z-index: 1;
  }
  .main_content {
    height: auto;
    overflow: unset;
    padding: 0;
    @include media-min('tablet') {
     margin-left: 160px;
    }
    @include media-min('desktop-s') {
      margin-left: 192px;
    }
    @include media-min('desktop-m') {
      margin-left: 224px;
    }
  }
  .bgMobile {
    position: fixed;
    opacity: 0.5193917;
    width: 100%; height: 100%;
    z-index: 0;
    @include media-min('tablet') {
      display: none;
    }
    &:before {
      background: linear-gradient(180deg, #00000000, #000000);
      content: "";
      position: fixed;
      height: 100%;
      width: 100%;
      z-index: 1;
      @include media-min('tablet') {
        display: none;
      }
    }
    img {
      height: 100%;
      object-fit: cover;
      object-position: center top;
      width: 100%;
    }
  }
  .main-playlist {
    display: flex;
    flex-direction: column;
    align-items: center;
    //justify-content: center;
    height: 100%;
    max-height: calc(100% - 60px);
    width: 100%;
    &:before {
      display: none;
      background: linear-gradient(180deg, #00000000, #000000);
      content: "";
      height: 100%;
      width: 100%;
      position: fixed;
      left: 0; top: 0; right: 0;
      z-index: 0;
    }
    .content_playlist {
      width: 100%;
    }
  }
  .body-list-group {
    width: 100%;
    position: relative;
    z-index: 0;
    padding: 0;
    @include media-min('tablet') {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 4.5rem;
    }
    @include media-min('desktop-s') {}
    @include media-min('desktop-m') {}
    @include media-min('desktop-l') {}
  }
  .list-group {
    @media (max-width: 767px)  {
      padding-bottom: 75px;
    }
  }

}
.container-playlistUnvailable {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
.playlist_unvailable {
  color: #fff;
  font-size: 15px;
}
}
.mobilePlayOnClick {
  // pointer-events: none;
}




