@import "../../../styles/mixin";

.containerRelogin {
  position: absolute;
  z-index: 1;
  background: linear-gradient(
      to right,
      rgba(200, 157, 46, 0.5),
      rgba(200, 157, 46, 1)
    ),
    url("../../../../public/assets/images/bg-maintenance.png") center no-repeat, #000000;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__close {
    border: none;
    background-color: transparent;
    position: absolute;
    top: 20px;
    right: 20px;
    img {
      width: 32px;
    }
  }

  &__message {
    margin: 2rem;
    font-size: 1.3rem;
    color: #fff;
    text-align: center;
    a {
      color: #fff;
    }
    @media (min-width: 668px) {
      font-size: 2rem;
    }
  }
}
