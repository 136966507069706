@import "../../../styles/mixin";
.similar-playlist {
  padding: 0 0.8rem 4.5rem;
  margin-top: 2vh;
  position: relative;

  @media (max-width: 767px) {
    padding-bottom: 85px;
  }

  .carousel-button-group {
    right: 1.25rem;
    top: -2px;
    @media screen and (max-width: 1023px) {
      display: none;
    }
  }

  &__title {
    color: #fff;
    font-size: size(10);
    font-weight: 800;
    line-height: 1.4em;
    margin: 0;
    margin-bottom: 10px;
    @media (min-width: 600px) {
      font-size: size(14);
    }
    @include media-min("tablet") {
      font-size: size(18);
      margin-bottom: size(24);
    }
    @include media-min("desktop-s") {
      font-size: size(22);
    }
    @include media-min("desktop-m") {
      font-size: size(22);
    }
  }

  &__item {
    padding: 0 7px;
    @include media-min("tablet") {
      padding: 0 size(16);
    }
    a {
      text-decoration: none;
    }

    &__picture-content {
      border-radius: 5px;
      margin: 0;
      position: relative;

      &__picture {
        border-radius: inherit;
        max-width: 100%;
        width: 100%;
        @include media-min("tablet") {
        }
        @include media-min("desktop-l") {
        }
      }
    }
    &__title {
      color: #fff;
      font-size: size(10);
      font-weight: 800;
      padding-top: 0.5rem;
      margin-bottom: 0;
      @include media-min("tablet") {
        font-size: 1rem;
        padding-top: 1rem;
      }
    }
  }
}
