@import '../../../styles/mixin';

.carousel-button-group {
    position: absolute;
    outline: none;
    border: none;
    img {
        width: 10px;
        @include media-min('tablet') {
            width: 16px;
        }
    }
    .btn-link {
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
    }
}