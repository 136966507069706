@import '../../../styles/mixin';

.container_onboarding_moods {
  display: flex;
  flex-direction: column;
  font-family: "Open Sans";
  color: #fff;
  padding: 3vh;
  background: linear-gradient(to top, #212626, #090a0a);
  @include media-min ('desktop-m') {
    height: 100vh;
  }

  .active-filters {
    .filter-choice {
      position: relative;
      top: 2px;
      z-index: 2;
      width: 100%;
      margin-left: 7px;
      display: flex;
      flex-wrap: wrap;
      @include media-min ('tablet') {
        width: inherit;
        top: 5px;
      }
      @media (max-width: 767px) {
        justify-content: space-between;
      }
      .delete-btn-mobil {
        background-color: transparent;
        margin-right: 37px;
        @include media-min ('tablet') {
          display: none;
        }
        img {
          width: 22px;
        }
      }
      .flex.mobile {
        @media (max-width: 767px) {
          width: 93%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      button {
        text-transform: uppercase;
        color: white;
        background-color: grey;
        padding: 10px 5px 10px 5px;
        border: none;
        border-radius: 3px;
        width: max-content;
        margin-bottom: 4%;
        font-size: 13px;
        margin-right: 15px;
        display: flex;
        justify-content: space-between;
        height: 40px;

        p:first-child {
          margin-right: 15px;
        }
      }
    }
  }

  .bg-onboarding-moods {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .onboarding-moods-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 5vh;
      h1 {
        font-size: 1.1rem;
        font-weight: bold;
        padding-bottom: 2vh;
        padding-top: 4vh;
        @include media-min ('tablet') {
          font-size: 2.5rem;
        }
        @include media-min ('desktop-s') {
          font-size: 2.7rem;
          padding-top: 4vh;
        }
        @include media-min ('desktop-l') {
          font-size: 2.7rem;
          padding-top: 0vh;
        }
      }
      p {
        text-align: center;
        font-size: 1rem;
        font-weight: bold;
        padding-bottom: 2vh;
        @include media-min ('tablet') {
          font-size: 1.5rem;
        }
      }
      .filter-choice p{
        font-size: 1rem;
      }
      .mood {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: auto;
        margin-right: auto;
        @media (max-width: 767px) {
          padding-bottom: 2vh;
        }
        @include media-min ('tablet') {
          width: 95%;
        }
        @include media-min ('desktop-s') {
          width: 90%;
        }
        @include media-min ('desktop-m') {
          width: 85%;
        }
        @include media-min ('desktop-l') {
          width: 80%;
        }
        .mood-picto {
          width: 40px;
          @include media-min ('desktop-s') {
            width: 30px;
          }
          @include media-min ('desktop-m') {
            width: 32px;
          }
        }
        button {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          color: white;
          border-radius: 5px;
          width: 25vw;
          height: 25vw;
          margin: 2vw;
          font-size: 10px;
          text-transform: uppercase;
          border: 0 none;
          @include media-min ('tablet') {
            width: 17.5vw;
            height: 17.5vw;
            font-size: 1.3rem;
          }
          @include media-min ('desktop-s') {
            width: 14vw;
            height: 14vw;
            margin: 1vw;
          }
          @include media-min ('desktop-m') {
            width: 9vw;
            height: 9vw;
            font-size: 22px;
          }
          @include media-min ('desktop-l') {
            width: 150px;
            height: 150px;
          }
          p{
            position: absolute;
            bottom: 0;
            font-size: 0.5rem;
            width: 23vw;
            overflow-wrap: break-word;
            padding-bottom: 0;
            @include media-min ('tablet') {
              font-size: 0.7rem;
              width: 16vw;
            }
            @include media-min ('desktop-s') {
              width: 15vw;
              font-size: 0.8rem;
            }
          }
        }
      }
      .btn-moods {
        height: 50px;
        width: 60vw;
        color: #fff;
        border-radius: 3px;
        border: none;
        background: linear-gradient(to right, #3cad00, #c8d900);
        text-transform: uppercase;
        font-size: 0.9rem;
        margin-top: 4vh;
        font-weight: 700;
        margin-bottom: 2vh;
        @include media-min ('tablet') {
          border-radius: 50px;
          width: 40vw;
          height: 70px;
        }
        @include media-min ('desktop-s') {
          width: 30vw;
          height: 75px;
          font-size: 1rem;
        }
        @include media-min ('desktop-m') {
          height: 80px;
          font-size: 1.2rem;
        }
        @include media-min ('desktop-l') {
          height: 85px;
          font-size: 1.3rem;
        }
      }
      .valid-mood {
        margin-top: 10vh;
      }
    }
  }
}
