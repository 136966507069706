@import '../../../styles/mixin';

.freetrial__page {
    .container__free_trial {
        @include media-min ('tablet'){
            padding-bottom: 0;
        }
        @include media-min ('desktop-s') {
            //font-size: 18px;
        }
                h1 {
            @include media-min ('tablet'){
                //margin: 5rem 0 3rem;
                //font-size: 22px;
            } 
            @include media-min ('desktop-s') {
                //margin: 6rem 0 3rem;
            }
        }
        .imagelogo {
            position: relative;
            @include media-min ('tablet'){
            }  
        }
        .container__input {
            display: flex;
            align-items: center;
            @include media-min ('tablet'){
                width: 428px;
            } 
            span {
                color: #71b804;
                font-size: .75rem;
                font-weight: 800;
                margin-right: 6px;
                padding-top: 0px;
                //width: 40px;
                white-space: nowrap;
                @include media-min ('tablet'){
                    font-size: 18px;
                    width: 65px;
                    margin-right: 10px;
                } 
                @include media-min ('desktop-s') {
                    margin-right: 1rem;
                }
            }
            input {
                border: none;
                border-radius: 2px !important;
                box-shadow: inset 1px 2px 3px rgba(0,0,0,0.5);
                margin-right: 14px;
                height: 40px !important;
                width: 54px !important;
                &:last-child {
                    margin-right: 0;
                }
                @include media-min ('tablet'){
                    border-radius: 11px !important;
                    margin-right: 2rem;
                    height: 64px !important;
                    width: 64px !important;
                } 
            }
            .styles_react-code-input-container__tpiKG {
                width: 100% !important;
                @include media-min ('tablet'){
                }   
            }
            .styles_react-code-input__CRulA > input {
                color: #989898;
                font-family: 'Open Sans', sans-serif;
                font-size: 1rem;
                font-weight: 800;
                &:focus {
                    border-color: transparent;
                    caret-color: transparent;
                }
            }
        }
        .container__button {
            @include media-min ('tablet'){
                margin-top: 3rem;
            }
            .button__freetrial {
            }
        }
        
    }
}