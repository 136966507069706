@import "../../../styles/mixin";

.appBanner {
  display: table;
  justify-content: space-between;
  background: linear-gradient(#b1d00e, #5fb101);
  width: 100vw;
  margin-left: -0.875rem;
  color: #fff;
  margin-bottom: 20px;
  text-decoration: none;
  &:hover {
    color: #fff;
  }
  @media screen and (min-width: 767px) {
    display: none;
  }
  .banner-text {
    display: table-cell;
    flex-direction: column;
    justify-content: center;
    padding: 0.875rem;
    h1 {
      font-size: 24px;
      font-weight: bold;
      font-size: 1em;
      margin-bottom: 5px;
      @media screen and (min-width: 600px) {
        font-size: 1.4rem;
      }
    }
    p {
      font-size: 0.625rem;
      font-weight: lighter;
      font-style: italic;
      margin-bottom: 0;
      @media screen and (min-width: 600px) {
        font-size: 0.875rem;
      }
    }
  }
  .banner-illu {
    display: table-cell;
    position: relative;
    width: 30%;
    img {
      position: absolute;
      bottom: 0;
      right: 0;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
