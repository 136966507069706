@import "../../../styles/mixin";

.container__mood {
  margin-bottom: size(28);
  @include media-min("tablet") {
    margin-bottom: size(40);
  }
  .carousel-button-group {
    //display: none;
    // @include media-min ('tablet') {
    //     display: inline;
    //     top: 0.5rem;
    //     right: 0rem;
    // }
  }
  .moods-titles-extra {
    /* display: flex;
        align-items: baseline;
        justify-content: space-between;
        padding: 0 20px; */
    /* .link__title {
            @include media-min ('tablet') {
                display: flex;
                align-items: baseline;
            }
            img {
                display: none;
                @include media-min ('tablet') {
                    display: block;
                    width: 10px;
                    height: 15px;
                }
            }
            .title-extra-moods {
                font-weight: 800;
                font-size: 14px;
                margin-right: 1rem;
                margin-top: 1rem;
                color: #fff;
                @media (min-width:768px) {
                    font-size: 18px;
                }
                @include media-min ('desktop-m') {
                    font-size: 22px;
                }
            } 
        } */
    /* .button__seemore {
            font-size: 14px;
            color: white;
            @include media-min ('tablet') {
                display: none;
            }
        } */
  }
  .mood-picto {
    width: 25px;
    //margin-bottom: 10px;
    @include media-min("tablet") {
      width: 44px;
    }
    @include media-min("desktop-s") {
    }
    @include media-min("desktop-m") {
      width: 58px;
    }
  }
  .slider-content {
    .react-multi-carousel-list {
      margin: 0 -7px;
      @include media-min("tablet") {
        margin: 0 size(-12);
      }
      @include media-min("desktop-m") {
        margin: 0 size(-16);
      }
      .slider_item {
        padding: 0 7px;
        @include media-min("tablet") {
          padding: 0 size(12);
        }
        @include media-min("desktop-m") {
          padding: 0 size(16);
        }
      }
    }
  }

  .mood-filter {
    background-color: #20c997;
    border-radius: 2px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 55px;
    text-decoration: none;
    //width: 55px;
    //margin-bottom: 30px;
    @media screen and (min-width: 600px) {
      height: 96px;
    }
    @include media-min("tablet") {
      height: 96px;
      // width: 100px;
      // font-size: 18px;
    }
    @include media-min("desktop-s") {
      // height: 110px;
      // width: 110px;
    }
    @include media-min("desktop-m") {
      height: 128px;
      // font-size: 22px;
      // width: 150px;
    }
    @include media-min("desktop-l") {
      // width: 170px;
      // height: 170px;
    }
    p {
      color: #fff;
      text-align: center;
      &.picto {
        margin: 0;
        @include media-min("tablet") {
          margin-bottom: 10px;
        }
      }
      &.text {
        font-size: size(8);
        font-weight: 800;
        margin: 0;
        text-align: center;
        @include media-min("tablet") {
          font-size: size(12);
        }
      }
    }
    /* p {
            font-size: 10px;
            position: absolute;
            bottom: 20px;
            text-transform: uppercase;
            text-align: center;
            width: 70px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @include media-min ('tablet'){
                font-size: 14px;
                width: 90px;
            }

            @include media-min ('desktop-s') {
                font-size: 14px;
                width: 100px;
            }
            @include media-min ('desktop-m') {
                font-size: 14px;
                width: 120px;
            }
            @include media-min ('desktop-l') {
                font-size: 16px;
                width: 140px;
            }
        } */
  }
  a.mood-filter:hover {
    text-decoration: none;
    color: white;
  }
}
