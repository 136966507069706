@import '../../../styles/mixin';

.container-home {
    //background: linear-gradient( #090A0A, #212626) ;
    //color: #fff;
    //display: flex;
    //flex-direction: column;
    //min-height: 100vh;
    //font-size: 1rem;
    padding-bottom: 150px;
    @media (min-width: 768px ) {
        //padding-top: 2.5rem;
        //margin-left: 160px;
        // padding-left: 20px;
        // padding-bottom: 30px;
    }
    @include media-min ('desktop-s') {
        //padding-top: 5rem;
        //padding-bottom: 95px;
        //margin-left: 258px;
    }
    @media (min-width: 1440px ) {
        //padding-left: 3rem;
        //padding-bottom: 100px;
    }
    @include media-min ('desktop-l') {
        //padding: 3rem 5rem 110px 6rem;
    }
    .react-multiple-carousel__arrow {
        display: none;
    }
    .react-multi-carousel-list,
    .container-titles-extra,
    .container-titles-only{
        //padding: 0 20px;
    }

    .title-page {
        display: none;
        @include media-min ('tablet') {
            // display: inline-block;
            // font-size: size(30);
            // font-weight: 800;
            // padding-bottom: 1.5rem;
            // padding-left: 20px;
            // padding-right: 20px;
        }
    }
    .tutorial{
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        position: absolute;
        z-index: 2;
        height: 62vh;
        top: 25vh;
        width: 80vw;
        background-color: black;
        right: 10vw;
        left: 10vw;
        border-width: 2px;
        border-color: #3CAD00;
        border-style: solid;
        text-align: center;
        @include media-min ('tablet') {
          right: 10vw;
          left: 10vw;
          margin-left: 21%;
          width: 60vw;
          top: 30vh;
        }
        @include media-min ('desktop-m') {
            margin-left: 15%;
        }
        .close-tuto {
            align-self: flex-end;
            width: 50px;
            height: 50px;
            background-color: transparent;
            color: white;
            border: none;

        }
        h2 {
            font-size: 22px;
            font-weight: bold;
            @include media-min ('tablet') {
                font-size: 24px;
                }
        }
        p {
            font-size: 14px;
            padding: 0 15px 0 15px;
            @include media-min ('tablet') {
                font-size: 16px;
                }
        }
        .tuto-ok {
            background: linear-gradient(#3CAD00, #C8D900);
            border:none;
            color: white;
            padding: 10px;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: bold;
            width: 50vw;
            @include media-min ('tablet') {
            font-size: 16px;
            width: 45vw;
            }
            @include media-min ('desktop-s') {
                width: 40vw;
            }
            @include media-min ('desktop-m') {
                width: 35vw;
            }
            @include media-min ('desktop-l') {
                width: 30vw;
            }
        }
        .tuto-skip {
            background: transparent;
            border: 1px solid white;
            color: white;
            padding: 10px;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: bold;
            width: 50vw;
            @include media-min ('tablet') {
                font-size: 16px;
                width: 45vw;
                }
                @include media-min ('desktop-s') {
                    width: 40vw;
                }
                @include media-min ('desktop-m') {
                    width: 35vw;
                }
                @include media-min ('desktop-l') {
                    width: 30vw;
                }
        }
    }
}