@import '../../../styles/mixin';

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.container__phoneNumber {
  background: linear-gradient(#090a0a,#212626);
  height: 100vh;
  overflow: auto;
  @media screen and (max-width: 767px) {
    background: url("../../../backgroundmobile.png") center  no-repeat;
    background-size: cover;
  }
  .main_content {
    height: 100vh;
    overflow: hidden; 
    //padding-top: 3.85rem;
  }
  .container_changeLanguage {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: absolute;
    left: 0; top: 0;
    width: 100%;
    .link_change_language {
      color: #fff;
      display: inline-flex;
      font-size: 8px;
      font-weight: 600;
      margin: 0;
      padding: 15px 12px 12px;
      
      @include media-min ('tablet') {
        font-weight: normal;
        font-size: 15px;
        height: 100%;
        padding: 20px;
      }
    }
  }
  .imagelogo {
    height: auto;
    margin: 0 auto 2rem;
    top: 0;
    width: 91px;
    @include media-min ('tablet') {
      width: 160px;
      @media (max-height: 699px) {
        margin-bottom: 1rem;
      }
    }
  }
  .content__phoneNumber {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
   }

  .container__askPhoneNumber {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    text-align: center;
    .title_content {
      margin-bottom: 2rem;
      @include media-min ('tablet') {
        @media (max-height: 699px) {
          margin-bottom: 1rem;
        }
      }
      h1 {
        background: -webkit-linear-gradient(360deg,#3CAD00, #C8D900);
        background: linear-gradient(to bottom, #3CAD00, #C8D900 );
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: size(20);
        font-weight: 800;
        line-height: 1.25rem;
        margin-bottom: size(20);
        //margin: 3rem 0 2rem;
        
        @include media-min ('tablet') {
          font-size: size(30);
          line-height: 1.6rem;
          //margin: 5rem 0 3rem;
          margin-bottom: size(32);
        }
        @include media-min ('desktop-s') {
          //margin: 4rem 0 3rem;
          margin-bottom: size(38);
        }
      }
      p {
        font-size: size(12);
        font-weight: 600;
        line-height: 1rem;
        margin: 0 auto;
        text-align: center;
        max-width: 70%;
        @include media-min ('tablet') {
          font-size: size(18);
          line-height: 1.6rem;
          max-width: 80%;
        }
      }
    }
    .link_return {
      position: absolute;
      top: 0;
      left: 0;
      margin: .60rem 1.2rem;
      @include media-min ('tablet') {
        margin: 1rem 2rem;
      }
      img {
        width: 12px;
        @include media-min ('tablet') {
          width: 20px;
        }
      }
    }
    .container__input {
      border-radius: 2px;
      height: 100%;
      max-width: 512px;
      margin-bottom: 2rem;
      width: 100%;

    .container__input__bcd {
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 512px;
      @include media-min ('tablet') {
        //width: 90%;
        @media (max-height: 699px) {
          margin-bottom: 1rem;
        }
      }
      .forgotLogin {
        color: #fff;
        font-size: .65rem;
        font-weight: 600;
        width: 83%;
        text-align: end;
        max-width: 512px;
        p {
          margin: 0px;
          padding: 5px 5px 0px;
        }
      }
      .input {
        border: none;
        border-radius: 2px;
        box-shadow: inset 0px 1px 3px rgba(0,0,0,0.5);
        color: #989898;
        font-size: size(14);
        font-weight: 600;
        height: 40px;
        outline: none;
        padding: 8px 20px;
        width: 80%;
        margin-bottom: 5px;
        @include media-min ('tablet') {
          border-radius: 11px;
          height: 64px;
        }
      }
    }
    .container__input__dv {
      border-radius: 2px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 40px;
      max-width: 512px;
      margin-bottom: 1rem;
      width: 100%;
      @include media-min ('tablet') {
        height: 64px;
        
        //width: 90%;
        @media (max-height: 699px) {
          margin-bottom: 1rem;
        }
      }
      .prefix {
        background: linear-gradient(to bottom, #3CAD00, #C8D900 );
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        color: #fff;
        display: block;
        font-size: size(12);
        font-weight: 800;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 54px;
        padding: 8px 0px;
        @include media-min ('tablet') {
          border-top-left-radius: 11px;
          border-bottom-left-radius: 11px;
          font-size: size(18);
          width: 96px;
        }
      }
      .input {
        border: none;
        border-radius: 0 2px 2px 0;
        box-shadow: inset 0px 1px 3px rgba(0,0,0,0.5);
        color: #989898;
        font-size: size(14);
        font-weight: 600;
        height: 100%;
        outline: none;
        padding: 8px 20px;
        width: 80%;
        width: calc(80% - 54px);
        @include media-min ('tablet') {
          border-radius: 0 11px 11px 0;
          width: calc(80% - 96px);
        }
      }
      .input::placeholder {
        color: #989898;
      }
    }
    .input::placeholder {
      color: #989898;
    }
  }
    .container__link {
      margin-bottom: 2rem;
      .link__informations {
        color: white;
        font-size: .75rem;
        font-weight: 600;
        text-decoration: underline;
      }
    }
    .container__button {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: size(100);
      @include media-min ('tablet') {
        margin-bottom: size(96);
        @media (max-height: 699px) {
          margin-bottom: size(32);
        }
      }
      .button__askphone {
        //background: linear-gradient(to right, #3CAD00, #C8D900 );
        background: linear-gradient(270deg, #5FB101, #B1D00E);
        box-shadow: inset 1px 2px 3px rgba(0,0,0,0.5);
        border-radius: 2px;
        border: none;
        color: #fff;
        height: 40px;
        font-size: size(11);
        font-weight: 800;
        text-transform: uppercase;
        width: 156px;
        @include media-min ('tablet') {
          border-radius: 48px;
          height: 64px;
          font-size: size(18);
          width: 320px;
        }
      }
    }
    
    .link__code {
      color: #fff;
      background-color: transparent;
      border: none;
      display: inline-block;
      font-size: size(12);
      font-weight: 700;
      line-height: 1.25;
      -webkit-appearance: initial;
      @include media-min ('tablet') {
        font-size: size(22);
        line-height: 1.2;
      }
      &:hover {
        text-decoration: none;
      }
    }
    .wrong {
      color:  #F00;
      margin-bottom: 0;
      text-align: center;
      width: 322px;
      &:last-child, &:last-of-type {
        margin-bottom: 1rem;
      }
      //padding-top: .5rem;
    }
    .error__registered {
      color:  #F00;
      text-align: center;
      padding: 0px 0px 5px;
      margin: 0;
      &:last-child, &:last-of-type {
        margin-bottom: .5rem;
      }
    }
  }
}