@import '../../../styles/mixin';

.mobilePlayOnClick {
  cursor: pointer;
  height: 100%;
  transition: all .20s ease-in-out;
  width: 100%;
  @include media-min('tablet') {
    background-color: #101113;
    border-bottom-color: #000;
    margin-bottom: 0;
  }
  .innerList {
    cursor: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 54px;
    @media (max-width: 767px) {
      padding: 0 10px;
      margin: 5px 0;
    }
    @include media-min('tablet') {
      height: 80px;
      max-width: 1087px;
      margin-bottom: 0;
      padding: 1rem 2rem;
    }
    @include media-min('desktop-s') {
      padding-left: 4rem;
      padding-right: 4rem;
    }
    @include media-min('desktop-l') {
      padding-left: 6rem;
      padding-right: 6rem;
    }
  }
  .leftTrack-content {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .cover_img {
    flex: 1 0 54px;
    height: 54px;
    margin: 0;
    width: 54px;
    min-width: 54px;
    @include media-min('tablet') {
      flex-basis: 48px;
      height: 48px;
      min-width: 48px;
      width: 48px;
    }
    img {
      display: block;
      object-fit: cover;
      object-position: center top;
      height: 100%;
      width: 100%;
    }
  }
  p {
    color: #fff;
    margin: 0;
  }

  .track-description-md {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: 767px) {
      display: block;
      margin-left: size(14);
      max-width: 60vw;
    }
    @include media-min('tablet') {
      margin-left: 2rem;
    }
    @include media-min('desktop-s') {}
    @include media-min('desktop-m') {}
    p {
      font-weight: 800;
      @include media-min('tablet') {
        width: 7.5rem;
      }
      @include media-min('desktop-s') {
        width: 12rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      @include media-min('desktop-m') {
        width: 16rem;
      }
      @include media-min('desktop-l') {
        width: 18rem;
      }
    }
    .track-title {
      font-size: 10px;
      @media (max-width: 767px) {
        overflow: hidden;
        max-height: 30px;
      }
      @include media-min('tablet') {
        font-size: 12px;
      }
      @include media-min('desktop-s') {
        font-size: 14px;
      }
    }
    .track-artist {
      font-size: 8px;
      @media (max-width: 767px) {
        overflow: hidden;
        max-height: 30px;
      }
      @include media-min('tablet') {
        font-size: 12px;
        font-weight: 600;
      }
      @include media-min('desktop-s') {
        font-size: 14px;
      }
    }
    .point {
      background-color: #fff;
      border-radius: 12px;
      height: 5px;
      width: 5px;
      max-width: 5px;
      min-width: 5px;
      @media (max-width: 767px) {
        display: none;
      }
      @include media-min('tablet') {
        margin: 0 1.25rem;
      }
      @include media-min('desktop-s') {
        margin: 0 1.5rem;
      }
    }
  }
  .lastInfoPlaylist {
    pointer-events: all;
    display: flex;
    align-items: center;
    flex-direction: row;
    .track-time {
      font-weight: 600;
      @media (max-width: 767px) {
        display: none;
      }
      @include media-min('tablet') {
        font-size: 12px;
        margin-right: 2rem;
      }
    }
    button {
      background: none;
      border: none;
      padding: 0;
      &:focus {
        outline: none;
      }
    }
    .playButton {
      @media (max-width: 767px) {
        display: none;
      }
      @include media-min('tablet') {
        height: 32px;
        margin-right: 2rem;
      }
      @include media-min('desktop-s') {
        
      }
      img {
        width: 24px;
        vertical-align: top;
        @include media-min('tablet') {
          width: 32px;
        }
        @include media-min('desktop-s') {
          width: 32px;
        }
      }
    }

    .buttonHeart {
      img {
        width: 24px;
        @include media-min('tablet') {
          width: 32px;
        }
      }
    }
  }
}
