@import '../../../styles/mixin';


.modalRefreshSubscriptionOverlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    opacity: 0.75;
}

.modalRefreshSubscription{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 2;
    height: 50vh;
    top: 25vh;
    width: 80vw;
    background-color: black;
    right: 10vw;
    left: 10vw;
    border-width: 2px;
    border-color: #3CAD00;
    border-style: solid;
    text-align: center;
    h1 {
        font-weight: bold;
        font-size: 22px;
        padding: 0 15px 0 15px;
        color: white;
        @include media-min ('tablet') {
            font-size: 24px;
            }
    }

    @media (min-width:1024px) {
        height: 25vh;
        top: 37.5vh;
        width: 40vw;
        right: 30vw;
        left: 30vw;
    }

}