@import '../../../styles/mixin';

.btn-skip {
    top: 4vh;
    background-color: transparent;
    border: none;
    color: #D8D8D8;
    font-size: 0.8rem !important;
    @include media-min ('tablet') {
        font-size: 1rem !important;
    }
    @include media-min ('desktop-l') {
        font-size: 1.2rem !important;
      }
    
}
