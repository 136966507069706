@import 'src/styles/mixin';

.bg_rgpdConfigure {
    height: 100vh;
    color: white;
    font-family: "Open Sans";
    background: black;
    position: fixed;
    z-index: 10;
    overflow: auto;

    @media (max-width: 767px) {
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        width: 100vw;
    }


    @include media-min('tablet'){
        left: 3vh;
        bottom: 3vh;
        width: 400px;
        height: 875px;
        max-height: 94vh;
        overflow: auto;
    }


        .rgpdConfigure_content{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin: 20px 40px 140px;


            @include media-min('tablet'){
                margin: 80px 40px 0;
            }

            .logo {
                width: 100px;
                height: auto;
                margin-bottom: 3vh;
                @include media-min ('tablet'){
                    display: none;
                }
            }

            .btn_close {
              background-color: transparent;
              border: none;
              color: white;
              position: absolute;
              top: 20px;
              right: 40px;
              padding: 0;
              font-size: 1rem;
              @include media-min ('tablet'){
                font-size: 1.5rem;
              }
            }
            .center_logo{
                margin: auto;
            }
            .rgpdConfigure_info {
                text-align: justify;
                font-size: 0.8rem;
                @include media-min ('tablet'){
                    font-size: 0.7rem;
                }
                .rgpd_privacy {
                    margin-left: 0.5rem;
                    color: white;
                    text-decoration: underline;
                    text-transform: lowercase;
                    text-align: start;
                    @include media-min ('desktop-s') {
                        margin-left: 0.8rem;
                    }
                }
            }
            .rgpdConfigure_autorization{
                text-transform: uppercase;
                font-size: 1.2rem;
                font-weight: bold;
                font-size: 0.7rem;
                @include media-min ('tablet'){
                    margin: 20px 0px 60px;
                }
               
            }
            .cookieChoice {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin: 10px 0;

                @include media-min('tablet'){
                    text-align: justify;
                }
                .rgpdConfigure_autorization_text{
                    flex: 75%;
                    font-weight: bold;
                    margin-right: 1rem;
                    @include media-min ('tablet'){
                        font-size: 0.8rem;
                    }

                    .plusicon{
                        font-weight: bold;
                        margin-right: 5px;
                    }
                }
            }
        }
}