@import '../../../styles/mixin';

.registrationPortal__page {    background: linear-gradient(#090a0a,#212626);
  height: 100vh;
  overflow: auto;

  .containerRegistration {
    display: flex;
    flex-direction: column;
    background: linear-gradient(#090a0a,#212626);
    height: 100vh;
    overflow: auto;
    @media screen and (max-width: 767px) {
      background: url("../../../backgroundmobile.png") center  no-repeat;
      background-size: cover;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    &__storeBtn {
      margin-top: 20px;
      height: 70px;
      width: 80%;
      display: flex;
      justify-content: space-around;
      @include media-min ('tablet') {
          margin-bottom: 20px;
          width: 70%;
      }
      a {
          width: 45%;
          @include media-min ('tablet') {
              padding: 10px 0;
          }
          img {
              width: 100%;
              overflow: hidden;
          }
      }
    }
  }
  &__imagelogo {
    margin: 0 auto 5rem;
}



  .container__button {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 4rem;
    margin-bottom: 3rem;
    @include media-min ('tablet') {
        align-items: center;
        margin-bottom: 0;
        margin-top: 1.375rem;
        @media (min-width: 1280px) {
            margin-top: .70rem;
        }
    }
    .button__registrationPortal {
      background: linear-gradient(90deg,#B1D00E,#5FB101
      );
      border: none;
      border-radius: 2px;
      color: #fff;
      font-size: .6875rem;
      font-weight: 800;
      height: 33px;
      width: 220px;
      text-transform: uppercase;
      margin-bottom: 20px;
      @media screen and (max-width: 767px) {
          box-shadow: inset 1px 2px 3px rgba(0,0,0,0.5);
      }
      @include media-min ('tablet') {
          font-size: 1.125rem;
          height: 64px;
          width: 320px;
      }
  }
}
}