@import '../../../styles/mixin';

@media (max-width: 399px) {
    .artistHeader {
        .flex__row {
            display: flex;
            align-items: center;
            margin: 0 2rem;
            height: 160px;
            .small__image {
                width: 110px;
                height: 110px;
                border-radius: 3px;
                margin-left: 1rem;
            }
        }
        .back__arrow {
            top: 19px;
            left: 0;
            position: absolute;
            background-color: inherit;
            border: none;
            img {
                width: 35%;
            }
        }
        .artistFlexRow {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            font-family: 'Sans open', sans-serif;
            .container__description {
                font-size: 12px;
                height: 100%;
                margin-left: 1rem;
                display: flex;
                flex-direction: column;
                .artist__title {
                    font-size: 1rem;
                    font-weight: 800;
                    overflow: auto;padding: 0;
                }
                p {
                    font-weight: lighter;
                }
            }
        }
    }

    img.background__image {
        mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
        mask-size: 100%;
        left: 0;
        position: absolute;
        z-index: -1;
        width: 100%;
        top: 0;
        opacity: 0.5;
    }

    img.background__image__sticky {
        mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
        mask-size: 100%;
        left: 0;
        position: absolute;
        z-index: -1;
        height: fit-content;
        top: 0;
        opacity: 0.5;
        object-fit: cover;
        width: 100%;
    }
}


@media (min-width:400px) {
    .artistHeader {
        background: linear-gradient(to bottom, #272C2B, #000000);
        height: 30vh;
        z-index: 0;
        

        .background__opacity {
            padding: 2rem 1.7rem;
            .flex__row {
                display: flex;
                height: 28vh;
                position: relative;
                padding-top: 1rem;

                .small__image {
                    display: none;
                }

                .background__image {
                    margin-left: 9px;
                    height: 175px;
                    margin-top: 2vh;
                    border-radius: 5px;
                    overflow: hidden;
                }
            }
        }
        .background__opacity {
            .artistFlexRow {
                flex: 45%;
                padding: 1rem;
                .container__description {
                    margin: 0 2rem;
                    .artist__title {
                        font-size: 30px;
                        font-weight: 900;
                        padding: .5rem 0;
                    }
                }
                .back__arrow {
                    background: transparent;
                    border: none;
                    position: absolute;
                    left: 4px;
                    top: -15px;
                    img {
                        width: 12px;
                    }
                }
                h1 {
                    font-size: 28px;
                }
            }
        }
    }
    .artist-title-scroll {
        font-size: 28px;
    }
    .background__opacity {
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.8), black)
    }
}

@media (min-width:1024px) {
    .artistHeader {
        height: 24vh;
        .background__opacity {
            padding: 0 1.7rem;
            .flex__row {
                height: auto;
                padding-left: 2rem;
                .background__image {
                    height: 230px;
                    margin-top: 3.5vh;
                }
            }
            .artistFlexRow {
                .back__arrow {
                    left: 35px;
                    top: 20px;
                }
                .container__description {
                    padding-top: 1.1rem;
                    .artist__title {
                        font-size: 40px;
                    }
                    .artist__description {
                        margin-top: 1.5rem;
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .sticky__description{
        justify-content: start !important;
        padding: 2em;
    }
    .scroll__header__description {
        p:first-child {
            font-size: 17px;
        }

        p:nth-child(2) {
            font-size: 22px;
        }
    }
    .artist__title__scroll {
        width: 25%;
        font-size: 30px;
    }
}


@media (min-width:1440px) {
    .artistHeader {
        height: 30vh;
        .background__opacity {
            .flex__row {
                img.background__image {
                    height: 300px !important;
                }
                .artistFlexRow {
                    h1 {
                        font-size: 34px;
                    }
                    .back__arrow {
                        left: 5px;
                        img {
                            width: 15px;
                        }
                    }
                    .container__description {
                        margin-left: 4rem;
                        padding-top: 1.5rem;
                        .artist__title {
                            font-size: 45px;
                        }
                        .artist__description {
                            font-size: 21px;
                            margin-top: 3rem;
                        }
                    }
                }
            }
        }
    }
    .artist-title-scroll {
        width: 35%;
        font-size: 34px;
    }
}

@media (min-width:1680px) {
    .artist-title-scroll {
        width: 30%;
    }
}
.background__image,
.background__image__sticky {
    pointer-events: none;
}

@media (max-width:1024px) {
    .flex__row {
        height: 28vh;
    }
}