@import '../../../styles/mixin';

.container__miniplayers {
    //background-color:rgba(31, 29, 29, 0.89);
    background-color: #111;
    color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: size(10);
    font-weight: 400;
    height: 64px;
    max-height: 100%;
    padding-top: 0;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 56px;
    //font-family: 'Sans open', sans-serif;
    width: 100%;
    @include media-min ('tablet'){
        bottom: 0;
        height: 64px;
        //background-color: #111;
        //font-size: 10px;
    }
    @include media-min ('desktop-s') {
        font-size: size(12);
    }
    @include media-min ('desktop-m') {
        //height: 8%;
        font-size: size(14);
    }
    &.onboarding_player {
        display: none;
    }
    .link__audio__player {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        @include media-min ('tablet'){
            display: none;
        }
    }
    .cover_content {
        min-width: 64px;
        width: 64px;
        @include media-min ('tablet'){
            min-width: 112px;
            width: 112px;
        }
    }
    .cover_track, .cover_track_mobile {
        .picture__track {
            display: block;
            object-fit: cover;
            object-position: center;
            height: 100%;
            width: 100%;
        }
    }
    .cover_track {
        display: block;
        height: 100%;
        @media (max-width: 767px ) {
            display: none;
        }
    }
    .cover_track_mobile {
        display: none;
        @media (max-width: 767px ) {
            display: block;
        }
    }
    .player {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        .container__range {
            width: 100%;
            z-index: 2;
            margin: 0;
            position: relative;
            input[type=range].custom-slider {
                -webkit-appearance: none;
                padding: 0;
                margin: 0;
                outline: none;
                box-sizing: border-box;
                opacity: .8;
                height: 6px;
                width: 100%;
                position: absolute;
                background-color: #656565;
                overflow-y: hidden;
            }
            input[type='range']::-webkit-slider-runnable-track {
                height: 10px;
                -webkit-appearance: none;
                color: #13bba4;
                margin-top: -1px;
            }
            input[type='range']::-webkit-slider-thumb {
                width: 0px;
                -webkit-appearance: none;
                cursor: ew-resize;
                background: #434343;
                box-shadow: -800px 0 0 800px #7827eb;
            }
        }
        .custom-range {
            height: 4px;
        }
        .container_trackBar {
            position: absolute;
            height: 4px;
            width: 100%;
            z-index: 10;
        }
        .container__miniplayer__button {
            display: flex;
            position: relative;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            //margin-top: -1rem;
            padding: 4px 1rem 0;
            @include media-min ('desktop-s') {
                padding: 4px 2rem;
            }
            button {
                background-color: transparent;
                border: none;
                color: #fff;
                padding: 0;
                position: relative;
                z-index: 2;
            }
            p {
                line-height: 1.45;
                margin-bottom: 0;
            }
            img {
                height: 20px;
                //margin: 0 .5rem;
                width: 20px;
                @include media-min ('tablet'){
                    height: 22px;
                    //margin: .5rem;
                    width: 23px;
                }
                @include media-min ('desktop-s') {
                    height: 23px;
                    //margin: .8rem;
                    width: 23px;
                }
                @include media-min ('desktop-m') {
                    height: 27px;
                    width: 27px;
                }
            }
            .title {
                font-weight: 800;
                @media (max-width: 767px) {
                    max-height: 30px;
                    overflow: hidden;
                }
                &:empty {
                    display: none;
                }
            }
            .title + p {
                @media (max-width: 767px) {
                    max-height: 30px;
                    overflow: hidden;
                }
            }
        }
        .container__description__track {
            display: flex;
            flex-direction: row;
            align-items: center;
            @include media-min ('tablet'){
                //margin-left: 0;
               width: 155px; 
            }
            @include media-min ('desktop-s') {
                //margin-left: 40px;
                width: 250px;
            }
            @include media-min ('desktop-m') {
                // margin-left: 3rem;
                // font-size: 14px;
                width: 375px;
            }
            button {
                margin-right: size(13);
                @include media-min ('desktop-s') {
                    margin-right: size(28);
                }
                @include media-min ('desktop-m') {
                    margin-right: size(32);
                }
            }
            .explicit {
                background-color: rgba(211, 211, 211, 0.312);
                font-size: 10px;
                position: relative;
                text-align: center;
                font-weight: lighter;
                left: 5px;
                border-radius: 10%;
                padding: 1px 3px;
            }
        }
        .container__buttons {
            display: flex;
            flex-direction: row;
            @include media-min ('tablet') {
                max-width: 330px;
                width: 330px;
            }
            @include media-min ('desktop-m') {
                max-width: 355px;
                width: 355px;
            }
            button {
                margin: 0 size(16);
                @include media-min ('desktop-m') {
                    //margin: 0 size(22);
                }
            }
            .btn_repeatonce {
                position: relative;
                .no_repeatonce {
                    display: none;
                }
                .active_repeatonce {
                    background: #C8D900;
                    color: #fff;
                    width: 18px;
                    font-weight: bold;
                    height: 18px;
                    display: block;
                    border: .5px solid #fff;
                    border-radius: 50%;
                    position: absolute;
                    right: 12px;
                    top: 10px;
                }
            }
        }
        .container__miniplayer__vol {
            display: none;
            @include media-min ('tablet'){
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 100%;
                padding: 5px 0;
            }
            @include media-min ('desktop-s') {
                //margin-right: 7rem;
            }
            @include media-min ('desktop-l') {
                //margin-right: 1rem;
            }
            .volume_content {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            .container__range__vol {
                margin: 0 1rem;
                input[type="range"] {
                    margin: auto;
                    -webkit-appearance: none;
                    position: relative;
                    overflow: hidden;
                    height: 4px;
                    width: 100%;
                    cursor: pointer;
                    border-radius: 25px;
                }
                ::-webkit-slider-runnable-track {
                    background: #ddd;
                }
                ::-webkit-slider-thumb {
                    border-radius: 4px;
                    -webkit-appearance: none;
                    width: 1px;
                    height: 10px;
                    box-shadow: -100vw 0 0 100vw #3CAD00;
                    background: linear-gradient(270deg, #C8D900, #3CAD00);;
                    //background: #3CAD00;
                    //box-shadow: -100vw 0 0 100vw #3CAD00;
                    //box-shadow: inset -50vw 0 0 100vw #3CAD00, inset -100vw 0 0 100vw #C8D900 ;
                }
                ::-moz-range-track {
                    height: 40px;
                    background: #ddd;
                }

                ::-moz-range-thumb {
                    background: #fff;
                    height: 40px;
                    width: 5px;
                    border: 3px solid #999;
                    box-shadow: -100vw 0 0 100vw #3CAD00;
                    box-sizing: border-box;
                }

                ::-ms-fill-lower {
                    background: #13bba4;
                }
                ::-ms-thumb {
                    background: #fff;
                    border: 2px solid #999;
                    height: 40px;
                    width: 20px;
                    box-sizing: border-box;
                }
                ::-ms-ticks-after, ::-ms-ticks-before, ::-ms-tooltip {
                    display: none;
                }
                ::-ms-track {
                    background: #ddd;
                    color: transparent;
                    height: 40px;
                    border: none;
                }
            }
            .time {
                display: none;
                @include media-min ('desktop-s') {
                    color: #cacaca;
                    display: flex;
                    font-weight: 600;
                    margin-left: 1.25rem;
                    white-space: nowrap;
                }
                @include media-min ('desktop-m') {
                    top: .7rem;
                    right: 1rem;
                }
            }
            .inactif3 {
                display: none;
                @include media-min ('tablet'){
                    border-radius: 4px;
                    display: flex;
                    height: 4px;
                    width: 3rem;
                }
                @include media-min ('desktop-m') {
                    width: 6.5rem;
                }
            }
            button {
                padding: 0;
            }
            .min_vol {
                img {
                    height: 15px;
                    width: 12px;
                }
            }
            .max_vol {
                img {
                    height: 20px;
                    width: 27px;
                }
            }
        }
        .inactif {
            display: none;
            @include media-min ('tablet'){
                display: flex;
            }
        }
    }
    .inactif {
        display: none;
        @include media-min ('tablet'){
            display: flex;
        }
    }
    .superposition__picture__miniplayer {
        display: none;
        @include media-min ('desktop-m') {
            display: block;
            height: 100%;
        }
        .picture__miniplayer {
            margin: 0;
            height: 100%;
            position: relative;
            width: 225px;
        }

        img {
            display: block;
            object-fit: cover;
            object-position: center;
            height: 100%;
            width: 100%;
        }
        a {
            color: #fff;
            display: block;
            font-size: 15px;
            font-weight: 800;
            text-transform: uppercase;
            height: inherit;
            width: inherit
        }
        .text {
            display: block;
            position: absolute;
            text-align: center;
            text-shadow: 0 0 10px #000;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            z-index: 1;
        }

    }
}