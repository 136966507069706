@import '../../../styles/mixin';

.playlistHeader {
  position: relative;
  width: 100%;
  z-index: 1;
  .topTracks_container {
    padding: 1.25rem 3rem 2em;
    transition: all .10s ease-in-out;
    @include media-min ('tablet') {
      background: linear-gradient(180deg, #272C2B, #020202);
      height: 256px;
      padding: 4rem 2rem 2em;
    }
    @include media-min ('desktop-s') {
      height: 320px;
      padding: 4rem 3rem 4rem 4rem;
    }
    @include media-min ('desktop-m') {
      height: 416px;
    }
    @include media-min ('desktop-l') {
      padding: 4rem 6rem;
    }
  }
  .fav_container {
    background: linear-gradient(180deg, #272C2B, #020202);
    height: 110px;
    padding: size(22) 3rem size(41);
    margin-bottom: size(40);
    @include media-min ('tablet') {
      height: 192px;
      margin-bottom: 0;
      padding: 4rem 2rem 2rem;
    }
    @include media-min ('desktop-s') {
      padding: 4rem 4rem 2rem;
    }
    @include media-min ('desktop-m') {
      padding: size(36) 4rem;
      max-width: 1086px;
    }
    @include media-min ('desktop-l') {
      padding-left: 6rem;
      padding-right: 6rem;
    }
    .bgFav {
      position: absolute;
      top: 0; left: 0;
      height: 100%;
      width: 100%;
      z-index: 0;
      img {
        object-fit: cover;
        object-position: center top;
        width: 100%;
        height: 100%;
      }
    }
    .backArrow {
      left: .875rem;
      top: .875rem;
    }
    .topTracks_content {
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      position: relative;
      z-index: 1;
    }  
    .topTracks_desc {
      display: flex;
      flex-direction: column;
      margin: auto;
      @include media-min ('tablet') {
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        //justify-content: space-between;
        margin-left: 0;
        width: 100%;
      }
    }
    .playlist-title {
      font-family: "Permanent Marker";
      font-weight: 400;
      font-size: size(20);
      height: 50px;
      overflow: hidden;
      text-align: center;
      text-overflow: ellipsis;
      margin-bottom: -0.5rem;
      @include media-min ('tablet') {
        font-size: size(30);
        max-height: 75px;
        height: auto;
        margin-right: 3rem;
        margin-bottom: 0;
        text-align: left;
      }
      @include media-min ('desktop-s') {
        font-size: size(40);
        margin-right: 4rem;
      }
      @include media-min ('desktop-m') {
        font-size: size(60);
      }
      @include media-min ('desktop-l') {
      }
    }
    .playlistPlayButton {
      @include media-min ('tablet') {
        margin-right: 0;
      }
    }
    .desc_content {
      align-items: center;
    }
    .desc_text {
      margin: 0;
      .time {
        display: none;
      }
    }
  }
  .backArrow {
    background: none;
    border: none;
    //height: 13px;
    padding: 0;
    position: absolute;
    left: 1rem; top: 1.25rem;
    //left: -2rem; top: -4px;
    z-index: 1;
    width: 30px;
    @include media-min ('tablet') {
      left: 2rem; 
      top: 1.5rem;
      //width: 16px;
    }
    @include media-min ('desktop-m') {
      left: 4rem;
    }
    @include media-min ('desktop-m') {
      left: 2rem;
    }
    @include media-min ('desktop-l') {
      left: 2.5rem; 
    }
    img {
      height: 13px;
      @include media-min ('tablet') {
        height: 16px;
      }
    }
  }
  .topTracks_content {
    display: flex;
    //flex-direction: column;
    justify-content: space-between;
    align-items: center;
    @media screen and (min-width: 600px) {
      //align-items: center;
      max-width: 70%;
    }
    @include media-min ('tablet') {
      justify-content: start;
      flex-direction: row;
      max-width: 100%;
    }
  }
  .topTracks_cover {
    background: #597f02;
    border-radius: 5px;
    margin: 0;
    height: 158px;
    min-width: 158px;
    position: relative;
    width: 158px;
    @media screen and (max-width: 767px) {
      display: none;
      position: fixed;
      top: 0; left: 0; right: 0;
      width: 100%; height: 100%;
      z-index: -1;
    }
    @include media-min ('tablet') {
      height: 158px;
      min-width: 158px;
      width: 158px;
    }
    @include media-min ('desktop-s') {
      height: 224px;
      min-width: 224px;
      width: 224px;
    }
    @include media-min ('desktop-m') {
      height: 288px;
      min-width: 288px;
      width: 288px;
    }
    .backgroundImage, .backgroundImageSticky {
      border-radius: inherit;
      display: block;
      height: 100%;
      object-fit: cover;
      object-position: center top;
      width: 100%;
    }
    .flag {
      height: 15px;
      @include media-min ('tablet') {
        height: 34px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
      }
      @include media-min ('desktop-s') {
        height: 48px;
      }
      @include media-min ('desktop-m') {
        height: 61px;
      }
    }
  }
  .playlist-title {
    color: #fff;
    font-size: size(12);
    font-weight: 800;
    margin-bottom: .625rem;
    text-transform: none;
    //text-transform: lowercase;
    @include media-min ('tablet') {
      font-size: size(30);
    }
    @include media-min ('desktop-s') {
      font-size: size(40);
      margin-bottom: 2rem;
    }
    @include media-min ('desktop-m') {
      font-size: size(50);
    }
    @include media-min ('desktop-l') {
    }
    .flag {
      width: 32px;
      margin-right: 10px;
      @include media-min ('tablet') {
        display: none;
      }
    }
  }
  .playlistPlayButton {
    //display: none;
    background: linear-gradient(270deg, #B1D00E, #5FB101);
    border: none;
    border-radius: 2px;
    color: #fff;
    font-size: size(11);
    font-weight: 800;
    height: 37px;
    //margin: 0 auto;
    padding: 0;
    text-transform: uppercase;
    //max-width: 224px;
    width: 100%;
    @include media-min ('tablet') {
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 3px 4px rgba(0,0,0,0.78);
      border-radius: 50%;
      height: 4rem;
      justify-content: space-around;
      min-width: 4rem;
      margin-right: 2rem;
      padding-left: 5px;
      width: 4rem;
    }
    @include media-min ('desktop-s') {
      height: 6rem;
      min-width: 6rem;
      width: 6rem;
    }
    @include media-min ('desktop-l') {
     margin-right: 4rem;
    }
    img {
      width: 15px;
      @include media-min ('tablet') {
        width: 30px;
      }
    }
    span {
      padding-left: 10px;
      @include media-min ('tablet') {
        display: none;
      }
    }
  }
  .topTracks_desc {
    @include media-min ('tablet') {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 2rem;
    }
    @include media-min ('desktop-m') {
      margin-left: 4rem;
    }
  }
  .container__icons {
    display: flex;
    flex-direction: row;
    .playlistPlayButtonMobile {
      background: linear-gradient(270deg, #B1D00E, #5FB101);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      border: none;
      padding: 0;
      img {
        width: 12px;
        vertical-align: middle;
        margin-bottom: 3px;
        margin-left: 2px;
      }
      @include media-min ('tablet') {
        display: none;
      }
    }
      .likeIcon {
        //margin-top: 6rem;
        margin: auto;
        .buttonHeart {
          //display: none;
          background-color: transparent;
          border: none;
        }
      }
  }

  .desc_content {
    display: flex;
    flex-direction: column;
    @include media-min ('tablet') {
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-end;
    }

  }
  .desc_text {
    margin-bottom: 8px;
    width: 70vw;
    @include media-min ('tablet') {
      margin-bottom: 0;
      width: auto;
    }
    p {
      color: #fff;
      font-weight: 600;
      line-height: 1.2;
      margin: 0;
    }
    .text {
      font-size: size(10);
      margin-bottom: 5px;
      @include media-min ('tablet') {
        font-size: size(12);
        margin-bottom: 1rem;
      }
      @include media-min ('desktop-s') {
        font-size: size(14);
        margin-bottom: size(12);
      }
      @include media-min ('desktop-m') {
        font-size: 1rem;
      }
    }
    .time {
      color: #cacaca;
      font-size: size(8);
      @include media-min ('tablet') {
        color: #fff;
        font-size: size(14);
        font-weight: 800;
      }
    }
  }
  &.sticky-top-sticky {
    position: sticky;
    top: 0;
    width: 100%;
    @media (min-width: 600px) {
    }
    @include media-min ('tablet') {
    }
    @include media-min ('desktop-s') {
    }
    .backArrow {
      /* left: 0.75rem;
      top: 0.75rem;
      @media (min-width: 600px) {
        top: 1.5rem;
        left: 1.75rem;
      }
      @include media-min ('tablet') {
        top: .25rem;
        left: 2rem;
      }
      @include media-min ('desktop-s') {
        top: 1rem;
        left: 4rem;
      } */
    }
    .playlistPlayButton {
      display: none;
      @include media-min ('tablet') {
        display: inline;
      }
    }

    .fav_container {
      .topTracks_desc {
        flex-direction: row;
        max-width: 90%;
        .playlist-title {
          padding-top: 0.5rem;
          @include media-min ('tablet') {
            padding-right: 0;
            padding-top: 0;
            font-size: size(30);
            max-width: 70%;
          }
          @include media-min ('desktop-s') {
            font-size: size(40);
           
          }
          @include media-min ('desktop-m') {
            padding-right: 0;
            font-size: size(50);
          }
        }
        .desc_content {
          position: relative;
          left: 5%;
          width: 45px;
          @include media-min ('tablet') {
            position: static;
            left: 0;
          }
          .playlistPlayButtonMobile {
            position: relative;
            left: 10%;
            background: linear-gradient(270deg, #B1D00E, #5FB101);
            border-radius: 50%;
            width: 30px;
            height: 30px;
            border: none;
            padding: 0;
            @include media-min ('tablet') {
              display: none;
            }
            img {
              width: 12px;
              vertical-align: middle;
              margin-bottom: 3px;
              margin-left: 2px;
            }
          }
        }
      }
    }
    .topTracks_container {
      background: linear-gradient(180deg, #272C2B, #020202);
      //padding: .75rem 1rem .75rem 2rem;
      padding: 1.25rem 1rem 1em; //  1.25 - 3 - 2
      @media (min-width: 600px) {
        padding-bottom: 1.5rem;
        padding-top: 1.5rem;
        padding-left: 4rem;
      }
      @include media-min ('tablet') {
        height: 160px;
        padding: 4rem 1rem 2rem 2rem;
      }
      @include media-min ('desktop-s') {
        height: 192px;
        padding: 3.5rem 2rem 2rem 4rem;
      }
      @include media-min ('desktop-l') {
        padding: 4rem 6rem 2rem;
      }
    }
    .topTracks_cover {
      display: none;
    }
    .topTracks_desc {
      margin-left: 10%;
      max-width: 65%;
      @include media-min ('tablet') {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        margin-left: 0;
        max-width: 100%;
      }
    }
    .playlist-title {
      font-size: 1.25rem;
      @media (min-width: 600px) {
        font-size: size(25);
        margin-bottom: 2rem;
      }
      @include media-min ('tablet') {
        margin-bottom: 0;
        //max-width: 45%;
        padding-right: 2rem;
      }
      @include media-min ('desktop-s') {
        //max-width: 45%;
      }
      @include media-min ('desktop-m') {
        font-size: size(34);
        padding-right: 4rem;
      }
    }
    .topTracks_content {
      max-width: 100%;
      justify-content: space-between;
      .playlistPlayButton {
        margin-left: 0;
      }
    }
    .desc_content {
      display: flex;
      //flex-direction: row-reverse;
      //justify-content: flex-end;
      @include media-min ('tablet') {
        align-items: center;
        max-width: 60%;
      }
      .playlistPlayButton {
        //border-radius: 50%;
        //height: 2rem;
        //justify-content: space-around;
        //width: 2rem;
        @include media-min ('tablet') {
          height: 4rem;
          width: 4rem;
        }
        @include media-min ('desktop-s') {
          height: 6rem;
          width: 6rem;
        }
        @include media-min ('desktop-m') {
          margin-right: 4rem;
        }
        // span {
        //   display: none;
        // }
      }
    }
    .desc_text {
      margin-bottom: .5rem;
      @include media-min ('tablet') {
        margin-left: 0;
        margin-bottom: 0;
      }
      p {
        line-height: 1.4;
      }
    }
    &.topTracks {
      .playlist-title {
        .flag {
          display: inline-flex;
          
          
          @include media-min ('desktop-s') {
            padding-right: 0.5rem;
            width: 64px;
          }
        }
      }
      .topTracks_desc {
        @include media-min ('tablet') {
          //justify-content: space-between;
        }
      }
      .desc_content {
        @include media-min ('tablet') {
          //width: 50%;
        }
      }
    }
  }
}

// style only for top tracks header
.topTracks {
  .topTracks_container {
    display: flex;
    flex-direction: row;
    @include media-min ('tablet') {
      height: 256px;
    }
    @include media-min ('desktop-s') {
      height: 320px;
    }
    @include media-min ('desktop-m') {
      height: 416px;
    }
    .topTracks_content {
      flex-direction: column;
      @include media-min ('tablet') {
       flex-direction: row;
      }
    }
    .playlistPlayButtonMobile {
      background: linear-gradient(270deg, #B1D00E, #5FB101);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      border: none;
      padding: 0;
      @include media-min ('tablet') {
        display: none;
      }
      img {
        width: 12px;
        vertical-align: middle;
        margin-bottom: 3px;
        margin-left: 2px;
      }
    }
  }
}

// style only for fav tracks header
.fav_container {
  height: 110px;
  padding: size(22) 3rem size(41);
  @include media-min ('tablet') {
    height: 192px;
    margin-bottom: 0;
    padding: 4rem 2rem 2rem;
  }
  @include media-min ('desktop-s') {
    padding: 4rem 4rem 2rem;
  }
  @include media-min ('desktop-m') {
    padding: size(36) 4rem;
    max-width: 1086px;
  }
  @include media-min ('desktop-l') {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  .playlist-title {
    font-size: size(20);
    @include media-min ('tablet') {
      font-size: size(30);
      margin-right: 0;
      max-width: 100%;
    }
    @include media-min ('desktop-s') {
      font-size: size(40);
      margin-right: 2rem;
    }
    @include media-min ('desktop-m') {
      font-size: size(60);
    }
  }
  .playlistPlayButton {
    display: inline;
    border-radius: 2px;
    justify-content: center;
    height: 37px;
    max-width: 224px;
    width: 100%;
    @include media-min ('tablet') {
      border-radius: 50%;
      height: 4rem;
      min-width: 4rem;
      width: 4rem;
    }
    @include media-min ('desktop-s') {
      height: 6rem;
      min-width: 6rem;
      width: 6rem;
    }
    @include media-min ('desktop-m') {
      margin-right: 0;
     }
    span {
      display: inline-flex;
      @include media-min ('tablet') {
        display: none;
      }
    }
  }
  .topTracks_content {

  }
  .topTracks_desc {
    margin-left: 0;
    @include media-min ('tablet') {
      //justify-content: space-between;
    }
  }
  .desc_content {
    flex-direction: column;
  }
}


