@import '../../../styles/mixin';

.container_changeLanguage_page {
    @media screen and (max-width: 767px) {
        background: url("../../../backgroundmobile.png") center  no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
    }
    @include media-min ('tablet') {
        overflow: hidden;
        //background: linear-gradient(#090a0a,#212626);
        // @media (orientation: landscape) {
        //     overflow: auto;
        // }
    }
    .overlay {
        color: lightgray;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        flex-wrap: wrap;
        flex-direction: column;
        text-align: center;
        background: url('../../../../public/assets/images/bg-maintenance.png') center  no-repeat;
        background-size: 100%;
        background-position: bottom;

        .overlay__btn {
            position: absolute;
            top: 5px;
            right: 7px;
            color: lightgray;
            padding: 10px;
            background-color: black;
            border: none;
            width: 11vw;
            height: 7vh;
            font-size: 25px;
            font-weight: bold;
            @media (orientation: landscape) {
                width: 5vw;
                height: 10vh;
                font-size: 20px;
                padding: 0;
            }
        }

        .overlay__informations {
            font-size: 21px;
            @media (orientation: landscape) {
                position: relative;
                top: -32%;
            }
            img {
                margin-bottom: 30%;
                @media (orientation: landscape) {
                    margin-bottom: 80px;
                }
            }
            .overlay__info {
                margin-bottom: 40%;
                @media (orientation: landscape) {
                    margin-bottom: 0;
                }
            }
            .overlay__logo {
                background: url("../../../sprite.png") no-repeat top left;
                width: 35px;
                height: 35px;
                vertical-align: text-bottom;
                display: inline-block;
                background-size: cover;
            }
        }
        
        .overlay__pointer {
            background: url("../../../sprite.png") no-repeat top left;
            background-size: cover;
            background-position: center -40px;
            animation-duration: 0.5s;
            width: 35px;
            height: 35px;
            animation-name: topToBottom;
            animation-iteration-count: infinite;
            animation-direction: alternate;
            margin-bottom: 1rem;
            @include media-min ('tablet') {
                position: absolute;
                top: 2%;
                right: 14.75%;
                animation-name: bottomToTop;
            }
            @media (orientation: landscape) {
                position: absolute;
                top: 2%;
                right: 12.25%;
                animation-name: bottomToTop;
                
            }
        }
        @keyframes topToBottom {
            0% {
                transform: translate(0, 0);
            }
            100% {
                transform: translate(0, 20px);
            }
        }
    
        @keyframes bottomToTop {
            0% {
                transform: translate(0, 0) rotate(180deg);
            }
            100% {
                transform: translate(0, 20px) rotate(180deg);
            }
        }
    }

    .container_changeLanguage {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        position: absolute;
        left: 0; top: 0;
        width: 100%;
        .link_change_language {
            color: #fff;
            text-align: center;
            display: inline-flex;
            font-size: 8px;
            font-weight: 600;
            height: 100%;
            margin: 0;
            text-align: center;
            @media screen and (max-width: 767px) {
                color: #cacaca;
                padding: 15px 12px 12px;
            }
            @include media-min ('tablet') {
                font-weight: normal;
                font-size: 15px;
                height: 100%;
                padding: 20px;
            }
        }
    }
    .imagelogo.country {
        @media screen and (max-width: 767px) {
            display: none;
            // @media (orientation: landscape) {
            //     margin-top: 1rem;
            // }
        }
        @include media-min ('tablet') {
            // width: 160px;
            // height: 95px;
            // margin: 2rem auto;
        }
    }
    .container__contryselect {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        @media screen and (max-width: 767px) {
            background: url("../../../backgroundmobile.png") center  no-repeat;
            background-size: cover;
            height: 100vh;
            font-size: size(32);
        }
        @include media-min ('tablet') {
            //height: 80%;
            color: #fff;
            //padding-bottom: 7rem;
            margin: auto;
            text-align: center;
        }
        @media (min-width: 1224px) {
            justify-content: center;
            align-items: center;
            padding-bottom: 0;
            padding-top: 0;
        }
        .main_content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            @include media-min('tablet'){
                overflow: hidden;
            }
        }
        .imagelogomobile {
            height: auto;
            width: 52px;
            margin: 0 auto;
            @media screen and (max-width: 767px) {
                @media (orientation: landscape) {
                    margin-top: .5rem;
                    margin-bottom: 0;
                }
            }
            @include media-min ('tablet') {
                display: none; 
            }
        }
        .title__page {
            line-height: 1.25rem;
            margin-bottom: size(24);
            margin-top: size(60);
            text-align: center;
            @include media-min ('tablet') {
                font-size: size(30);
                line-height: 1.6rem;
                margin-bottom: size(64);    
            }
        }
        .wrapper__contry {
            max-width: 100vw;
            overflow: hidden;
            width: 100%;
            @include media-min ('desktop-s') {
                padding-left: 2rem;
                padding-right: 2.25rem;
                max-width: 1000px;
                margin: 0 auto;
            }
            @include media-min ('desktop-m') {
                padding-right: 2.25rem;
            }
        }
        .container__contry {
            display: flex;
            align-items: center;
            scroll-behavior: smooth;
            @media screen and (min-width: 1025px) {
                &::-webkit-scrollbar {
                    height: 8px;
                }
                &::-webkit-scrollbar-track {
                    background-color: rgba(60, 60, 60, 1);
                    opacity: 0;
                    border-radius: 10px;
                }
                &::-webkit-scrollbar-thumb {
                    background-color: rgba(190, 190, 190, 1);
                    opacity: .5;
                    border-radius: 10px;
                }
            }
            @media screen and (max-width: 767px) {
                flex-direction: column;
                max-height: 35vh;
                margin: 0 auto 2rem;
                overflow: auto;
                max-width: 230px;
                width: 100%;
            }
            @include media-min ('tablet') {
                //padding-right: 2.2rem !important;
                //margin-top: 2rem;
                padding-left: 2rem;
                overflow-x: auto;
                overflow-y: hidden;
                margin: auto;
                padding-bottom: 10px;
                width: 100%;
            }
            @include media-min ('desktop-s') {
                padding-left: 0;
            }
            // @include media-min ('desktop-s') {
            //     align-items: center;
            // }
            @include media-min ('desktop-m') {
                //justify-content: center;
                //overflow: hidden;
                //width: 90%;
            }
            // @include media-min ('desktop-l') {
            //     //width: 80%;
            // }
            &:after {
                content: "";
                height: 176px;
                min-width: 1px;
                @media screen and (max-width: 767px) {
                    display: none;
                }
                @include media-min ('tablet') {
                    display: block;
                }
                @include media-min ('desktop-s') {
                    display: none;
                }
            }
            .one__pays {
                background: transparent;
                border: none;
                box-sizing: border-box;
                color: #fff;
                padding: 0;
                position: relative;
                @media screen and (max-width: 300px) {
                    width: 80%;
                    height: 30px;
                }
                @media screen and (max-width: 767px) {
                    display: flex;
                    align-items: center;
                    margin-top: 1rem;
                    //width: 60%;
                    width: 100%;
                    min-height: 20px;
                    &:first-child {
                        margin-top: 0;
                    }
                    /* @media (orientation: landscape) {
                        flex-direction: column;
                        margin-top: 0;
                        width: 65px;
                        height: 100px;
                        margin-right: .6rem;
                    } */
                }
                @include media-min ('tablet') {
                    height: 176px;
                    margin-right: 2rem;
                    position: relative;
                    //margin-right: 0.8rem;
                    //margin-top: .5rem;
                    width: 128px;
                    //width: 100%;
                }
                // @include media-min ('desktop-s') {
                //     height: 182px;
                //     width: 128px;
                //     margin-right: 1.15rem;
                // }
                // @media (min-width: 1224px) {
                //     height: 182px;
                //     margin-right: 1.15rem;
                //     width: 128px;
                // }
                .image__contry {
                    height: 20px;
                    margin-right: size(24);
                    width: 34px;
                    @media screen and (max-width: 767px) {
                        // @media (orientation: landscape) {
                        //     width: 30px;
                        //     height: 18px;
                        //     position: absolute;
                        //     left: 27%;
                        //     top: 25%;
                        // }
                    }
                    @include media-min ('tablet') {
                        width: 68px;
                        height: 40px;
                        position: absolute;
                        top: 44px;
                        left: 50%;
                        transform: translate(-50%, 0);
                    }
                }
                .cover {
                    @media screen and (max-width: 767px) {
                        display: none;
                        /* @media (orientation: landscape) {
                            width: 60px;
                            height: auto;
                            border-radius: 5px;
                            display: block;
                            filter: brightness(60%);
                        } */
                    }

                    @include media-min ('tablet') {
                        box-shadow: 0px 2px 4px rgba($color: (#000000), $alpha: .5);
                        border-radius: 5px;
                        height: 128px;
                        width: 128px;
                        //height: auto;
                        filter: brightness(60%);
                    }
                }
                input[type=radio] {
                    @media screen and (max-width: 767px) {
                        height: 16px;
                        margin-right: 1.25rem;
                        min-width: 16px;
                        width: 16px;
                        /* @media (orientation: landscape) {
                            display: none;
                        } */
                    }
                    @include media-min ('tablet') {
                        display: none;
                    }  
                }
                p {
                    color: #fff;
                    font-size: .75rem;
                    font-weight: 600;
                    margin: 0;
                    text-align: left;
                    @media screen and (max-width: 767px) {
                        /* @media (orientation: landscape) {
                            font-size: .5rem;
                            padding-top: .5rem;
                            text-transform: uppercase;
                        } */
                        white-space: nowrap;
                        overflow: hidden;
                    }
                    @include media-min ('tablet') {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: .8125rem;
                        font-weight: 800;
                        line-height: 1.1em;
                        margin-top: 1rem;
                        min-height: 2rem;
                        text-align: center;
                        text-transform: uppercase;
                    }
                }
                .selectedTitle {
                    color: #67C100;
                }
            }
            .selected {
                /* @media (orientation: landscape) {
                    background: linear-gradient(to top, #3CAD00, #C8D900 );
                    padding: 2px;
                    filter: brightness(100%) !important;
                } */
                @include media-min ('tablet') {
                    background: linear-gradient(to top, #3CAD00, #C8D900 );
                    padding: 6px;
                    filter: brightness(100%) !important;
                }
            }   
        }
        .container__errormsg {
            text-align: center;
            color: #ff0000;
            font-size: 1rem;
            font-family: 'Open Sans';
            font-weight: 900;
            margin-top: -1rem;
            margin-bottom: 0.5rem;
            /* @media (orientation: landscape) {
                margin-top: .5rem;
                margin-bottom: 1rem;
            } */

            @include media-min ('tablet') {
                margin-top: -3rem;
                margin-bottom: 0.7rem;
                font-size: 24px;
                font-weight: bold;
                color: #ff0000;
            }
        }
        .container__button {
            display: flex;
            justify-content: center;
            //margin-bottom: 3.5rem;
            /* @media (orientation: landscape) {
                margin-bottom: .5rem;
                padding-top: 1rem;
            } */
            @include media-min ('tablet') {
                align-items: center;
                margin-bottom: 0;
                margin-top: 1.375rem;
                @media (min-width: 1280px) {
                    margin-top: .70rem;
                }
            }

            .button__selectcontry {
                background: linear-gradient(90deg, #5FB101, #B1D00E);
                border: none;
                border-radius: 2px;
                color: #fff;
                font-size: .6875rem;
                font-weight: 800;
                height: 40px;
                width: 156px;
                text-transform: uppercase;
                @media screen and (max-width: 767px) {
                    box-shadow: inset 1px 2px 3px rgba(0,0,0,0.5);
                }
                @include media-min ('tablet') {
                    background: linear-gradient(270deg, #B1D00E, #5FB101);
                    border-radius: 50px;
                    font-size: 1.125rem;
                    height: 64px;
                    width: 320px;
                }
                .button__selectcountry-active {
                    opacity: 1;
                }
                .button__selectcountry-inactive {
                    opacity: .6;
                }
                &:focus, &:hover {
                    span {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

