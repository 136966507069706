@import '../../../styles/mixin';

.container_onboarding_genres {
  display: flex;
  flex-direction: column;
  font-family: "Open Sans";
  color: #fff;
  padding: 3vh;
  background: linear-gradient(to top, #212626, #090a0a);
  .active-filters {
    .filter-choice {
      position: relative;
      top: 2px;
      z-index: 2;
      width: 100%;
      margin-left: 7px;
      display: flex;
      flex-wrap: wrap;
      @include media-min ('tablet') {
        width: inherit;
        top: 5px;
      }
      @media (max-width: 767px) {
        justify-content: space-between;
      }
      .delete-btn-mobil {
        background-color: transparent;
        margin-right: 37px;
        @include media-min ('tablet') {
          display: none;
        }
        img {
          width: 22px;
        }
      }
      .flex.mobile {
        @media (max-width: 767px) {
          width: 93%;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
      }
      button {
        text-transform: uppercase;
        color: white;
        background-color: grey;
        padding: 10px 5px 10px 5px;
        border: none;
        border-radius: 3px;
        width: max-content;
        margin-bottom: 4%;
        font-size: 13px;
        margin-right: 15px;
        display: flex;
        justify-content: space-between;
        height: 40px;

        p:first-child {
          margin-right: 15px;
        }
      }
    }
  }

  .bg-onboarding-genres {
     display: flex;
     flex-direction: column;
     justify-content: center;
    .onboarding-genres-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 5vh;
      h1 {
        font-size: 1.1rem;
        font-weight: bold;
        padding-bottom: 2vh;
        padding-top: 4vh;
        @include media-min ('tablet') {
          font-size: 2.5rem;
        }
        @include media-min ('desktop-s') {
          font-size: 2.7rem;
          padding-top: 4vh;
        }
        @include media-min ('desktop-l') {
          font-size: 2.7rem;
          padding-top: 0vh;
        }
      }
      p {
        font-size: 1rem;
        font-weight: bold;
        padding-bottom: 2vh;
        @include media-min ('tablet') {
          font-size: 1.5rem;
        }
      }
      .filter-choice p{
        font-size: 1rem;
      }
      .genre {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        @media (max-width: 767px) {
          padding-bottom: 2vh;
        }
        @include media-min ('tablet') {
          width: 95%;
        }
        @include media-min ('desktop-s') {
          width: 90%;
        }
        @include media-min ('desktop-m') {
          width: 85%;
        }
        @include media-min ('desktop-l') {
          width: 80%;
        }
        button {
          width: 25vw;
          height: 7vh;
          margin: 2vw;
          border-radius: 3px;
          border-width: 1px;
          font-size: 11px;
          color: #fff;
          font-weight: bold;
          text-transform: uppercase;
          @include media-min ('tablet') {
            font-size: 1.3rem;
            width: 25vw;
            height: 9vh;
          }
          @include media-min ('desktop-s') {
            width: 19vw;
            height: 90px;
            margin: 1vw;
          }
          @include media-min ('desktop-m') {
            width: 14vw;
            height: 100px;
          }
          @include media-min ('desktop-l') {
            width: 13vw;
            height: 110px;
          }
        }
      }
      .btn-genres {
        height: 50px;
        width: 60vw;
        color: #fff;
        border-radius: 3px;
        border: none;
        background: linear-gradient(to right, #3cad00, #c8d900);
        text-transform: uppercase;
        font-size: 0.9rem;
        font-weight: 700;
        margin-top: 4vh;
        margin-bottom: 2vh;
        @include media-min ('tablet') {
          border-radius: 50px;
          width: 40vw;
          height: 70px;
        }
        @include media-min ('desktop-s') {
          width: 30vw;
          height: 75px;
          font-size: 1rem;
        }
        @include media-min ('desktop-m') {
          height: 80px;
          font-size: 1.2rem;
        }
        @include media-min ('desktop-l') {
          height: 85px;
          font-size: 1.3rem;
        }
      }
    }
  }
}
