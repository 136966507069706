@import "../../../styles/mixin";

.container_settings {
  background: linear-gradient(#090a0a, #212626);
  color: #fff;
  display: flex;
  flex-direction: column;
  //min-height: 100vh;
  font-size: 1rem;
  //font-family: "Sans open", sans-serif;
  //margin: 15px;
  @include media-min("tablet") {
    //padding-left: 20px;
    //margin: 0 0 0 21% ;
  }
  @include media-min("desktop-s") {
    //padding-top: 5rem;
    //padding-bottom: 95px;
  }
  a {
    color: white;
    padding-left: 1rem;
    text-decoration: none;
    @include media-min("tablet") {
      padding-left: 40px;
    }
    button {
      color: white;
      padding: 0;
      border: none;
      background-color: transparent;
    }
  }
  h1 {
    //color: white;
    //font-family: "Open Sans";
    // margin-top: 30px;
    // margin-bottom: 50px;
    //font-size: 20px;
    // font-weight: bold;
    // text-align: center;
    @include media-min("tablet") {
      // font-size: 32px;
      // padding-bottom: 1.5rem;
      // padding-left: 40px;
      // padding-right: 20px;
      // font-weight: 700;
      // text-align: left;
    }
  }
    a {
      display: flex;
      margin-bottom: 3vw;
      justify-content: space-between;
      width: 100%;
      font-weight: 600;
      font-size: 0.8rem;
      @include media-min("tablet") {
        padding-right: 20px;
      }
    }
    .image-button {
      @include media-min("desktop-s") {
        width: 10px;
      }
      @include media-min("desktop-m") {
        width: 11px;
      }
      @include media-min("desktop-l") {
        width: 12px;
      }
    }
    a:hover {
      color: #fff;
      text-decoration: none;
    }
  .settings_onboarding {
    p {
      font-size: 12px;
      @include media-min("desktop-m") {
        font-size: 14px;
      }
      @include media-min("desktop-l") {
        font-size: 16px;
      }
    }
  }
  .settings_subscription {
      margin-bottom: 3vw;
      justify-content: space-between;
      width: 100%;
      font-weight: 600;
      font-size: 0.8rem;
      @include media-min("tablet") {
        padding-right: 20px;
      }
      p {
        color: white;
        padding-left: 1rem;
        @include media-min("tablet") {
          padding-left: 40px;
        }
      }
      .subscription {
        display: flex;
        justify-content: space-between;

        p {
          font-weight: 200;
        }
        button {
          background-color: transparent;
          border: none;

          p{
            color:  #3CAD00;
          }
        }
        .date{
          display: flex;
          flex-direction: row;
          p:first-child {
             padding-left: 1rem;
          }
          p {
            padding: 0;
          }
        }
    }
      }
  }
