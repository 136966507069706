@import '../../../styles/mixin';


.container_change_language {
    @media screen and (max-width: 767px) {
        background: url("../../../backgroundmobile.png") center  no-repeat;
        background-size: cover;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'Open Sans';
        padding-bottom: 5rem;
        color: #fff;
    }

    @include media-min ('tablet') {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-family: 'Open Sans';
        background: linear-gradient(#090a0a,#212626);
    }

    @include media-min ('desktop-s') {
        padding-bottom: 10rem;
        padding-top: 6rem;
    }

    @media (min-width: 1224px) {
        padding-bottom: 0;
        padding-top: 0;
        justify-content: center;
        align-items: center;
    }

    .logoI {
        @media screen and (max-width: 767px) {
            display: none;
        }
        @include media-min ('tablet') {
            width: 160px;
            height: 95px;
            margin: 2rem auto;
        }
    }
    .logomobileI {
        @media screen and (max-width: 767px) {
            margin-top: 2rem;
            margin-left: auto;
            margin-right: auto;
        }
        @include media-min ('tablet') {
            display: none;
        }
    }

    .title_change_language {
        @media screen and (max-width: 767px) {
            text-align: center;
            color: #fff;
            font-size: 1rem;
            font-family: 'Open Sans';
            font-weight: 900;
            margin-top: 3rem;
            margin-bottom: 2rem;
        }
        @include media-min ('tablet') {
            text-align: center;
            color: #fff;
            font-size: 24px;
            font-family: 'Open Sans';
            font-weight: 900;
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
        @include media-min ('desktop-s') {
            margin-top: 6rem;
            margin-bottom: 1.5rem;
            font-size: 26px;
        }
        @media (min-width: 1224px) {
            margin-top: 5rem;
            margin-bottom: 1.5rem;
            font-size: 26px;
        }
    }

    .lists {
        @media screen and (max-width: 767px) {
            height: 40;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: row;
            border: 1px solid #696a6b;
            border-radius: 50px;
            margin-bottom: 15px;
            padding: .5rem 1.5rem;
            color: #696a6b;
            background: transparent;
            width: 100%;
        }
        @include media-min ('tablet') {
            align-items: center;
            justify-content: center;
            display: flex;
            font-size: 20px;
            flex-direction: row;
            border: 1px solid #696a6b;
            border-radius: 50px;
            margin-bottom: 15px;
            padding: .5rem 1.5rem;
            color: #696a6b;
            background: transparent;
            width: 200px;
        }
    }
    .selected{
        @media screen and (max-width: 767px) {
            border: 2px solid #3CAD00;
            height: 40;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: row;
            border-radius: 50px;
            margin-bottom: 15px;
            padding: .5rem 1.5rem;
            color: #e3e3e3;
            background: transparent;
            width: 100%;
        }
        @include media-min ('tablet') {
            font-size: 20px;
            border: 2px solid #3CAD00;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: row;
            border-radius: 50px;
            margin-bottom: 15px;
            padding: .5rem 1.5rem;
            color: #e3e3e3;
            background: transparent;
            width: 200px;
        }
    }

    .container__link {
        @media screen and (max-width: 767px) {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 2rem;
            margin-bottom: 2rem;
            background: linear-gradient(to right, #3CAD00, #C8D900 );
            border-radius: 50px;
            height: 40px;
            width: 156px;
            border: none;
        }
        @include media-min ('tablet') {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 4rem;
            margin-bottom: 2rem;
            background: linear-gradient(to right, #3CAD00, #C8D900 );
            border-radius: 50px;
            height: 64px;
            min-height: 64px;
            width: 320px;
            border: none;
        }
            .link__selectlanguage {
                @media screen and (max-width: 767px) {
                    color: #fff;
                    text-transform: uppercase;
                    font-size: .7rem;
                    font-weight: 700;
                    text-align: center;
                    margin-bottom: 0;
                }
                @include media-min ('tablet') {
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 1rem;
                    font-weight: 700;
                    text-align: center;
                    margin-bottom: 0;
                }
            }
    }

}



