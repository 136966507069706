@import '../../../styles/mixin';

.modalSubscription{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 2;
    height: 45vh;
    top: 25vh;
    width: 80vw;
    background-color: black;
    right: 10vw;
    left: 10vw;
    border-width: 2px;
    border-color: #3CAD00;
    border-style: solid;
    text-align: center;
    @include media-min ('tablet') {
      right: 10vw;
      left: 10vw;
      margin-left: 21%;
      width: 60vw;
      height: 30vh;
      top: 15vh;
    }
    @include media-min ('desktop-s') {
        height: 60vh;
        top: 25vh;
    }
    @include media-min ('desktop-m') {
        margin-left: 15%;
        top: 30vh;
    }
    h1 {
        font-weight: bold;
        font-size: 22px;
        padding: 0 15px 0 15px;
        @include media-min ('tablet') {
            font-size: 24px;
            }
    }
    p {
        font-size: 14px;
        padding: 0 15px 0 15px;
        @include media-min ('tablet') {
            font-size: 16px;
            }
    }

    .cancel {
        background: linear-gradient(#3CAD00, #C8D900);
        border:none;
        color: white;
        padding: 10px;
        font-size: 14px;
        width: 50vw;
        @include media-min ('tablet') {
        font-size: 16px;
        width: 45vw;
        }
        @include media-min ('desktop-s') {
            width: 40vw;
        }
        @include media-min ('desktop-m') {
            width: 35vw;
        }
        @include media-min ('desktop-l') {
            width: 30vw;
        }
    }

    .modal-skip {
        background: transparent;
        border: none;
        color: white;
        padding: 10px;
        font-size: 14px;
        width: 50vw;
        text-decoration: underline;
        @include media-min ('tablet') {
            font-size: 16px;
            width: 45vw;
            }
            @include media-min ('desktop-s') {
                width: 40vw;
            }
            @include media-min ('desktop-m') {
                width: 35vw;
            }
            @include media-min ('desktop-l') {
                width: 30vw;
            }
    }
}