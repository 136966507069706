@import '../../../styles/mixin';

.container__top-country {
    margin-bottom: size(36);
    @include media-min ('tablet'){
        margin-bottom: size(55);
    }
    @include media-min ('desktop-m') {
        
    }
    .container-main-carousel {
        /* .carousel-button-group{
            top: 0rem;
            right: 0rem;
            display: none;
            @media (orientation: landscape) {
                @media (min-width: 481px) {
                    top: 0;
                    right: 0.5rem;
                }
            }
            @include media-min ('tablet') {
                display: inline;
                top: -0.5rem;
                right: 0rem;
            }
        } */
        /* .title-extra {
            font-size: 14px;
            font-weight: 800;
            margin-bottom: 0px;
            @include media-min ('tablet'){
                font-size: 18px;
            }
            @include media-min ('desktop-m') {
                font-size: 22px;
            }
            @include media-min ('desktop-l') {
                font-size: 22px;
            }
        } */
        
        /* .superposition {
            display: inline-block;
            position: relative;
            margin: .5rem 0 0 0;
            width: 87%;
        } */
        .superposition {
            > a {
                display: block;
                position: relative;
                text-decoration: none;
                &:hover {
                    text-decoration: none;
                }
            }
            .img_content {
                margin-bottom: 0;
            }
            .cover {
                width: 100%;
                height: auto;
                border-radius: 5px;
                filter: brightness(60%);
            }
            .flag {
                height: 33px;
                width: 57px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                @include media-min ('tablet'){
                    height: 48px;
                    width: 77px;
                }
                @include media-min ('desktop-s') {
                    top: 45%;
                }
            }
            p {
                color: #fff;
                font-size: size(10);
                font-weight: 800;   
                margin: 0;
                @include media-min ('tablet'){
                    font-size: size(16);
                    margin-top: size(16);
                }
                @include media-min ('desktop-m'){
                    margin-top: size(18);
                }
            }
            .container-description-territory {

                width: 100%;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    text-transform: uppercase;
                    transform: translateY(25px);

                p {
                        text-align: center;
                }
            }
        }
        /* .superposition p:nth-of-type(1) {
            position: absolute;
            top: 60%;
            width: 100%;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            text-transform: uppercase;
            margin-top: 0.5em;
            @include media-min ('tablet'){
                margin-top: 1rem;
                font-size: 20px;
            }
        } */
        
        /* .container-description-territory {
            display: flex;
            @include media-min ('desktop-s') {
                width: 190px;
            }
        } */
    }
}


